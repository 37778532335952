import { useMemo, useState } from 'react';
import { PlayersStateContext, playersInitialState } from 'context/playersStateContext';
import { EventsStateContext, eventsInitialState } from 'context/eventsStateContext';
import { PerformanceTypeStateContext, performanceTypeInitialState } from 'context/performanceTypeStateContext';
import { AuthStateContext, authInitialState } from 'context/authContext';

export const GlobalStateProvider = ({ children }) => {
    const [players, setPlayers] = useState(playersInitialState.players);
    const playersStateContextValue = useMemo( () => ({ players, setPlayers }), [players] );

    const [events, setEvents] = useState(eventsInitialState.events);
    const eventsStateContextValue = useMemo( () => ({ events, setEvents }), [events] );

    const [globalPerformanceType, setGlobalPerformanceType] = useState(performanceTypeInitialState.globalPerformanceType);
    const globalPerformanceTypeStateContextValue = useMemo( () => ({ globalPerformanceType, setGlobalPerformanceType }), [globalPerformanceType] );

    const [authenticatedUser, setAuthenticatedUser] = useState(authInitialState.authenticatedUser);
    const authStateContextValue = useMemo( () => ({ authenticatedUser, setAuthenticatedUser }), [authenticatedUser] );


    return (
        <AuthStateContext.Provider value={authStateContextValue}>
            <PlayersStateContext.Provider value={playersStateContextValue}>
                <EventsStateContext.Provider value={eventsStateContextValue}>
                    <PerformanceTypeStateContext.Provider value={globalPerformanceTypeStateContextValue}>
                        {children}
                    </PerformanceTypeStateContext.Provider>
                </EventsStateContext.Provider>
            </PlayersStateContext.Provider>
        </AuthStateContext.Provider>
    )
}
