import { gql } from 'graphql-request';

export const SIGN_IN = gql `
    mutation SignIn(
        $username: String!,
        $password: String!
    ) {
        signIn(
            username: $username,
            password: $password
        ) {
            token
            refreshToken
            userMe {
                id
                userName
                roles
                profile {
                    id
                    profileName
                }
            }
        }
    }
`;
