import {
    extendTheme,
    withDefaultColorScheme
  } from '@chakra-ui/react';
  
  const theme = extendTheme({
    fonts: {
        body: "Roboto",
        heading: "Roboto"
    },
    colors: {
      blackAlpha: {
        500: "rgba(0, 0, 0, 0.92)"  //overriden to get black color
      }
    }
  }, withDefaultColorScheme({ colorScheme: 'blackAlpha' }));

export default theme;