import React, { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';

import { Home } from 'pages/Home';
import { PlayerHome} from 'pages/Player/PlayerHome';
import { PlayerPerformance, 
            ChampionshipPerformance, 
            TimeTrialPerformance, 
            Benchmark,
            PlayerPersonalBest,
            PlayerCompetition,
            PlayerRecommendation
        }  from 'pages/Player';

import { ManagePlayer} from 'pages/Player/ManagePlayer';
import { PageNotFound } from 'pages/PageNotFound';
import { UploadPlayerPerfData } from 'pages/Player/UploadPlayerPerfData';
import SignIn from 'pages/Auth/SignIn';
import SignOut from 'components/SignOut';
import { AUTH_REDIRECT_ROUTE, AUTH_TOKEN_NAME, ADMIN_ROLE, AUTH_USER_ME, COACH_ROLE } from 'utils/constants';
import { useToast } from '@chakra-ui/react';

import { TimetrialUpload, TournamentUpload } from 'pages/Upload';


const ProtectedRoute = ({
    redirectPath = {AUTH_REDIRECT_ROUTE},
    permittedRoles,
    children
}) => {
    const toast = useToast();

    const token = localStorage.getItem(AUTH_TOKEN_NAME);

    const hasPermissions = (permittedRoles) => {
        const userMe = JSON.parse(window.atob(localStorage.getItem(AUTH_USER_ME)));
        console.log('userMe', userMe);
        
        return permittedRoles.some((item) => userMe?.roles?.includes(item));
    }

    // check for authentication
    if (!token) {
        return <Navigate to={redirectPath} replace />
    }

    // check for authorization
    if (Array.isArray(permittedRoles) && permittedRoles.length > 0 && !hasPermissions(permittedRoles)) {
        toast({
            title: 'Not authorized',
            duration: 3000,
            isClosable: true,
            status: 'error'
        });
        return <Navigate to={redirectPath} replace />
    }

    return children ? children : <Outlet />
}

function AppRoute() {
    return (
        <Router>
            <Routes>
                <Route index element={<Navigate to="/signIn" />} />
                <Route path = "/signIn" element={<SignIn />} />
                <Route path = "/signOut" element={<SignOut />} />
                <Route path="/benchmark"
                    element={
                        <ProtectedRoute
                            redirectPath={AUTH_REDIRECT_ROUTE}
                        >
                            <Benchmark />
                        </ProtectedRoute>
                    }>
                </Route>
                <Route path="/player-personal-best"
                    element={
                        <ProtectedRoute
                            redirectPath={AUTH_REDIRECT_ROUTE}
                        >
                            <PlayerPersonalBest />
                        </ProtectedRoute>
                    }>
                </Route>
                <Route path="/player-competition"
                    element={
                        <ProtectedRoute
                            redirectPath={AUTH_REDIRECT_ROUTE}
                            // permittedRoles={[ADMIN_ROLE, COACH_ROLE]}
                        >
                            <PlayerCompetition />
                        </ProtectedRoute>
                    }>
                </Route>
                <Route path="/player-recommendation"
                    element={
                        <ProtectedRoute
                            redirectPath={AUTH_REDIRECT_ROUTE}
                            permittedRoles={[ADMIN_ROLE]}
                        >
                            <PlayerRecommendation />
                        </ProtectedRoute>
                    }>
                </Route>
                <Route path="/upload-tournament-data"
                    element={
                        <ProtectedRoute
                            redirectPath={AUTH_REDIRECT_ROUTE}
                            permittedRoles={[ADMIN_ROLE]}
                        >
                            <TournamentUpload />
                        </ProtectedRoute>
                    }>
                </Route>
                <Route path="/upload-timetrial-data"
                    element={
                        <ProtectedRoute
                            redirectPath={AUTH_REDIRECT_ROUTE}
                            permittedRoles={[ADMIN_ROLE]}
                        >
                            <TimetrialUpload />
                        </ProtectedRoute>
                    }>
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
}

export default AppRoute