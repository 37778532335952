import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom';


class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    // // This to reset the state to prepare navigation out of this component
    // componentDidMount() {
    //     this.unlisten = this.props.history.listen((location, action) => {
    //         if (this.state.hasError) {
    //           this.setState({ hasError: false });
    //         }
    //       });
    // }

    // // unregister the listener
    // componentWillUnmount() {
    //     this.unlisten();
    // }

    render() {

        if (this.state.hasError) {
            return <div>
                <h1>Something went wrong!</h1>
                <button
                    type="button"
                    raised
                    onClick={() => this.props.history.push('/')}
                >
                    Back to Home
                </button> 
            </div>        
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
