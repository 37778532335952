import React, { useState, useEffect, useRef } from "react";
import useSwr from "swr";
import {
  useColorMode,
  useColorModeValue,
  Box,
  FormLabel,
  SimpleGrid,
  Grid,
  GridItem,
  Center,
  Button,
  IconButton,
  HStack,
  Flex,
  Spacer,
  TagLabel,
  TagCloseButton,
  Tag,
  Heading,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { usePlayersState } from 'hooks/usePlayers';
import { useEventsState } from 'hooks/useEvents';
import { useGlobalPerformanceTypeState } from "hooks/useGlobalPerformanceType";

import { Select } from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import { default as ReactSelect } from "react-select";

import { useClient } from "hooks/useClient";
import fetcher from "utils/fetcher";
import * as Messages from "utils/messages";
import benchmarkLevels from 'mocks/benchmarks.json';

import {
  GET_ALL_PLAYERS as getAllPlayers,
  GET_ALL_EVENTS as getAllEvents,
  GET_PLAYER_PERFORMANCE_V2 as getPlayerPerformanceQueryV2,
  GET_PLAYER_PERSONAL_BEST as getPlayerPersonalBest
} from "./queries";

import { StandardHeader } from "components/Header";
import Footer from "components/Footer";
import Loader from "components/Loader";

import SelectAdd from "components/UI/SelectAdd";
import AquaTag from "components/UI/AquaTag";
import AquaTab from "components/UI/AquaTab";
import { useToast } from '@chakra-ui/react';

import { isAuthenticated, isArrayEmpty } from "utils/helpers";
import { buildEventsArray } from 'pages/Player/helpers';
import { buildPlayerPersonalBestData } from 'pages/Player/helpers';
import { useNavigate } from 'react-router-dom';

import {
  AUTH_REDIRECT_ROUTE,
  PLAYERS_LIMIT,
  EVENTS_LIMIT,
  DEFAULT_BENCHMARK_LEVEL
} from "utils/constants";

export const PlayerPersonalBest = (props) => {

  const DEFAULT_PLAYER = [{
    label: "Ashwath Ayyalasomayajula",
    value: "9E98605B64D34FD2BF8FE02665577D54",
  }];

  const navigate = useNavigate();

  const [shouldFetch, setShouldFetch] = useState(false);
  const isPlayerLoading = useRef(false);

  const [isLoading, setIsLoading] = useState(false);

  const [nodata, setNoData] = useState(false);

  const {players, setPlayers} = usePlayersState();
  const {events, setEvents} = useEventsState();

  const [selectedPerfEvents, setSelectedPerfEvents] = useState(
                                events && !isArrayEmpty(events)
                                ? events
                                : []
                              );
  const [selectedPlayers, setSelectedPlayers] = useState(
                                players && !isArrayEmpty(players)
                                ? players
                                : []
                              );
  const [selectedBenchmarkLevel, setSelectedBenchmarkLevel] = useState(DEFAULT_BENCHMARK_LEVEL);

  const [playerList, setPlayerList] = useState([]);
  const [ eventsList, setEventsList] = useState();
  

  const [shortlistedPlayers, setShortlistedPlayers] = useState([]);
  const [shortlistedEvents, setShortlistedEvents] = useState();
  

  const [playerPersonalBestData, setPlayerPersonalBestData] = useState();

  const [showSelectPlayer, setShowSelectPlayer] = useState(false);
  const [showSelectEvent, setShowSelectEvent] = useState(false);
  

  const queryParamsRef = useRef({
    playerIds: selectedPlayers.map(item => item.value),
    benchmarkLevel: selectedBenchmarkLevel
    // eventIds: selectedPerfEvents.map(item => item.value),
  });

  const eventChangedHandler = (inputValue) => {
    if (!inputValue) return;

    const shortlistedEventsArr = [];

    inputValue.map((event) => {
      shortlistedEventsArr.push({'label' : event.label, 'value': event.value});
    });

    let currentShortListedEvents = shortlistedEvents;

    if (typeof currentShortListedEvents !== 'undefined' && !currentShortListedEvents) 
    currentShortListedEvents.push(...shortlistedEventsArr);
    else  
    currentShortListedEvents = shortlistedEventsArr;
    setShortlistedEvents(currentShortListedEvents);
  };

  const benchmarkLevelChangedHandler = (e) => {
    console.log('benchmarkLevelChangedHandler');
    console.log(e.currentTarget.value);

    e.preventDefault();
    
    let paramObj = {
      ...queryParamsRef.current,
      benchmarkLevel: e.currentTarget.value
    };
    
    updateQueryParam(paramObj);
    setSelectedBenchmarkLevel(e.currentTarget.value);
  }

  const playerChangedHandler = (inputValue) => {
    if (!inputValue) return;

    const shortlistedPlayersArr = [];

    console.log('inputValue', inputValue);

    inputValue.map((player) => {
      shortlistedPlayersArr.push({'label' : player.label, 'value': player.value});
    });

    let currentShortListedPlayers = shortlistedPlayers;

    console.log('shortlistedPlayers', shortlistedPlayers);

    if (typeof currentShortListedPlayers !== 'undefined' && !currentShortListedPlayers) 
      currentShortListedPlayers.push(...shortlistedPlayersArr);
    else  
      currentShortListedPlayers = shortlistedPlayersArr;

    console.log('currentShortListedPlayers', currentShortListedPlayers);

    setShortlistedPlayers(currentShortListedPlayers);
  };

  const playerAddedHandler = () => {

     // Check if any value is selected
     if (!shortlistedPlayers || (Array.isArray(shortlistedPlayers) && shortlistedPlayers.length === 0 )) {
      toast({
        title: 'Choose a Player',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    let currentSelectedPlayers = [...selectedPlayers];

    // Check for duplicates
    const duplicatePlayers = selectedPlayers.filter(itr => shortlistedPlayers.some(sp => sp.value  === itr.value));

    if (duplicatePlayers && Array.isArray(duplicatePlayers) && duplicatePlayers.length > 0) {
      toast({
        title: 'Player Already added',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    // Maximum 5 players are allowed
    if (selectedPlayers.length + shortlistedPlayers.length > PLAYERS_LIMIT) {
      toast({
        title: `Max ${PLAYERS_LIMIT} players can be added`,
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    if (Array.isArray(currentSelectedPlayers) && currentSelectedPlayers.length > 0) 
      currentSelectedPlayers.push(...shortlistedPlayers);
    else  
      currentSelectedPlayers = shortlistedPlayers;

    let paramObj = {
      ...queryParamsRef.current,
      playerIds: currentSelectedPlayers.map((item) => item.value)
    };
    
    updateQueryParam(paramObj);

    setSelectedPlayers(currentSelectedPlayers);
    setShowSelectPlayer(false);
    
  }

  const eventAddedHandler = () => {

    // Check if any value is selected
    if (!shortlistedEvents || (Array.isArray(shortlistedEvents) && shortlistedEvents.length === 0 )) {
      toast({
        title: 'Choose an Event',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    let currentSelectedEvents = [...selectedPerfEvents];

    // Check for duplicates
    const duplicateEvents = selectedPerfEvents.filter(itr => shortlistedEvents.some(se => se.value  === itr.value));

    if (duplicateEvents && Array.isArray(duplicateEvents) && duplicateEvents.length > 0) {
      toast({
        title: 'Event Already added',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    // Maximum 5 Events are allowed
    if (selectedPerfEvents.length + shortlistedEvents.length > EVENTS_LIMIT) {
      toast({
        title: `Max ${EVENTS_LIMIT} events can be added`,
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    if (Array.isArray(currentSelectedEvents) && currentSelectedEvents.length > 0) 
      currentSelectedEvents.push(...shortlistedEvents);
    else  
      currentSelectedEvents = shortlistedEvents;

   

    let paramObj = {
      ...queryParamsRef.current,
      eventIds: currentSelectedEvents.map((item) => item.value)
    };

    updateQueryParam(paramObj);

    setSelectedPerfEvents(currentSelectedEvents);
    setShowSelectEvent(false);
    

  }

  const client = useClient();
  const toast = useToast();

  const updateQueryParam = (paramObj) => {
    queryParamsRef.current = paramObj;
  };

  const buildPlayersArray = (playersObj) => {
    const playersArr = [];

    playersObj.map((playerObj) => {
      let playerItem = {};
      playerItem["label"] = playerObj.firstName + ' ' + playerObj.lastName;
      playerItem["value"] = playerObj.id;
      playersArr.push(playerItem);
    });

    return playersArr;
  };

  const getPlayers = async () => {
    try {
      const playerResData = await fetcher(getAllPlayers, client);
      const playersArr = buildPlayersArray(
        playerResData?.getAllPlayers?.players
      );
      setPlayerList(playersArr);
    } catch (error) {
      console.log('error', error);
      isPlayerLoading.current = false;
    }
  };

  const getEvents = async () => {
    try {
        const eventsResData = await fetcher(getAllEvents, client);
        const eventsArr = buildEventsArray(eventsResData?.getAllEvents?.events);
        setEventsList(eventsArr);
    }
    catch(error) {
      console.log('error', error);
      setEventsList([]);
    }
  }

  const { data: playerPersonalBestApiData, error } = useSwr(
    shouldFetch
      ? [getPlayerPersonalBest, client, queryParamsRef.current]
      : null,
    fetcher,
    {
      loadingTimeout: 800,
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  useEffect(() => {
    
    getPlayers();
    getEvents();

    setShouldFetch(true);
    setIsLoading(true);
    // players && setSelectedPlayers(players);
    // events && setSelectedPerfEvents(events);
  }, []);

  useEffect(() => {
    // console.log('isAuthenticated', isAuthenticated);
    // if (!isAuthenticated()) navigate(AUTH_REDIRECT_ROUTE)
    
    // check if any of the input is empty. If true set noData to true
    if (
      // isArrayEmpty(selectedPerfEvents) ||
      isArrayEmpty(selectedPlayers)
    ) {
      setNoData(true);
      setPlayerPersonalBestData("");
      return;
    }
    setIsLoading(true);
    setNoData(false);
    setShouldFetch(true);
    setPlayerPersonalBestData('');

  }, [
    selectedPerfEvents,
    selectedPlayers,
    selectedBenchmarkLevel
  ]);

  useEffect(() => {
    if (!playerPersonalBestApiData) return;

    // set should fetch to false
    setShouldFetch(false);

    const playerPerformanceDataRes =
      playerPersonalBestApiData?.getPlayerPersonalBest;

    // If player performance is null then show no data found
    if (!playerPerformanceDataRes) {
      setIsLoading(false);
      setNoData(true);
      return;
    }

    console.log('selected players', selectedPlayers);
    console.log('selected selectedPerfEvents', selectedPerfEvents);

    const performanceObj = buildPlayerPersonalBestData(selectedPlayers, selectedPerfEvents, playerPerformanceDataRes);

    console.log('performanceObj', performanceObj);

    setPlayerPersonalBestData(performanceObj);

    setIsLoading(false);
  }, [playerPersonalBestApiData]);

  useEffect(() => {

  }, [shortlistedPlayers])

  useEffect(() => {

  }, [shortlistedEvents])

  const playerTagClickedHandler = (e, value) => {
    const currentPlayers = [...selectedPlayers];
    const updatedPlayers = currentPlayers.filter( (player) => player && player.value !== value );

    let paramObj = {
      ...queryParamsRef.current,
    };

    if (updatedPlayers && Array.isArray(updatedPlayers) && updatedPlayers.length === 0) {
      delete paramObj.playerIds;
    }
    else {
      paramObj['playerIds'] = updatedPlayers.map((item) => item.value)
    }
    
    updateQueryParam(paramObj);

    setSelectedPlayers([...updatedPlayers]);
  }

  const eventTagClickedHandler = (e, value) => {
    const currentEvents = [...selectedPerfEvents];
    const updatedEvents = currentEvents.filter( (eventItem) => eventItem && eventItem.value !== value );

    let paramObj = {
      ...queryParamsRef.current,
    };

    if (updatedEvents && Array.isArray(updatedEvents) && updatedEvents.length === 0) {
      delete paramObj.eventIds;
    }
    else {
      paramObj['eventIds'] = updatedEvents.map((item) => item.value)
    }
    
    updateQueryParam(paramObj);

    setSelectedPerfEvents(updatedEvents);
  }

  const showSelectPlayerHandler = () => {
    setShortlistedPlayers();
    setShowSelectPlayer(!showSelectPlayer);
  }

  const showSelectEventHandler = () => {
    setShortlistedEvents();
    setShowSelectEvent(!showSelectEvent);
  }

  if (isLoading) return <Loader show={isLoading} message="Loading..." />;

  return (
    <div>
      <StandardHeader />
      <Heading as='h4' size='md' textAlign='center' marginTop={5}>Player Best Performance</Heading>
      <Grid
        spacing="10px"
        p={10}
        templateColumns={{base: '1fr', sm: 'repeat(4, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(8, 1fr)' }}
      >
        <GridItem colSpan={3} marginBottom={{base: '25px'}}>
          <Box>
            <FormLabel>Selected Players</FormLabel>
              {
                selectedPlayers && selectedPlayers.length > 0 && selectedPlayers.map( (player) => {
                  return  <AquaTag 
                            key={player.value}
                            label={player.label}
                            value={player.value}
                            clickedHandler={playerTagClickedHandler}
                          />
                })
              }
              <IconButton 
                icon={<AddIcon />} 
                size='sm' 
                onClick={() => showSelectPlayerHandler()} 
              />
              {
                showSelectPlayer &&
                <SelectAdd 
                itemsList={playerList} 
                  onChangedHandler={playerChangedHandler}
                  label="Player"
                  name="playerName"
                  placeholder="Select Player"
                  addClickedHandler={playerAddedHandler}
                  cancelClickedHandler={() => setShowSelectPlayer(!showSelectPlayer)} 
                />
              }
          </Box>
        </GridItem>
        <GridItem colSpan={3} marginBottom={{base: '25px'}}>
        <Box>
            <FormLabel>Selected Events</FormLabel>
              {
                selectedPerfEvents && 
                selectedPerfEvents.length > 0 && 
                selectedPerfEvents.map( (eventItem) => {
                  return  <AquaTag 
                            key={eventItem.value}
                            label={eventItem.label}
                            value={eventItem.value}
                            clickedHandler={eventTagClickedHandler}
                          />
                })
              }
              <IconButton 
                icon={<AddIcon />} 
                size='sm' 
                onClick={() => showSelectEventHandler()} 
              />
              {
                showSelectEvent &&
                <SelectAdd 
                  itemsList={eventsList} 
                  onChangedHandler={eventChangedHandler}
                  label="Event"
                  name="eventName"
                  placeholder="Select Event"
                  addClickedHandler={eventAddedHandler}
                  cancelClickedHandler={() => setShowSelectEvent(!showSelectEvent)} 
                />
              }
          </Box>
        </GridItem>

        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box w='80%'>
            <FormLabel>Benchmark Level</FormLabel>
            <Select placeholder="Choose Level" 
              value={selectedBenchmarkLevel} onChange={benchmarkLevelChangedHandler}>
              {
                (benchmarkLevels && benchmarkLevels.length > 0)
                ?
                benchmarkLevels && benchmarkLevels.map((benchmarkLevel) => {
                    return <option
                            key={benchmarkLevel.key}
                            value={benchmarkLevel.key}
                          >
                            {benchmarkLevel.value}
                          </option>
                  })
                :
                  <option>
                    {Messages.NO_DATA_FOUND}
                  </option>
              }
            </Select>
          </Box>
        </GridItem>
      </Grid>
      
      {playerPersonalBestData && <Box p={5}>
        <AquaTab tabData={playerPersonalBestData} displayType='standardExtended' ></AquaTab>
      </Box>
      }

      {error && (
        <Box width="100%" p={5} h="70vh">
          <Center>
            <div>Unable to load Data. Try again</div>
          </Center>
        </Box>
      )}
      {nodata && selectedPlayers.length > 0 && (
        <Box width="100%" p={5} h="70vh">
          <Center>
            <div>Data not found for this selection</div>
          </Center>
        </Box>
      )}

      {nodata && selectedPlayers.length === 0 && (
        <Box width="100%" p={5} h="70vh">
          <Center>
            <div>Select a Player</div>
          </Center>
        </Box>
      )}
      <Footer />
    </div>
  );
};
