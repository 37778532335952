import React, { useState, useEffect } from 'react';
import { default as ReactSelect } from "react-select";
import { StandardHeader } from "components/Header";
import {
    Heading,
    Stack,
    Flex,
    Grid,
    Button,
    Spacer,
    FormLabel
  } from "@chakra-ui/react";
import Footer from 'components/Footer';
import Loader from "components/Loader";

export const TimetrialUpload = (props) => {

    const UPLOAD_URL = 'https://api.ngaze.in/upload/championship?uploadDataType=timetrial';
    // let uploadImageElement = document.getElementById('upload-image');
    // let uploadImageContainerElement = document.getElementById('upload-image-container');
    
    const [selectedFileObject, setSelectedFileObject] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    
    const onChangedHandler = (event) => {
        setErrorText('');
        setSelectedFileObject(event.target.files[0]);
    }

    const onSubmitHandler = (event) => {
        console.log(selectedFileObject);

        const formData = new FormData();
        // formData.append('File', selectedFileObject);
        formData.append("file", selectedFileObject)

        console.log('selected file object', selectedFileObject);

        if (typeof selectedFileObject === "undefined"  || !selectedFileObject.size > 0) {
            setErrorText('File size cannot be empty');
            return;
        }

        setIsLoading(true);

        fetch(
            UPLOAD_URL,
            {
                method: 'POST',
                body: formData,
                mode: 'no-cors',
                redirect: 'follow'
            }
        )
        .then((response) => response.json)
        .then((result) => {
            console.log(result);
            setIsLoading(false);
        })
        .catch((error) => {
            console.log('upload failure', error)
            setIsLoading(false)
        })
    }

    if (isLoading) return <Loader show={isLoading} message="Loading..." />;

    return (
        <div>
            <StandardHeader />
            <Heading as='h4' size='md' textAlign='center' marginTop={5}>Training Data Import</Heading>
            <Stack minHeight="90vh" direction={{ base: 'column', md: 'row' }}>
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={4} w={'full'} maxW={'md'}>
                        <div id="upload-file">
                            <FormLabel>Select File to Upload</FormLabel>
                            <input type = "file" name="uploadFile" accept=".csv, .xls, .xlsx" onChange={onChangedHandler} />
                            {
                                errorText && <p>{errorText}</p>
                            }
                            <Spacer />
                        </div>
                        <Button onClick={onSubmitHandler}>Submit</Button>
                    </Stack>
                </Flex>
            </Stack>
            <Footer />
        </div>
    )
}