import { default as ReactSelect } from "react-select";
import {
  Box,
  FormLabel,
  Flex,
  Spacer,
  Button
} from "@chakra-ui/react";

export default function SelectAdd(props) {
  const { label, 
          placeholder, 
          name, 
          itemsList, 
          onChangedHandler, 
          addClickedHandler, 
          cancelClickedHandler 
        } = props;

    const reactSelectCustomStyles = {
        control: base => ({
          ...base,
          minWidth: 250
        })
    };

    return ( <div><Box w='80%'>
        <FormLabel>{label}</FormLabel>
        <ReactSelect placeholder={placeholder}
            styles={reactSelectCustomStyles}
            isMulti
            options={itemsList} 
            onChange={(inputValue) => onChangedHandler(inputValue)}
            name={name}
        />
      </Box>
      <Flex py={2} w='80%'>
        <Button onClick={cancelClickedHandler} variant='outline'>Cancel</Button>
        <Spacer />
        <Button onClick={addClickedHandler}>Add</Button>
      </Flex>
      </div>
      )
}