import React, { useState, useEffect, useRef } from "react";
import { Stack, 
            SimpleGrid, 
            Image, 
            Checkbox, 
            Link, 
            GridItem, 
            FormControl, 
            FormLabel, 
            FormErrorMessage,
            Input, 
            Button, 
            Heading, 
            Center, 
            useColorMode, 
            Flex 
        } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { AUTH_TOKEN_NAME, AUTH_USER_ME } from 'utils/constants';
import { useAuthState } from 'hooks/useAuth';
import { SIGN_IN as signIn} from './queries';
import { useClient } from "hooks/useClient";


import {
    PLAYER_PERFORMANCE_ROUTE,
    PLAYER_PERSONAL_BEST_ROUTE,
    PLAYER_COMPETITION_ROUTE,
    ADMIN_ROLE,
    COACH_ROLE
  } from 'utils/constants';

export default function SignInForm() {

    const { register, 
            handleSubmit,  
            formState: { errors, isSubmitting }, 
    } = useForm();
    // const onSubmit = data => console.log(data);
    const { toggleColorMode } = useColorMode();

    const toast = useToast();
    const navigate = useNavigate();
    const {authenticatedUser, setAuthenticatedUser} = useAuthState();
    const client = useClient();
    
    const hostname = window.location.hostname;

    function authenticate(email, password) {
        const usersList = [
            {
                "email": "rmohankrish@gmail.com",
                "password": "ngaze123"
            },
            {
                "email": "krish.isola@gmail.com",
                "password": "ngaze123"
            },
            {
                "email": "venkateshmula@gmail.com",
                "password": "ngaze123"
            },
            {
                "email": "demo@ngaze.in",
                "password": "demo123"
            },
            {
                "email": "player@ngaze.in",
                "password": "player123"
            },
            {
                "email": "coach@ngaze.in",
                "password": "coach123"
            }
        ];

        const authUser = usersList.filter(el => el.email === email);

        if (authUser && 
            Array.isArray(authUser) &&
            authUser.length > 0 &&
            authUser[0].password === password) {
            return [true, 'success']
        }
        else {
            return [false, 'Invalid email/password']
        }
    }

    function generateToken() {
        let randomGen = () => {
            return Math.random().toString(36).substring(2);
        }

        let token = randomGen() + '.' + randomGen();
        
        return token;

    }

    async function onSubmit(values) {
        // const [authSuccess, message] = authenticate(values.email, values.password);

        const inputParams = {
            username: values.email,
            password: values.password
        };

        const buildRequest = {
            document: signIn,
            variables: inputParams
        };

        try {
            let signInresponse = await client.request(buildRequest);

            let responseData = signInresponse.signIn;

            if (responseData) {
                localStorage.setItem(AUTH_TOKEN_NAME, responseData?.token);
                let authUserObj = {
                    userName: responseData?.userMe?.userName,
                    roles: responseData?.userMe.roles,
                    userMe: responseData?.userMe
                };

                localStorage.setItem(AUTH_USER_ME, window.btoa(JSON.stringify(authUserObj)));
                
                setAuthenticatedUser(authUserObj);

                navigate(PLAYER_COMPETITION_ROUTE, { replace: true });
                
                // if (responseData?.userMe?.roles.includes(ADMIN_ROLE) || responseData?.userMe?.roles.includes(COACH_ROLE))
                //     // window.location.href = window.location.origin + PLAYER_COMPETITION_ROUTE;
                //     navigate(PLAYER_COMPETITION_ROUTE, { replace: true });
                // else 
                //     // window.location.href = window.location.origin + PLAYER_PERSONAL_BEST_ROUTE;
                //     navigate(PLAYER_PERSONAL_BEST_ROUTE, { replace: true });
            }
        }
        catch(error) {
            toast({
                title: 'Invalid username/password',
                duration: 3000,
                isClosable: true,
                status: 'error'
            })
        }
    }

    useEffect( () => {
        // clean localstorage
        localStorage.removeItem(AUTH_TOKEN_NAME);
        localStorage.removeItem(AUTH_USER_ME);
    }, [])

    return (
        // <Stack h="calc(100vh - 64px)" direction={{ base: 'column', md: 'row' }}>
        <Stack minHeight="90vh" direction={{ base: 'column', md: 'row' }}>
            <Flex flex={1}>
                <Image
                    alt={'Login Image'}
                    objectFit={'cover'}
                    src={
                        'swimmer.jpg'
                    }
                />
            </Flex>
            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={4} w={'full'} maxW={'md'}>
                    <Heading fontSize={'2xl'}>Sign in to your account</Heading>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={errors.email || errors.password}>
                            <SimpleGrid rowGap={6} w={{ base: 'auto', md: '30vw' }}>
                                <GridItem colSpan={1}>
                                    <FormLabel>Email</FormLabel>
                                    <Input 
                                        id='email'
                                        type='email'
                                        maxLength={30}
                                        {...register('email', {
                                        required: 'Email is required',
                                     })} 
                                    />
                                    <FormErrorMessage>
                                        {errors.email && errors.email.message}
                                    </FormErrorMessage>
                                </GridItem>
                                <GridItem colSpan={1}>
                                    <FormLabel>Password</FormLabel>
                                    <Input 
                                        id='password'
                                        type="password"
                                        maxLength={12}
                                        {...register("password", {
                                            required: "Password is required",
                                            minLength: { value: 7, message: "Minimum 7 Chars length"}
                                        })} 
                                    />
                                    <FormErrorMessage>
                                        {errors.password && errors.password.message}
                                    </FormErrorMessage>
                                </GridItem>
                                <Stack
                                    direction={{ base: 'column', sm: 'row' }}
                                    align={'start'}
                                    justify={'space-between'}>
                                    <Checkbox>Remember me</Checkbox>
                                    <Link color={'blue.500'}>Forgot password?</Link>
                                </Stack>
                                <Center>
                                    <Button size="lg" variant='solid' type='submit'>Submit</Button>
                                </Center>
                            </SimpleGrid>
                        </FormControl>
                    </form>
                </Stack>
            </Flex>
        </Stack>
    )
}
