import { AUTH_TOKEN_NAME } from "utils/constants";

export default async function fetcher(query, client, queryParams='') {
    let buildRequest = {};
    
    const token = localStorage.getItem(AUTH_TOKEN_NAME);

    const headers = {
        authorization: `Bearer ${token}`
    };

    if (queryParams) {
        buildRequest = {
            document: query,
            requestHeaders: headers,
            variables: queryParams
        };
    }
    else {
        buildRequest = {
            document: query,
            requestHeaders: headers,
        };
    }

    const response = await client.request(buildRequest);
    return response;
}


// export default async function fetcher(query, variables) {
//     console.log('In fetcher', variables);
//     const response = await request({
//                     query,
//                     variables
//                     // requestHeaders: headers
//                 });
//     console.log(response);
//     return response;
// }
