import React from 'react';
import { Link, Outlet } from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LineController,
    BarController
  } from 'chart.js';
import { Line, Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LineController,
    BarController
  );

function epoch_to_mm_ss(epoch) {
    return new Date(epoch*1000).toISOString().substr(14, 5)
}

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: {
            ticks: {
                callback: function(value) { return epoch_to_mm_ss(value) },
                stepSize: 2
            },
            title: {
              display: true,
              text: 'Duration mm.ss'
            }
        }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Time Trail Progress',
      },
      tooltip: {
        callbacks: {
            label: function(context) { return  context?.dataset.label + ' ' + epoch_to_mm_ss(context.formattedValue)}
        }
      },
      datalabels: {
        display: true,
        formatter: (ctx, data) => {
          let dataLabel = data?.dataset?.data[data.dataIndex];
          return epoch_to_mm_ss(dataLabel);
        },
        align: 'top'
      },
    },
};
  
const labels = ['January', 'February', 'March', 'April', 'May'];
  
export const data = {
    labels,
    datasets: [
      {
        label: 'Player',
        data: [126,128,130,129,131],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Event Topper',
        data: [124,125,127,128,130],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
};

// Multi chart type
export const multiCharTypeOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Event Position for a player',
    }
  },
};

const multiChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
      yAxes: {
          ticks: {
              callback: function(value) { return epoch_to_mm_ss(value) },
              stepSize: 2
          },
          title: {
            display: true,
            text: 'Duration mm.ss'
          }
      }
  },

  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Player Position by Event',
    },
    tooltip: {
      callbacks: {
          label: function(context) {  return  context?.dataset.label + ' ' + epoch_to_mm_ss(context.formattedValue)}
      }
    },
    datalabels: {
      display: true,
      formatter: (ctx, data) => {
        let dataLabel = data?.dataset?.data[data.dataIndex];
        return epoch_to_mm_ss(dataLabel);
      },
      align: 'top'
    },
  },
};

const multiChartdata = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'Event Topper',
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 2,
      fill: false,
      data: [190, 200, 290, 210, 400],
    },
    {
      type: 'bar',
      label: 'Player',
      backgroundColor: 'rgb(75, 192, 192)',
      data: [200, 250, 300, 250, 450],
      borderColor: 'white',
      borderWidth: 2,
    },
  ],
};


export const PlayerHome = (props) => {
    return (
        <>
            <div>Player Home</div>
            <nav>
                <Link to="/player/player-manage">Manage</Link> {" "}
            </nav>
            <div style={{position: 'relative', margin: 'auto', height: '80vh', width: '80vw'}}>
                <Line options={options} data={data} />
            </div>

            <div style={{position: 'relative', margin: 'auto', height: '80vh', width: '80vw'}}>
                <Chart type='bar' options={multiChartOptions} data={multiChartdata} />
            </div>

            {/* <Outlet /> */}
        </>
    )
}



