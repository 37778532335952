import { getDataLabelValue, 
        convertToMilliseconds, 
        getRankDiff, 
        getAgeGroupFromAge,
        isObjectEmpty,
        initCaps
      } from 'utils/helpers';
import { playerLegendColors, BenchmarkLevels } from 'utils/constants';
import genders from 'mocks/genders.json';

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov'];

export const buildChartDataSets = (playerPerformanceDetail, playerBenchmarkDetails, selectedPerfEvents) => {
  
  // Initialize arrays

   
  const playerDataArr = [];
  let chartDataSets = [];

  let playerDataSetParams = {
    label: 'Player',
    data: playerDataArr[0],
    borderColor: 'rgb(0, 102, 255)',
    backgroundColor: 'rgba(0, 102, 255, 0.5)',
    pointStyle: 'triangle',
    pointRadius: 10
  };

  let nmrDataSetParams = {
    label: 'National Medal Record',
    data: [],
    borderColor: 'rgb(0, 153, 51)',
    backgroundColor: 'rgba(0, 153, 51, 0.5)',
    datalabels: {
      display: function(context) {
        return getDataLabelValue(context)
      }
    },
  };

  let goldMedalDataSetParams = {
      label: 'Gold',
      data: [],
      borderColor: 'rgba(214, 175, 54)',
      backgroundColor: 'rgba(214, 175, 54, 1)',
      datalabels: {
        display: function(context) {
          return getDataLabelValue(context)
        }
      },
  };

  let SilverMedalDataSetParams = {
      label: 'Silver',
      data: [],
      borderColor: 'rgba(167, 167, 173)',
      backgroundColor: 'rgba(167, 167, 173, 1)',
      datalabels: {
        display: function(context) {
          return getDataLabelValue(context)
        }
      },
  };

  let BronzeMedalDataSetParams = {
      label: 'Bronze',
      data: [],
      borderColor: 'rgba(130, 74, 2)',
      backgroundColor: 'rgba(130, 74, 2, 1)',
      datalabels: {
        display: function(context) {
          return getDataLabelValue(context)
        }
      },
  };

  let cutOffFromDataParams = 
  {
    label: 'Cutoff From',
    data: [],
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    datalabels: {
      display: function(context) {
        return getDataLabelValue(context)
      }
    },
  };

  let cutOffToDataParams =
  {
    label: 'Cutoff To',
    data: [],
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    datalabels: {
      display: function(context) {
        return getDataLabelValue(context)
      }
    },
  };

  const eventTopper1Data = new Array(12).fill(
                                      playerBenchmarkDetails && 
                                      playerBenchmarkDetails?.benchmark[0].time
                                    );
  const eventTopper2Data = new Array(12).fill(
                                    playerBenchmarkDetails && 
                                    playerBenchmarkDetails?.benchmark[1].time
                                    );
  const eventTopper3Data = new Array(12).fill(
                                    playerBenchmarkDetails && 
                                    playerBenchmarkDetails?.benchmark[2].time
                                    );
  const cutOffStartData = new Array(12).fill(
                                    playerBenchmarkDetails && 
                                    playerBenchmarkDetails?.cutoffStart
                                    );
  const cutOffEndData = new Array(12).fill(
                                    playerBenchmarkDetails && 
                                    playerBenchmarkDetails?.cutoffEnd
                                    );

  
  const nmrData = new Array(12).fill(
                                      playerBenchmarkDetails &&
                                      playerBenchmarkDetails?.nmr && 
                                      Array.isArray(playerBenchmarkDetails.nmr) &&
                                      playerBenchmarkDetails.nmr[0].nmr
                                    );

  
  // construct player data
  if (playerPerformanceDetail) {
    let ctr = 0;
    for (const itr in playerPerformanceDetail) {
      let playerData = new Array(11).fill(null);
      playerData[playerPerformanceDetail[itr].month-1] = typeof playerPerformanceDetail[itr] !== 'undefined' 
                                                          ? playerPerformanceDetail[itr].time 
                                                          : null;
      // Choose player legend
      let borderColor = playerLegendColors && Array.isArray(playerLegendColors) && playerLegendColors.length >= ctr 
                        ? playerLegendColors[ctr].borderColor
                        : 'rgb(0, 102, 255)';
      
      let backgroundColor = playerLegendColors && Array.isArray(playerLegendColors) && playerLegendColors.length >= ctr 
                        ? playerLegendColors[ctr].backgroundColor
                        : 'rgba(0, 102, 255, 0.5)';

      // Build player chartitem
      let chartitem = {
        ...playerDataSetParams,
        label: playerPerformanceDetail[itr].player?.displayName,
        data: playerData,
        borderColor: borderColor,
        backgroundColor: backgroundColor
      };
      chartDataSets.push(chartitem);
      ctr++;
    }
  }

  // Don't add benchmark data points if more than one event is selected
  if (selectedPerfEvents && 
      Array.isArray(selectedPerfEvents) &&
      selectedPerfEvents.length === 1) {
    // Add other chart series
    // 1. Add NMR
    chartDataSets.push(
      {
        ...nmrDataSetParams,
        data: nmrData
      }
    );

    // 2. Add Gold Medalist
    chartDataSets.push(
      {
        ...goldMedalDataSetParams,
        data: eventTopper1Data
      }
    );

    // 3. Add Silver Medalist
    chartDataSets.push({
      ...SilverMedalDataSetParams,
      data: eventTopper2Data
    });

    // 4. Add Bronze Medalist
    chartDataSets.push({
      ...BronzeMedalDataSetParams,
      data: eventTopper3Data
    });

    // 5. Add Cutoff From 
    chartDataSets.push({
      ...cutOffFromDataParams,
      data: cutOffStartData
    });

    // 6. Add Cutoff To 
    chartDataSets.push({
      ...cutOffToDataParams,
      data: cutOffEndData
    });
  }
 

  return chartDataSets;
}

export const buildEventsArray = (eventsObj) =>  {
    
  const eventssArr = [];

  eventsObj.map((eventObj) => {
    let eventItem = {};
    eventItem['label'] = eventObj.name;
    eventItem['value'] = eventObj.id;
    eventssArr.push(eventItem);
  })

  return eventssArr;
}

export const buildStatesArray = (statesObj) =>  {
    
  const statesArr = [];

  statesObj.map((stateObj) => {
    let stateItem = {};
    stateItem['label'] = initCaps(stateObj.NAME);
    stateItem['value'] = stateObj.NAME;
    statesArr.push(stateItem);
  })

  return statesArr;
}

export const buildAgeGroupsArray = (ageGroupObj) => {
  const ageGroupsArr = [];

  ageGroupObj.map((item) => {
    let ageGroupItem = {};
    ageGroupItem['label'] = item.ageGroup;
    ageGroupItem['value'] = item.ageGroup;
    ageGroupsArr.push(ageGroupItem);
  })

  return ageGroupsArr;
}

export const buildChampionshipPerformanceData = (players, events, championshipData) => {

  const champPerf = championshipData;

  console.log('champPerf', champPerf);

  const benchmarkData = champPerf.benchmarkDetail;

  const playerPerformanceData = champPerf.playerPerformanceDetail;

  let championshipPerformance = [];

  events.forEach(eventItem => {

    console.log('eventItem', eventItem);

    // Build benchmarks
    const benchmarksArr = benchmarkData.filter( (rec) => rec && rec.event.id === eventItem.value);

    // Build player performance data
    const playerPerformanceArr = playerPerformanceData.filter( (rec) => rec && rec.event.id === eventItem.value);

    // Build comparisons
    let playerCompArr = [];

    playerPerformanceArr && playerPerformanceArr.map( (player) => {
      const benchmarks = benchmarksArr.filter( (rec) => rec && (rec.ageGroup === player.ageGroup && rec.gender === player.gender));
      const benchmarkObj = benchmarks && Array.isArray(benchmarks) && benchmarks[0];

      let nmr = benchmarkObj && benchmarkObj.benchmark && benchmarkObj.nmr[0];

      let rankInputObj = benchmarkObj.benchmark;
      
      console.log('rankInputObj', rankInputObj);

      // Build ranks array
      let ranks = [];

      if (benchmarkObj && benchmarkObj.benchmark) {
        benchmarkObj && benchmarkObj.benchmark && rankInputObj.sort((val1, val2) => val1.rank - val2.rank);

        rankInputObj.forEach(element => {
          console.log('element.time', element.time);
          console.log('player.time', player.time);
          const rankcomp = getRankDiff(element.time, player.time);
          console.log('rankcomp', rankcomp);
  
          let rankItem = {
            rankValue: element.time,
            comparison: rankcomp.comparison,
            rankDiff: rankcomp.rankDiff
          };
          ranks.push(rankItem);
        });
      }

      // benchmarkObj && 
      // benchmarkObj.benchmark && 
      // benchmarkObj.benchmark.forEach(element => {

      //   const rankcomp = getRankDiff(element.time, player.time);

      //   let rankItem = {
      //     rankValue: element.time,
      //     comparison: rankcomp.comparison,
      //     rankDiff: rankcomp.rankDiff
      //   };
      //   ranks.push(rankItem);
      // });
      
     

      let cutoffStartComparison = benchmarkObj && getRankDiff(benchmarkObj.cutoffStart.time, player.time);
      let cutoffEndComparison = benchmarkObj && getRankDiff(benchmarkObj.cutoffEnd.time, player.time);

      // Cutoffs
      let cutOffRanks = {};

      if (benchmarkObj) {
        cutOffRanks = {
          cutoffStart: benchmarkObj && benchmarkObj.cutoffStart,
          cutoffStartComparison: cutoffStartComparison.comparison,
          cutoffStartDiff: cutoffStartComparison.rankDiff,
          cutoffEnd: benchmarkObj && benchmarkObj.cutoffEnd,
          cutoffEndComparison: cutoffEndComparison.comparison,
          cutoffEndDiff: cutoffEndComparison.rankDiff,
        }
      }
      console.log('player time', player);
      let playerCompItem = {
          ageGroup: player.ageGroup,
          gender: player.gender,
          nmr: nmr,
          player: player.player,
          time: player.time,
          ranks: ranks,
          cutoffStart: benchmarkObj && benchmarkObj.cutoffStart,
          cutoffEnd: benchmarkObj && benchmarkObj.cutoffEnd,
          cutoffRanks: cutOffRanks
      }
      playerCompArr.push(playerCompItem);
    });

    // Championship performance item
    let championshipPerformanceItem = {
      entityType: 'event', //player or event
      entity: eventItem,
      performances: playerCompArr
    }
    championshipPerformance.push(championshipPerformanceItem);

  });
  console.log('championshipPerformance', championshipPerformance);
  return championshipPerformance;
  
}

export const buildPlayerPersonalBestData = (players, events, personalBestData) => {

  const personalPerf = personalBestData;
  console.log('personalPerf', personalPerf);
  const benchmarkData = personalPerf.benchmarkDetail;
  const playerPerformanceData = personalPerf.playerPerformanceDetail;

  console.log('players', players);

  let playerPersonalBest = [];

  players.forEach(playerItem => {

    let playerPeformanceArr = playerPerformanceData.filter( (rec => rec && rec.player.id === playerItem.value) );
    let playerPerformacesArr = [];
    let playerYB = '';
    let playerAgeGroup = '';
    let playerGender = '';
    let playerDiffComp = '';

    playerPeformanceArr && Array.isArray(playerPeformanceArr) &&
    playerPeformanceArr.length > 0 && playerPeformanceArr.forEach(item => {
      
      // Build benchmarks
      const benchmarksArr = benchmarkData.filter( (rec) => rec && (rec.event.id === item.event.id 
                                                                      && rec.ageGroup === item.ageGroup 
                                                                      && rec.gender === item.gender
                                                                  )
                                                );

      playerYB = item.player.YB;
      playerAgeGroup = item.ageGroup;
      playerGender = item.gender;
      playerDiffComp = '';

      const benchmarkObj = benchmarksArr && Array.isArray(benchmarksArr) && benchmarksArr[0];
      console.log('benchmarkObj', benchmarkObj);

      let rankInputObj = benchmarkObj && benchmarkObj.benchmark;
      console.log('rankInputObj', rankInputObj);

      // Build ranks array
      let ranks = [];

      // sort ranks array from 1 to 3
      if (benchmarkObj && benchmarkObj.benchmark) {
        benchmarkObj && benchmarkObj.benchmark && rankInputObj.sort((val1, val2) => val1.rank - val2.rank);

        rankInputObj.forEach(element => {
          const rankcomp = getRankDiff(element.time, item.time);

          let rankItem = {
            rankValue: element.time,
            comparison: rankcomp.comparison,
            rankDiff: rankcomp.rankDiff,
            playerName: element.playerName,
            yb: element.YB
          };
          ranks.push(rankItem);
          if (element.rank === 1) playerDiffComp = rankcomp;
        });
      }

      let cutoffStartComparison = benchmarkObj && getRankDiff(benchmarkObj?.cutoffStart?.time, item?.time);
      let cutoffEndComparison = benchmarkObj && getRankDiff(benchmarkObj?.cutoffEnd?.time, item?.time);

      // Cutoffs
      let cutOffRanks = {};

      if (benchmarkObj) {
        cutOffRanks = {
          cutoffStart: benchmarkObj && benchmarkObj.cutoffStart,
          cutoffStartComparison: cutoffStartComparison.comparison,
          cutoffStartDiff: cutoffStartComparison.rankDiff,
          cutoffEnd: benchmarkObj && benchmarkObj.cutoffEnd,
          cutoffEndComparison: cutoffEndComparison.comparison,
          cutoffEndDiff: cutoffEndComparison.rankDiff,
        }
      }

      let performanceItem = {
        event: item.event,
        eventOrder: item.event?.order,
        ageGroup: item.ageGroup,
        gender: item.gender,
        player: item.player,
        time: item.time,
        playerDiffComp: playerDiffComp,
        ranks: ranks,
        cutoffStart: benchmarkObj && benchmarkObj.cutoffStart,
        cutoffEnd: benchmarkObj && benchmarkObj.cutoffEnd,
        cutoffRanks: cutOffRanks
      }
      playerPerformacesArr.push(performanceItem);

    });
    playerPerformacesArr.sort((val1, val2) => val1.eventOrder - val2.eventOrder);

    console.log('playerPeformanceArr', playerPeformanceArr);
    
    let playerObj = playerItem;
    playerObj['yb'] = playerYB;
    playerObj['ageGroup'] = playerAgeGroup;
    playerObj['gender'] = playerGender;

    let playerPersonalBestItem = {
      entityType: 'player', //player or event
      entity: playerObj,
      performances: playerPerformacesArr
    }
    playerPersonalBest.push(playerPersonalBestItem);

    console.log('playerPersonalBest', playerPersonalBest);

  });
  return playerPersonalBest;
  
}

export const buildPlayerCompetitionData = (players, benchmarkLevel, benchmarkLevelValue, groupDetails, competitionData) => {

  let playerCompetition = [];

  const competitionPerf = competitionData;
  const benchmarkData = competitionPerf.competitionBenchmarkDetail;
  const playerPerformanceData = competitionPerf.playerPerformanceDetail;
  const playerParticipationData = competitionPerf.playerParticipation;

  console.log('benchmarkData', benchmarkData);
  console.log('playerPerformanceData', playerPerformanceData);

  let competitionsArr = [];

  // Player is selected, ignores ageGroup and gender
  console.log('players', players)
  console.log('players', players.length)
  if (players && players?.length > 0) {

    players.forEach(playerItem => {

      let playerPeformanceArr = playerPerformanceData.filter( (rec => rec && rec.player.id === playerItem.value) );
      

      let playerPerformacesArr = [];
      let playerYB = '';
      let targetTime = '';
      let targetComp = '';
      let gender = '';
      let ageGroup = '';
      let performanceLevel = '';
      

      playerPeformanceArr && Array.isArray(playerPeformanceArr) &&
      playerPeformanceArr.length > 0 && playerPeformanceArr.forEach(item => {
        
        playerYB = item.player.YB;
        gender = item.gender;

        // get AgeGroup
        let age = null;
        let ageGroupObj = {};
        

        if (playerYB) {
          age = new Date().getFullYear() - playerYB;
          ageGroupObj = getAgeGroupFromAge(groupDetails, age);
          ageGroup = ageGroupObj?.ageGroup;
        }
        else {
          ageGroup = item.ageGroup
        } 

        let competitionsArr = [];


        // Build benchmarks
        const benchmarksArr = benchmarkData.filter( (rec) => rec && (rec.event.id === item.event.id 
                                                                      && rec.ageGroup === ageGroup
                                                                      // && rec.benchmarkLevel === benchmarkLevel
                                                                      && rec.gender === item.gender
                                                                  )
                                                );


        const benchmarkObj = benchmarksArr && Array.isArray(benchmarksArr) && benchmarksArr[0];
        targetTime = benchmarkObj?.target?.time;

        let ranks = [];
        let nonRanks = [];
        let benchmarkItem = null;
        performanceLevel = benchmarkObj?.performaceLevel;

        if (benchmarkObj && Array.isArray(benchmarkObj.competitions) && benchmarkObj.competitions.length > 0) {

          for(let bItr = 0; bItr <= benchmarkObj.competitions.length - 1; bItr++) {
            let benchmarkInputObj = benchmarkObj.competitions[bItr].benchmark;

            
            

            benchmarkInputObj && benchmarkInputObj.sort((val1, val2) => val1.rank - val2.rank);
            // console.log('benchmarkInputObj', benchmarkInputObj);

            if (benchmarkInputObj) {
              if (
                bItr === 0 &&
                (
                  (benchmarkLevel === BenchmarkLevels.NATIONAL && benchmarkInputObj[0]?.state.indexOf('All') === 0) ||
                  (benchmarkLevel === BenchmarkLevels.NATIONAL && benchmarkInputObj[0]?.state.indexOf('All') < 0) ||
                  (benchmarkLevel === BenchmarkLevels.STATE && benchmarkInputObj[0]?.state.indexOf('All') === 0) ||
                  (benchmarkLevel === BenchmarkLevels.STATE && benchmarkInputObj[0]?.state.indexOf('All') < 0) ||
                  (benchmarkLevel === BenchmarkLevels.DISTRICT && benchmarkInputObj[0]?.state.indexOf('All') === 0) ||
                  (benchmarkLevel === BenchmarkLevels.DISTRICT && benchmarkInputObj[0]?.state.indexOf('All') < 0)
                )
              ){
                for(let idx = 0; idx <= benchmarkInputObj.length -1 ; idx++) {
                  let element = benchmarkInputObj[idx];

                  const rankcomp = getRankDiff(element.time, item.time);

                  // targetTime = element.time;
                  // targetComp = rankcomp.comparison;

                  benchmarkItem = {
                    rankValue: element.time,
                    comparison: rankcomp.comparison,
                    rankDiff: rankcomp.rankDiff,
                    playerName: element.playerName,
                    rank: element.rank,
                    state: element.state && element.state.indexOf('All') === 0 ? element.state.replace('All -', '') : element.state,
                    yb: element.YB
                  };
                  ranks.push(benchmarkItem);
                }
              }
              else {
                for(let idx = 0; idx <= benchmarkInputObj.length -1 ; idx++) {
                  let element = benchmarkInputObj[idx];
                  
                  const rankcomp = getRankDiff(element.time, item.time);
                  // targetTime = element.time;
                  // targetComp = rankcomp.comparison;

                  benchmarkItem = {
                    rankValue: element.time,
                    comparison: rankcomp.comparison,
                    rankDiff: rankcomp.rankDiff,
                    playerName: element.playerName,
                    rank: element.rank,
                    state: element.state && element.state,
                    yb: element.YB
                  };
                  nonRanks.push(benchmarkItem);
                }
              }
            }
          }

        }
        ranks.sort((val1, val2) => val1.rankValue?.localeCompare(val2.rankValue));
        nonRanks.sort((val1, val2) => val1.rankValue?.localeCompare(val2.rankValue));
        let competitionItem = {
          ranks: ranks,
          nonRanks: nonRanks
        };
        competitionsArr.push(competitionItem);

        // if(benchmarkLevel === BenchmarkLevels.NATIONAL) {
        //   targetTime = benchmarkObj?.target;
        //   targetComp = getRankDiff(benchmarkObj?.target, item.time)
        //   targetComp = targetComp?.comparison;
        // }

        let playerParticipations = playerParticipationData.filter( (rec) => rec && (rec.player.id === item.player.id));

        let participatedEvent = playerParticipations && Array.isArray(playerParticipations) && playerParticipations.length > 0 &&
                                  playerParticipations[0]?.events.filter( (rec) => rec && (rec.id === item.event.id) );
        
        participatedEvent = participatedEvent && Array.isArray(participatedEvent) && participatedEvent.length > 0
                            ? participatedEvent[0]
                            : {};

        let performanceItem = {
          benchmarkLevel: benchmarkObj?.benchmarkLevel,
          performanceLevel: benchmarkObj?.performaceLevel,
          event: item.event,
          eventOrder: item.event?.order,
          participatedInEvent: isObjectEmpty(participatedEvent) ? 'N' : 'Y',
          // ageGroup: ageGroupObj && ageGroupObj.ageGroup,
          gender: item.gender,
          player: item.player,
          time: item.time,
          target: benchmarkObj?.target,
          cutOff: benchmarkObj?.cutoffStart,
          nmr: benchmarkObj?.nmr && Array.isArray(benchmarkObj?.nmr) && benchmarkObj?.nmr[0],
          // targetTime: targetTime,
          // targetComp: targetComp,
          competitions: competitionsArr
        }
        playerPerformacesArr.push(performanceItem);

      });

      let playerObj = playerItem;
      playerObj['yb'] = playerYB;
      playerObj['gender'] = gender;
      playerObj['ageGroup'] = ageGroup;
      playerPerformacesArr.sort((val1, val2) => val1.eventOrder - val2.eventOrder);

      let playerCompetitionItem = {
        entityType: 'player', //player or event
        entity: playerObj,
        benchmarkLevel: benchmarkLevel,
        performanceLevel: performanceLevel,
        performances: playerPerformacesArr
      }
      playerCompetition.push(playerCompetitionItem);

    });

    return playerCompetition;
  }
  else {
    // Player is not selected, uses ageGroup and gender
    // Build benchmarks
    let ageGroup = '';
    let gender = '';
    let performanceLevel = '';

    benchmarkData && Array.isArray(benchmarkData) && benchmarkData.length > 0 &&
    benchmarkData.forEach (benchmarkDataItem => {

      const benchmarkObj = benchmarkDataItem;

      let ranks = [];
      let nonRanks = [];
      let benchmarkItem = {};

      if (benchmarkObj && Array.isArray(benchmarkObj.competitions) && benchmarkObj.competitions.length > 0) {

        for(let bItr = 0; bItr <= benchmarkObj.competitions.length - 1; bItr++) {
          // benchmarkObj.competitions.forEach (competition => {
          
          let benchmarkInputObj = benchmarkObj.competitions[bItr].benchmark;
          let rankItem = {};

          benchmarkInputObj && benchmarkInputObj.sort((val1, val2) => val1.rank - val2.rank);
          // console.log('benchmarkInputObj', benchmarkInputObj);

          if (benchmarkInputObj) {
            if (
              bItr === 0 &&
              (
                (benchmarkLevel === BenchmarkLevels.NATIONAL && benchmarkInputObj[0]?.state.indexOf('All') === 0) ||
                (benchmarkLevel === BenchmarkLevels.NATIONAL && benchmarkInputObj[0]?.state.indexOf('All') < 0) ||
                (benchmarkLevel === BenchmarkLevels.STATE && benchmarkInputObj[0]?.state.indexOf('All') === 0) ||
                (benchmarkLevel === BenchmarkLevels.STATE && benchmarkInputObj[0]?.state.indexOf('All') < 0) ||
                (benchmarkLevel === BenchmarkLevels.DISTRICT && benchmarkInputObj[0]?.state.indexOf('All') === 0) ||
                (benchmarkLevel === BenchmarkLevels.DISTRICT && benchmarkInputObj[0]?.state.indexOf('All') < 0)
              )
            ){
              for(let idx = 0; idx <= benchmarkInputObj.length -1 ; idx++) {
                let element = benchmarkInputObj[idx];

                const rankcomp = getRankDiff(benchmarkObj?.target?.time, element.time);

                rankItem = {
                  rankValue: element.time,
                  comparison: rankcomp.comparison,
                  rankDiff: rankcomp.rankDiff,
                  playerName: element.playerName,
                  rank: element.rank,
                  state: element.state && element.state.indexOf('All') === 0 ? element.state.replace('All -', '') : element.state,
                  yb: element.YB
                };
                ranks.push(rankItem);
              }
            }
            else {
              for(let idx = 0; idx <= benchmarkInputObj.length -1 ; idx++) {
                let element = benchmarkInputObj[idx];
                
                const rankcomp = getRankDiff(benchmarkObj?.target?.time, element.time);

                rankItem = {
                  rankValue: element.time,
                  comparison: rankcomp.comparison,
                  rankDiff: rankcomp.rankDiff,
                  playerName: element.playerName,
                  rank: element.rank,
                  state: element.state && element.state,
                  yb: element.YB
                };
                nonRanks.push(rankItem);
              }
            }
          }
        }
      };
      ranks.sort((val1, val2) => val1.rankValue?.localeCompare(val2.rankValue));
      nonRanks.sort((val1, val2) => val1.rankValue?.localeCompare(val2.rankValue));
      let ranksItem = {
        ranks: ranks,
        nonRanks: nonRanks
      };
      
      benchmarkItem['benchmarkLevel'] = benchmarkLevel;
      benchmarkItem['performanceLevel'] = benchmarkObj.performaceLevel;
      benchmarkItem['event'] = benchmarkObj.event;
      benchmarkItem['eventOrder'] = benchmarkObj.event?.order;
      benchmarkItem['ageGroup'] = benchmarkObj.ageGroup;
      benchmarkItem['gender'] = benchmarkObj.gender;
      benchmarkItem['target'] = benchmarkObj.target;
      benchmarkItem['cutOff'] = benchmarkObj.cutoffStart;
      benchmarkItem['nmr'] = benchmarkObj.nmr && Array.isArray(benchmarkObj.nmr) && benchmarkObj.nmr[0];
      benchmarkItem['competitions'] = ranksItem;
      ageGroup = benchmarkObj.ageGroup;
      gender = benchmarkObj.gender;
      performanceLevel = benchmarkObj.performaceLevel;
      competitionsArr.push(benchmarkItem);
    })


    competitionsArr.sort((val1, val2) => val1.eventOrder - val2.eventOrder);
    let entity = {};
    entity['ageGroup'] = ageGroup;
    entity['gender'] = gender;
    entity['benchmarkLevel'] = benchmarkLevel;
    entity['performanceLevel'] = performanceLevel;     
    entity['group'] = groupDetails.filter((item) => item.ageGroup === ageGroup)[0]?.group;

    let playerCompetitionItem = {
      entityType: 'competition', 
      entity: entity,
      benchmarkLevel: benchmarkLevel,
      performanceLevel: performanceLevel,
      competitions: competitionsArr
    }

    playerCompetition.push(playerCompetitionItem);

    return playerCompetition;
  
  }
  
  
}

export const buildPlayerRecommendationData = (recommendationData) => {

  let playerRecommendation = [];

  const recommendationPerf = recommendationData;
  const benchmarkData = recommendationPerf.recommendationBenchmarkDetail;
  const playerPerformanceData = recommendationPerf.playerRecommendationDetail;

  console.log('benchmarkData', benchmarkData);
  console.log('playerPerformanceData', playerPerformanceData);

  if (benchmarkData && Array.isArray(benchmarkData) && benchmarkData.length === 0) return;

  for (let bItr =0; bItr <= benchmarkData.length -1; bItr++) {
    let benchmarkElement = benchmarkData[bItr];
    let benchmarkItem = {};
    // add event related details to benchmarkItem
    benchmarkItem['entityType'] = 'player'; //player or event
    benchmarkItem['benchmarkLevel'] = benchmarkElement.benchmarkLevel;
    benchmarkItem['event'] = benchmarkElement.event;
    benchmarkItem['eventOrder'] = benchmarkElement.event?.order;
    benchmarkItem['ageGroup'] = benchmarkElement.ageGroup;
    benchmarkItem['gender'] = benchmarkElement.gender;
    benchmarkItem['target'] = benchmarkElement.target;
    benchmarkItem['cutOff'] = benchmarkElement.cutOff;

    let recommendedPlayerDetails = [];

    // Filter matching players from playerPerformanceData for the given event
    let recommendedPlayers = playerPerformanceData && playerPerformanceData.filter( (rec) => rec 
                                  &&  (rec.event.id === benchmarkElement.event.id 
                                  && rec.ageGroup === benchmarkElement.ageGroup
                                  && rec.gender === benchmarkElement.gender
                                ));
    
    if (recommendedPlayers && Array.isArray(recommendedPlayers) && recommendedPlayers.length > 0) {
      for (let rItr = 0; rItr <= recommendedPlayers.length - 1; rItr++) {
        let playerItem = recommendedPlayers[rItr];

        let recommendedPlayer = {};
        // playername (YB|Academy), PB, Benchmark (absolute value/delta/diff sign), Cutoff (Red/Green)
        recommendedPlayer['player'] = playerItem.player;
        recommendedPlayer['time'] = playerItem.time; //PB from timetrial or championship
        recommendedPlayer['performanceLevel'] = playerItem?.performanceLevel; //Timetrial or championship
        recommendedPlayer['academy'] = playerItem?.academy;
        // recommendedPlayer['recommended'] = playerItem?.recommended;

        let benchmark = {};
        let rankcomp = getRankDiff(benchmarkElement?.target?.time, playerItem.time);

        benchmark['rankValue'] = benchmarkElement?.target?.time;
        benchmark['comparison'] = rankcomp?.comparison;
        benchmark['rankDiff'] = rankcomp?.rankDiff;

        let cutOff = {};
        rankcomp = getRankDiff(benchmarkElement?.cutOff?.time, playerItem.time);
        cutOff['rankValue'] = benchmarkElement?.cutOff?.time;
        cutOff['comparison'] = rankcomp?.comparison;
        cutOff['rankDiff'] = rankcomp?.rankDiff;

        recommendedPlayer['benchmark'] = benchmark;
        recommendedPlayer['cutOff'] = cutOff;

        recommendedPlayerDetails.push(recommendedPlayer);
      }  
    }
    recommendedPlayerDetails && recommendedPlayerDetails.sort((val1, val2) => val1.time.localeCompare(val2.time));
    benchmarkItem['playerRecommendations'] = recommendedPlayerDetails;
    playerRecommendation.push(benchmarkItem);
  }
  playerRecommendation && playerRecommendation.sort((val1, val2) => val1.eventOrder - val2.eventOrder)
  return playerRecommendation;
  
}

export const buildBenchmarkData = (benchmarkInputData) => {

  console.log('benchmarkInputData', benchmarkInputData);

   let benchmarksResArr = [];

    benchmarkInputData && benchmarkInputData.map( (item) => {
      const benchmarkObj = item.benchmark && Array.isArray(item.benchmark) && item.benchmark;

      let nmr = item && item.nmr[0];

      let ranks = benchmarkObj;
      ranks && ranks.sort((val1, val2) => val1.rank - val2.rank);

      let genderItem = genders.find(element => element.key === item.gender)

      let benchmarkItem = {
          event: item.event,
          // eventOrder: item.event?.order,
          ageGroup: item.ageGroup,
          gender: genderItem && genderItem.value,
          nmr: nmr,
          ranks: ranks,
          cutoffStart: item && item.cutoffStart,
          cutoffEnd: item && item.cutoffEnd,
          year: item.year,
          month: item.month,
          benchmarkLevel: item.benchmarkLevel
      }
      benchmarksResArr.push(benchmarkItem);
    });
    benchmarksResArr.sort((val1, val2) => val1.event.order - val2.event.order);

  return benchmarksResArr;
  
}
