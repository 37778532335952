import { createContext } from 'react';
import { AUTH_USER_ME } from 'utils/constants';

const userObject = localStorage.getItem(AUTH_USER_ME) 
                ?
                JSON.parse(window.atob(localStorage.getItem(AUTH_USER_ME)))
                : '';
console.log('userObject', userObject);
console.log('userName', userObject.userName);

const authUserName = userObject?.userName;
const roles = userObject?.userMe?.roles;
const userMe = userObject?.userMe;

const intialState = {
    userName: authUserName,
    userMe: userMe,
    roles: roles
};

const authInitialState = { authenticatedUser: intialState, setAuthenticatedUser: undefined };

const AuthStateContext = createContext(authInitialState);

export { AuthStateContext, authInitialState }