import { Tabs, 
            TabList, 
            TabPanels, 
            Tab, 
            TabPanel,
            Grid,
            GridItem,
            Heading,
            Box,
            Card,
            CardBody,
            StatArrow,
            Stat,
            StatNumber,
            StatHelpText,
            StatLabel,
            Table,
            Thead,
            Tbody,
            Tfoot,
            Tr,
            Th,
            Td,
            TableCaption,
            TableContainer,
            Accordion,
            AccordionItem,
            AccordionButton,
            AccordionPanel,
            AccordionIcon,
            Text,
        } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { isObjectEmpty, initCaps } from 'utils/helpers';
import genders from 'mocks/genders.json';
import { FaSwimmer } from "react-icons/fa";
import './styles.css';

export default function AquaTab({tabData, displayType}) {

    const [isLargerThan1280, isDisplayingInBrowser] = useMediaQuery([
        '(min-width: 992px)',
        '(display-mode: browser)',
      ]);
    
    let tabList = '';

    if (displayType === 'recommendation') {
        tabList = tabData ?
                <TabList mb='1em'>
                    <Tab key="recommendation">
                        <Box>
                            <Text>Recommendation</Text>
                        </Box>
                    </Tab>
                </TabList>
            : ''
    }
    else if (displayType === 'competition') {
        let tabHeader = 'Competition';
        let item = tabData && Array.isArray(tabData) && tabData[0];
        let gender = item.entity.gender === 'M' ? 'Male' : 'Female';

        if (item?.entity?.group) tabHeader = tabHeader + ' for Group-' + item.entity.group;
        if (item?.entity?.gender) tabHeader = tabHeader + ', ' + gender;
        

        tabList = tabData ?
                <TabList mb='1em'>
                    <Tab key="recommendation">
                        <Box>
                            <Text>{tabHeader}</Text>
                        </Box>
                    </Tab>
                </TabList>
            : ''
    }
    else {
        tabList = tabData ?
            <TabList mb='1em'>
                {
                    tabData &&
                    tabData.map((item) => {
                        let tabHeader = item.entity?.label;
                        let tabSubHeader = '';
                        let gender = item.entity.gender === 'M' ? 'Male' : 'Female';
                        if (item.entity?.yb) tabHeader = tabHeader + ' YB - ' + item.entity.yb;
                        if (item.entity?.gender) tabSubHeader = gender;
                        if (item.entity?.ageGroup) tabSubHeader = tabSubHeader + ', ' + item.entity.ageGroup;
                        
                        return <Tab key={item.entity?.value}>
                                <Box>
                                    <Text>{tabHeader}</Text>
                                    <Text>{tabSubHeader}</Text>
                                </Box>
                                </Tab>
                    })
                }
            </TabList>
        : ''
    }
    

    const gridHeaderStyling = {
        border: '1px',
        borderColor: 'lightgray',
        background: 'black',
        minHeight: '50px',
        height: '50px'
      };
    
    const ParticipantHeaderStyling = {
        border: '1px',
        borderColor: 'lightgray',
        background: '#f2f2f2',
        minHeight: '50px',
        height: '50px'
    };

    const gridStyling = {
        border: '1px',
        borderColor: 'lightgray'
    };

    const vhAlign = {
        textAlign: {base: "left", md: "center"},
        alignSelf: "center",
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 'medium'
    };

    const pColAlign = {
        textAlign: "left",
        alignSelf: "center",
        color: 'black',
        textTransform: 'capitalize',
        fontSize: 'medium'
    };

    const vAlign = {
        textAlign: "center"
    };

    const standardDisplay = (item) => {
        return (<TableContainer>
            <Table variant='simple' {...gridStyling}>
                <Thead {...gridHeaderStyling} >
                <Tr>
                    <Th {...vhAlign}>{item.entityType === 'player' ? "Event" : "Player"}</Th>
                    <Th {...vhAlign}>Group</Th>
                    <Th {...vhAlign}>Time</Th>
                    <Th {...vhAlign}>Gold</Th>
                    <Th {...vhAlign}>Silver</Th>
                    <Th {...vhAlign}>Bronze</Th>
                    <Th {...vhAlign}>Cutoff Start</Th>
                    <Th {...vhAlign}>Cutoff End</Th>
                </Tr>
                </Thead>
                <Tbody>
                {
                    item.performances && 
                    Array.isArray(item.performances) && 
                    item.performances.length > 0
                    ?
                    item.performances.map((playerPerf, idx) => {
                        let genderItem = genders.find(element => element.key === playerPerf.gender)
                        const groupValue = playerPerf.ageGroup 
                                            ? playerPerf.ageGroup + ', ' + genderItem.value
                                            : genderItem.value;
                                    
                        return <Tr key={`player` + idx}>
                                <Td>
                                    <Box>
                                        <span>
                                                {
                                                    item.entityType === 'player' 
                                                    ? playerPerf.event.name
                                                    : playerPerf.player.displayName
                                                }
                                        </span>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box>
                                        <span>{groupValue}</span>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box >
                                        <Stat>
                                            <StatNumber fontSize='md'>{playerPerf.time}</StatNumber>
                                        </Stat>
                                    </Box>
                                </Td>
                                {
                                    playerPerf.ranks &&
                                    playerPerf.ranks.length > 0
                                    ?
                                        playerPerf.ranks.map((rank, rankIdx) => {
                                            return <Td key={`rank` + rankIdx}>
                                                    <Stat>
                                                        {/* <StatLabel>{idx === 0 ? 'Gold ' : idx === 1 ? 'Silver' : 'Bronze'}</StatLabel> */}
                                                        <StatNumber fontSize='md'>{rank.rankValue}</StatNumber>
                                                        <StatHelpText>
                                                            { rank.comparison === '+' 
                                                                ? 
                                                                <StatArrow type = 'increase'/> 
                                                                : rank.comparison === '-' 
                                                                    ? <StatArrow type = 'decrease'/> 
                                                                : '' }
                                                            {rank.rankDiff}
                                                        </StatHelpText>
                                                        <StatLabel>{rank.playerName}</StatLabel>
                                                        <StatLabel>{rank.yb ? "YB - " + rank.yb  : ""}</StatLabel>
                                                    </Stat>
                                                </Td>
                                        })
                                    : 
                                    <Td colSpan={3} {...vAlign} >
                                        <span>-</span>
                                    </Td>
                                }

                                {
                                    !isObjectEmpty(playerPerf?.cutoffRanks)
                                    ?
                                    <>
                                        <Td>
                                            <Box>
                                                <Stat>
                                                    <StatNumber fontSize='md'>{playerPerf.cutoffRanks?.cutoffStart.time}</StatNumber>
                                                    <StatHelpText>
                                                        { playerPerf.cutoffRanks?.cutoffStartComparison === '+' 
                                                            ? 
                                                            <StatArrow type = 'increase'/> 
                                                            : playerPerf.cutoffRanks?.cutoffStartComparison === '-' 
                                                                ? <StatArrow type = 'decrease'/> 
                                                            : '-' }
                                                        {playerPerf.cutoffRanks?.cutoffStartDiff}
                                                    </StatHelpText>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffStart.playerName}</StatLabel>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffStart.YB ? "YB - " + playerPerf.cutoffRanks.cutoffStart.YB  : ""}</StatLabel>
                                                </Stat>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Box>
                                                <Stat>
                                                    <StatNumber fontSize='md'>{playerPerf.cutoffRanks?.cutoffEnd.time}</StatNumber>
                                                    <StatHelpText>
                                                        { playerPerf.cutoffRanks?.cutoffEndComparison === '+' 
                                                            ? 
                                                            <StatArrow type = 'increase'/> 
                                                            : playerPerf.cutoffRanks?.cutoffEndComparison === '-' 
                                                                ? <StatArrow type = 'decrease'/> 
                                                            : '-' }
                                                        {playerPerf.cutoffRanks?.cutoffEndDiff}
                                                    </StatHelpText>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffEnd.playerName}</StatLabel>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffEnd.YB ? "YB - " + playerPerf.cutoffRanks.cutoffEnd.YB  : ""}</StatLabel>
                                                </Stat>
                                            </Box>
                                        </Td>
                                    </>
                                    :
                                    <>
                                        <Td colSpan={1} {...vAlign}>
                                            <span>-</span>
                                        </Td>
                                        <Td colSpan={1} {...vAlign}>
                                            <span>-</span>
                                        </Td>
                                    </>
                                }
                            </Tr>
                    })
                    :
                    <Tr
                    >
                        <Td >
                            <span>Player performance not available</span>
                        </Td>
                    </Tr>
                }
                </Tbody>
            </Table>
        </TableContainer>
        );
    } 

    const standardExtendedDisplay = (item) => {
        return (<TableContainer>
            <Table variant='simple' {...gridStyling}>
                <Thead {...gridHeaderStyling} >
                <Tr>
                    <Th {...vhAlign}>{item.entityType === 'player' ? "Event" : "Player"}</Th>
                    <Th {...vhAlign}>Time</Th>
                    <Th {...vhAlign}>Gold</Th>
                    <Th {...vhAlign}>Silver</Th>
                    <Th {...vhAlign}>Bronze</Th>
                    <Th {...vhAlign}>Cutoff Start</Th>
                    <Th {...vhAlign}>Cutoff End</Th>
                </Tr>
                </Thead>
                <Tbody>
                {
                    item.performances && 
                    Array.isArray(item.performances) && 
                    item.performances.length > 0
                    ?
                    item.performances.map((playerPerf, idx) => {
                        let genderItem = genders.find(element => element.key === playerPerf.gender)
                        const groupValue = playerPerf.ageGroup 
                                            ? playerPerf.ageGroup + ', ' + genderItem.value
                                            : genderItem.value;
                                    
                        return <Tr key={`player` + idx}>
                                <Td>
                                    <Box>
                                        <span>
                                                {
                                                    item.entityType === 'player' 
                                                    ? playerPerf.event.name
                                                    : playerPerf.player.displayName
                                                }
                                        </span>
                                    </Box>
                                </Td>
                                <Td>
                                    <Box >
                                        <Stat>
                                            <StatNumber fontSize='md'>{playerPerf.time}</StatNumber>
                                            <StatHelpText>
                                                { playerPerf.playerDiffComp.comparison === '+' 
                                                    ? 
                                                    <StatArrow type = 'increase'/> 
                                                    : playerPerf.playerDiffComp.comparison === '-' 
                                                        ? <StatArrow type = 'decrease'/> 
                                                    : '' }
                                                {playerPerf.playerDiffComp.rankDiff}
                                            </StatHelpText>
                                        </Stat>
                                    </Box>
                                </Td>
                                {
                                    playerPerf.ranks &&
                                    playerPerf.ranks.length > 0
                                    ?
                                        playerPerf.ranks.map((rank, rankIdx) => {
                                            return <Td key={`rank` + rankIdx}>
                                                    <Stat>
                                                        {/* <StatLabel>{idx === 0 ? 'Gold ' : idx === 1 ? 'Silver' : 'Bronze'}</StatLabel> */}
                                                        <StatNumber fontSize='md'>{rank.rankValue}</StatNumber>
                                                        <StatHelpText>
                                                            { rank.comparison === '+' 
                                                                ? 
                                                                <StatArrow type = 'increase'/> 
                                                                : rank.comparison === '-' 
                                                                    ? <StatArrow type = 'decrease'/> 
                                                                : '' }
                                                            {rank.rankDiff}
                                                        </StatHelpText>
                                                        <StatLabel>{rank.playerName}</StatLabel>
                                                        <StatLabel>{rank.yb ? "YB - " + rank.yb  : ""}</StatLabel>
                                                    </Stat>
                                                </Td>
                                        })
                                    : 
                                    <Td colSpan={3} {...vAlign} >
                                        <span>-</span>
                                    </Td>
                                }

                                {
                                    !isObjectEmpty(playerPerf?.cutoffRanks)
                                    ?
                                    <>
                                        <Td>
                                            <Box>
                                                <Stat>
                                                    <StatNumber fontSize='md'>{playerPerf.cutoffRanks?.cutoffStart?.time}</StatNumber>
                                                    <StatHelpText>
                                                        { playerPerf.cutoffRanks?.cutoffStartComparison === '+' 
                                                            ? 
                                                            <StatArrow type = 'increase'/> 
                                                            : playerPerf.cutoffRanks?.cutoffStartComparison === '-' 
                                                                ? <StatArrow type = 'decrease'/> 
                                                            : '-' }
                                                        {playerPerf.cutoffRanks?.cutoffStartDiff}
                                                    </StatHelpText>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffStart?.playerName}</StatLabel>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffStart?.YB ? "YB - " + playerPerf.cutoffRanks?.cutoffStart?.YB  : ""}</StatLabel>
                                                </Stat>
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Box>
                                                <Stat>
                                                    <StatNumber fontSize='md'>{playerPerf.cutoffRanks?.cutoffEnd?.time}</StatNumber>
                                                    <StatHelpText>
                                                        { playerPerf.cutoffRanks?.cutoffEndComparison === '+' 
                                                            ? 
                                                            <StatArrow type = 'increase'/> 
                                                            : playerPerf.cutoffRanks?.cutoffEndComparison === '-' 
                                                                ? <StatArrow type = 'decrease'/> 
                                                            : '-' }
                                                        {playerPerf.cutoffRanks?.cutoffEndDiff}
                                                    </StatHelpText>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffEnd?.playerName}</StatLabel>
                                                    <StatLabel>{playerPerf.cutoffRanks?.cutoffEnd?.YB ? "YB - " + playerPerf.cutoffRanks?.cutoffEnd?.YB  : ""}</StatLabel>
                                                </Stat>
                                            </Box>
                                        </Td>
                                    </>
                                    :
                                    <>
                                        <Td colSpan={1} {...vAlign}>
                                            <span>-</span>
                                        </Td>
                                        <Td colSpan={1} {...vAlign}>
                                            <span>-</span>
                                        </Td>
                                    </>
                                }
                            </Tr>
                    })
                    :
                    <Tr
                    >
                        <Td >
                            <span>Player performance not available</span>
                        </Td>
                    </Tr>
                }
                </Tbody>
            </Table>
        </TableContainer>
        );
    }
    
    const competitionPlayerAccordianDisplay = (item) => {
        let dataNotes = item && item.entity &&
                        item?.benchmarkLevel !== item?.performanceLevel
                        ? 'Showing data from ' + initCaps(item?.performanceLevel) + ' level competition'
                        : '';

        return ( 
            <>
                {
                    item.performances && 
                    Array.isArray(item.performances) && 
                    item.performances.length > 0
                    ?
                        <>
                        {dataNotes && <div style={{textAlign: 'right'}}>{dataNotes}</div>}
                        {<Box textAlign='right'><FaSwimmer style={{ width: '4em', height: '1.1em', display: 'inline'}}/> <span>- Participated Events</span></Box>}
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {
                                item.performances.map((playerPerf, idx) => {
                                    let genderItem = genders.find(element => element.key === playerPerf.gender)
                                    const groupValue = playerPerf.ageGroup 
                                                        ? playerPerf.ageGroup + ', ' + genderItem.value
                                                        : genderItem.value;
                                    
                                    let entityValue = 
                                        item.entityType === 'player' 
                                        ? playerPerf.event.name
                                        : playerPerf.player.displayName;
                                    
                                    let pb = '';
                                    let target = '';
                                    let cutOff = '';
                                    let nmr = '';

                                    // add player time
                                    if (playerPerf.time)
                                        pb = ', PB - ' + playerPerf.time;
                                    
                                    if (playerPerf?.target?.time)
                                        target = ', Target - ' + playerPerf.target?.time;
                                    
                                    if (playerPerf?.cutOff?.time)
                                        cutOff = ', CutOff - ' + playerPerf.cutOff?.time;

                                    if (playerPerf?.nmr?.nmr)
                                        nmr = ', NMR - ' + playerPerf?.nmr?.nmr;

                                    return <AccordionItem >
                                            <h2 className="accordion-item">
                                                <AccordionButton >
                                                    <Box as="span" flex='1' textAlign='left' >
                                                        <span style={{fontSize: 'medium'}}>{entityValue}</span>
                                                        <span style={{fontSize: 'small', color: 'brown'}}>{pb}</span>
                                                        <span style={{fontSize: 'small', color: 'blue'}}>{target}</span>
                                                        <span style={{fontSize: 'small', color: 'red'}}>{cutOff}</span>
                                                        <span style={{fontSize: 'small', color: 'green'}}>{nmr}</span>
                                                        {
                                                            playerPerf?.participatedInEvent === 'Y' ? <FaSwimmer style={{ width: '4em', height: '1.1em', display: 'inline'}}/>: ""
                                                        }
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size="sm" variant='simple' {...gridStyling} className='table-tiny'>
                                                        <Tbody>
                                                            <Tr>
                                                                {/* Rank Table */}
                                                                <Td width="50%">
                                                                {
                                                                    playerPerf && playerPerf.competitions && Array.isArray(playerPerf.competitions) &&
                                                                    playerPerf.competitions.length > 0
                                                                    ?
                                                                        <Table variant='simple' {...gridStyling} className='inner-table'>
                                                                            <TableCaption textAlign="left" m={1} p={0} placement="top">Top Competitors</TableCaption>
                                                                            {/* <Thead {...gridHeaderStyling} >
                                                                                <Tr>
                                                                                    <Th {...vhAlign}>Player Name</Th>
                                                                                    <Th {...vhAlign}>Rank</Th>
                                                                                    <Th {...vhAlign}>State</Th>
                                                                                </Tr>
                                                                            </Thead> */}
                                                                            <Tbody>
                                                                                {
                                                                                    playerPerf.competitions.map((competition, compIdx) => {
                                                                                        return competition && competition.ranks && Array.isArray(competition.ranks) &&
                                                                                        competition.ranks.length > 0 
                                                                                        ?
                                                                                            competition.ranks.map((rank, rankIdx) => {
                                                                                                let yb = rank.yb ? " (" + rank.yb + ")"  : "";
                                                                                                return <Tr key={`rank` + compIdx + rankIdx}>
                                                                                                    {/* Player Name column */}
                                                                                                    <Td>
                                                                                                        <Stat>
                                                                                                            {rank.playerName + yb}
                                                                                                            <StatHelpText fontSize="x-small">
                                                                                                                {rank.state}
                                                                                                            </StatHelpText>
                                                                                                        </Stat>
                                                                                                    </Td>
                                                                                                    {/* Rank column */}
                                                                                                    <Td>
                                                                                                        <Stat>
                                                                                                            {rank.rank}
                                                                                                            <StatHelpText fontSize="x-small">
                                                                                                                Rank
                                                                                                            </StatHelpText>
                                                                                                        </Stat>
                                                                                                        
                                                                                                    </Td>
                                                                                                    <Td>
                                                                                                        <Box>
                                                                                                            <Stat>
                                                                                                                {/* <StatNumber fontSize='md'>{rank.rank}</StatNumber> */}
                                                                                                                <StatNumber fontSize='md'>{rank.rankValue}</StatNumber>
                                                                                                                <StatHelpText>
                                                                                                                    { rank.comparison === '+' 
                                                                                                                        ? 
                                                                                                                        <StatArrow type = 'increase'/> 
                                                                                                                        : rank.comparison === '-' 
                                                                                                                            ? <StatArrow type = 'decrease'/> 
                                                                                                                        : '' }
                                                                                                                    {rank.rankDiff}
                                                                                                                </StatHelpText>
                                                                                                            </Stat>
                                                                                                        </Box>
                                                                                                    </Td>
                                                                                                    
                                                                                                </Tr>
                                                                                            })
                                                                                            
                                                                                        : <Tr><Td>No matching data</Td></Tr>
                                                                                    })
                                                                                }
                                                                            </Tbody>
                                                                        </Table>
                                                                        
                                                                    : 
                                                                    <Table variant='simple' {...gridStyling} >
                                                                        <TableCaption textAlign="left" m={1} p={0} placement="top">Finals</TableCaption>
                                                                        <Tbody>
                                                                            <Tr><Td>No matching data</Td></Tr>
                                                                        </Tbody>
                                                                    </Table>
                                                                }
                                                                </Td>
                                                                {/* Non Rank Table */}
                                                                <Td>
                                                                    <Table variant='simple' {...gridStyling} className='inner-table'>
                                                                    <TableCaption textAlign="left" m={1} p={0} placement="top">{initCaps(playerPerf?.performanceLevel)} Level Competitors</TableCaption>
                                                                        <Tbody>
                                                                            {
                                                                                playerPerf && playerPerf.competitions && Array.isArray(playerPerf.competitions) &&
                                                                                playerPerf.competitions.length > 0
                                                                                ?
                                                                                    playerPerf.competitions.map((competition, compIdx) => {
                                                                                        return competition && competition.nonRanks && Array.isArray(competition.nonRanks) &&
                                                                                        competition.nonRanks.length > 0 
                                                                                        ?
                                                                                        competition.nonRanks.map((nonRank, nonRankIdx) => {
                                                                                            let yb = nonRank.yb ? " (" + nonRank.yb + ")"  : "";
                                                                                            return <Tr key={`rank` + compIdx + nonRankIdx}>
                                                                                                {/* Player Name column */}
                                                                                                <Td>
                                                                                                    <Stat>
                                                                                                        {nonRank.playerName + yb}
                                                                                                        <StatHelpText fontSize="x-small">
                                                                                                            {nonRank.state}
                                                                                                        </StatHelpText>
                                                                                                    </Stat>
                                                                                                </Td>
                                                                                                {/* Rank column */}
                                                                                                <Td>
                                                                                                    <Stat>
                                                                                                        {nonRank.rank}
                                                                                                        <StatHelpText fontSize="x-small">
                                                                                                            Rank
                                                                                                        </StatHelpText>
                                                                                                    </Stat>
                                                                                                    
                                                                                                </Td>
                                                                                                <Td>
                                                                                                    <Box>
                                                                                                        <Stat>
                                                                                                            {/* <StatNumber fontSize='md'>{rank.rank}</StatNumber> */}
                                                                                                            <StatNumber fontSize='md'>{nonRank.rankValue}</StatNumber>
                                                                                                            <StatHelpText>
                                                                                                                { nonRank.comparison === '+' 
                                                                                                                    ? 
                                                                                                                    <StatArrow type = 'increase'/> 
                                                                                                                    : nonRank.comparison === '-' 
                                                                                                                        ? <StatArrow type = 'decrease'/> 
                                                                                                                    : '' }
                                                                                                                {nonRank.rankDiff}
                                                                                                            </StatHelpText>
                                                                                                        </Stat>
                                                                                                    </Box>
                                                                                                </Td>
                                                                                                
                                                                                            </Tr>
                                                                                        })
                                                                                            
                                                                                        : <Tr><Td>No matching data</Td></Tr>
                                                                                    })
                                                                                : <Tr><Td>No matching data</Td></Tr>
                                                                            }
                                                                        </Tbody>
                                                                    </Table>
                                                                </Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                })
                            }
                        </Accordion>
                        </>
                    : ""
                }
            </>
            
        );
    }

    const competitionAccordianDisplay = (item) => {
        let itemObj = item && Array.isArray(item) && item[0];
        let dataNotes = itemObj && itemObj.entity &&
                        itemObj?.benchmarkLevel !== itemObj?.performanceLevel
                        ? 'Showing data from ' + initCaps(itemObj?.performanceLevel) + ' level competition'
                        : '';
                
        return ( 
            <>
                {
                    itemObj  
                    ?
                        <>
                        {dataNotes && <div style={{textAlign: 'right'}}>{dataNotes}</div>}
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {
                                itemObj && itemObj.competitions && 
                                itemObj.competitions.map((competitionItem, idx) => {
                                    let genderItem = genders.find(element => element.key === competitionItem.gender)
                                    
                                    let entityValue = competitionItem.event.name;
                                    
                                    let cutOff = '';
                                    let target = '';
                                    let nmr = '';
                                    
                                    if (competitionItem?.target?.time)
                                        target = ', Target - ' + competitionItem?.target?.time;
                                    
                                    if (competitionItem?.cutOff?.time)
                                        cutOff = ', CutOff - ' + competitionItem?.cutOff?.time;

                                    if (competitionItem?.nmr?.nmr)
                                        nmr = ', NMR - ' + competitionItem?.nmr?.nmr;

                                        return <AccordionItem >
                                            <h2 className="accordion-item">
                                                <AccordionButton >
                                                    <Box as="span" flex='1' textAlign='left' >
                                                        <span style={{fontSize: 'medium'}}>{entityValue}</span>
                                                        <span style={{fontSize: 'small', color: 'blue'}}>{target}</span>
                                                        <span style={{fontSize: 'small', color: 'red'}}>{cutOff}</span>
                                                        <span style={{fontSize: 'small', color: 'green'}}>{nmr}</span>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size="sm" variant='simple' {...gridStyling} className='table-tiny'>
                                                        <Tbody>
                                                            <Tr>
                                                                {/* Rank Table */}
                                                                <Td width="50%">
                                                                {
                                                                    competitionItem.competitions 
                                                                    ?
                                                                        <Table variant='simple' {...gridStyling} className='inner-table'>
                                                                            <TableCaption textAlign="left" m={1} p={0} placement="top">Top Competitors</TableCaption>
                                                                            <Tbody>
                                                                                {
                                                                                    competitionItem.competitions && 
                                                                                    Array.isArray(competitionItem.competitions.ranks) &&
                                                                                    competitionItem.competitions.ranks.length > 0 
                                                                                        ?
                                                                                            competitionItem.competitions.ranks.map((rank, rankIdx) => {
                                                                                                let yb = rank.yb ? " (" + rank.yb + ")"  : "";
                                                                                                return <Tr key={`rank` +  rankIdx}>
                                                                                                    {/* Player Name column */}
                                                                                                    <Td>
                                                                                                        <Stat>
                                                                                                            {rank.playerName + yb}
                                                                                                            <StatHelpText fontSize="x-small">
                                                                                                                {rank.state}
                                                                                                            </StatHelpText>
                                                                                                        </Stat>
                                                                                                    </Td>
                                                                                                    {/* Rank column */}
                                                                                                    <Td>
                                                                                                        <Stat>
                                                                                                            {rank.rank}
                                                                                                            <StatHelpText fontSize="x-small">
                                                                                                                Rank
                                                                                                            </StatHelpText>
                                                                                                        </Stat>
                                                                                                        
                                                                                                    </Td>
                                                                                                    <Td>
                                                                                                        <Box>
                                                                                                            <Stat>
                                                                                                                {/* <StatNumber fontSize='md'>{rank.rank}</StatNumber> */}
                                                                                                                <StatNumber fontSize='md'>{rank.rankValue}</StatNumber>
                                                                                                                <StatHelpText>
                                                                                                                    { rank.comparison === '+' 
                                                                                                                        ? 
                                                                                                                        <StatArrow type = 'increase'/> 
                                                                                                                        : rank.comparison === '-' 
                                                                                                                            ? <StatArrow type = 'decrease'/> 
                                                                                                                        : '' }
                                                                                                                    {rank.rankDiff}
                                                                                                                </StatHelpText>
                                                                                                            </Stat>
                                                                                                        </Box>
                                                                                                    </Td>
                                                                                                    
                                                                                                </Tr>
                                                                                            })
                                                                                            
                                                                                        : <Tr><Td>No matching data</Td></Tr>
                                                                                }
                                                                            </Tbody>
                                                                        </Table>
                                                                        
                                                                    : 
                                                                    <Table variant='simple' {...gridStyling} >
                                                                        <TableCaption textAlign="left" m={1} p={0} placement="top">Finals</TableCaption>
                                                                        <Tbody>
                                                                            <Tr><Td>No matching data</Td></Tr>
                                                                        </Tbody>
                                                                    </Table>
                                                                }
                                                                </Td>
                                                                {/* Non Rank Table */}
                                                                <Td width="50%">
                                                                
                                                                {
                                                                    competitionItem.competitions 
                                                                    ?
                                                                        <Table variant='simple' {...gridStyling} className='inner-table'>
                                                                            <TableCaption textAlign="left" m={1} p={0} placement="top">{initCaps(itemObj?.performanceLevel)} Level Competitors</TableCaption>
                                                                            <Tbody>
                                                                                
                                                                                {
                                                                                    competitionItem.competitions && 
                                                                                    Array.isArray(competitionItem.competitions.ranks) &&
                                                                                    competitionItem.competitions.nonRanks.length > 0 
                                                                                        ?
                                                                                            competitionItem.competitions.nonRanks.map((rank, rankIdx) => {
                                                                                                let yb = rank.yb ? " (" + rank.yb + ")"  : "";
                                                                                                return <Tr key={`rank` +  rankIdx}>
                                                                                                    {/* Player Name column */}
                                                                                                    <Td>
                                                                                                        <Stat>
                                                                                                            {rank.playerName + yb}
                                                                                                            <StatHelpText fontSize="x-small">
                                                                                                                {rank.state}
                                                                                                            </StatHelpText>
                                                                                                        </Stat>
                                                                                                    </Td>
                                                                                                    {/* Rank column */}
                                                                                                    <Td>
                                                                                                        <Stat>
                                                                                                            {rank.rank}
                                                                                                            <StatHelpText fontSize="x-small">
                                                                                                                Rank
                                                                                                            </StatHelpText>
                                                                                                        </Stat>
                                                                                                        
                                                                                                    </Td>
                                                                                                    <Td>
                                                                                                        <Box>
                                                                                                            <Stat>
                                                                                                                {/* <StatNumber fontSize='md'>{rank.rank}</StatNumber> */}
                                                                                                                <StatNumber fontSize='md'>{rank.rankValue}</StatNumber>
                                                                                                                <StatHelpText>
                                                                                                                    { rank.comparison === '+' 
                                                                                                                        ? 
                                                                                                                        <StatArrow type = 'increase'/> 
                                                                                                                        : rank.comparison === '-' 
                                                                                                                            ? <StatArrow type = 'decrease'/> 
                                                                                                                        : '' }
                                                                                                                    {rank.rankDiff}
                                                                                                                </StatHelpText>
                                                                                                            </Stat>
                                                                                                        </Box>
                                                                                                    </Td>
                                                                                                    
                                                                                                </Tr>
                                                                                            })
                                                                                            
                                                                                        : <Tr><Td>No matching data</Td></Tr>
                                                                                }
                                                                            </Tbody>
                                                                        </Table>
                                                                        
                                                                    : 
                                                                    <Table variant='simple' {...gridStyling} >
                                                                        <TableCaption textAlign="left" m={1} p={0} placement="top">Finals</TableCaption>
                                                                        <Tbody>
                                                                            <Tr><Td>No matching data</Td></Tr>
                                                                        </Tbody>
                                                                    </Table>
                                                                }
                                                                </Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                })
                            }
                        </Accordion>
                        </>
                    : ""
                }
            </>
            
        );
    }

    const recommendationDisplay = (item) => {

        return ( 
            <>
                {
                    item  
                    ?
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {
                                item.map((recommendItem, idx) => {
                                    let genderItem = genders.find(element => element.key === recommendItem.gender)
                                    const groupValue = recommendItem.ageGroup 
                                                        ? recommendItem.ageGroup + ', ' + genderItem?.value
                                                        : genderItem?.value;
                                    
                                    let entityValue = 
                                        recommendItem.entityType === 'player' 
                                        ? recommendItem.event.name
                                        : recommendItem.player.displayName;
                                    
                                    let cutOff = '';
                                    let target = '';
                                    
                                    if (recommendItem?.target?.time)
                                        target = ', Target - ' + recommendItem?.target?.time;
                                    
                                    if (recommendItem?.cutOff?.time)
                                        cutOff = ', CutOff - ' + recommendItem?.cutOff?.time;

                                    return <AccordionItem >
                                            <h2 className="accordion-item">
                                                <AccordionButton >
                                                    <Box as="span" flex='1' textAlign='left' >
                                                        <span style={{fontSize: 'medium'}}>{entityValue}</span>
                                                        <span style={{fontSize: 'small', color: 'blue'}}>{target}</span>
                                                        <span style={{fontSize: 'small', color: 'red'}}>{cutOff}</span>
                                                    </Box>
                                                    <AccordionIcon />
                                                </AccordionButton>
                                            </h2>
                                            <AccordionPanel pb={4}>
                                                <TableContainer>
                                                    <Table size="sm" variant='simple' {...gridStyling} className='table-tiny'>
                                                        <Tbody>
                                                            <Tr>
                                                                {/* Participants Table */}
                                                                <Td >
                                                                {
                                                                    <Table variant='simple' {...gridStyling} className='inner-table'>
                                                                        <TableCaption textAlign="left" m={1} p={0} placement="top">Participants</TableCaption>
                                                                        <Thead {...ParticipantHeaderStyling} >
                                                                            <Tr>
                                                                                <Th {...pColAlign}>Player Name</Th>
                                                                                <Th {...pColAlign}>PB</Th>
                                                                                <Th {...pColAlign}>Benchmark</Th>
                                                                                <Th {...pColAlign}>CutOff</Th>
                                                                            </Tr>
                                                                        </Thead>
                                                                        <Tbody>
                                                                        {
                                                                            recommendItem && recommendItem.playerRecommendations && 
                                                                            Array.isArray(recommendItem.playerRecommendations) &&
                                                                            recommendItem.playerRecommendations.length > 0
                                                                            ?
                                                                                recommendItem.playerRecommendations.map((recommendation, recomIdx) => {
                                                                                    let playerName = '';
                                                                                    if (recommendation.player && recommendation.player.displayName)
                                                                                        playerName = recommendation.player.displayName;
                                                                                    
                                                                                    if (recommendation.player && recommendation.player.YB && recommendation.player.district)
                                                                                        playerName = playerName +  ' (' + recommendation.player.YB + ' | ' + recommendation.player.district + ')';
                                                                                    else if (recommendation.player && recommendation.player.YB)
                                                                                        playerName = playerName + ' (' + recommendation.YB  + ')';
                                                                                    else if (recommendation.player.district)
                                                                                        playerName = playerName + ' (' + recommendation.player.district  + ')';

                                                                                    return  <Tr key={`rank` + recomIdx}>
                                                                                            {/* Player Name column */}
                                                                                            <Td>
                                                                                                {playerName}
                                                                                            </Td>
                                                                                            <Td>
                                                                                                {recommendation.time}
                                                                                            </Td>
                                                                                            {/* Benchmark column */}
                                                                                            <Td>
                                                                                                <Box>
                                                                                                    <Stat>
                                                                                                        <StatNumber fontSize='md'>{recommendation?.benchmark?.rankValue}</StatNumber>
                                                                                                        <StatHelpText>
                                                                                                            { recommendation?.benchmark?.comparison === '+' 
                                                                                                                ? 
                                                                                                                <StatArrow type = 'increase'/> 
                                                                                                                : recommendation?.benchmark?.comparison === '-' 
                                                                                                                    ? <StatArrow type = 'decrease'/> 
                                                                                                                : '' }
                                                                                                            {recommendation?.benchmark?.rankDiff}
                                                                                                        </StatHelpText>
                                                                                                    </Stat>
                                                                                                </Box>
                                                                                            </Td>
                                                                                            {/* CutOff column */}
                                                                                            <Td>
                                                                                                <Box>
                                                                                                    <Stat>
                                                                                                        <StatNumber fontSize='md'>{recommendation?.cutOff?.rankValue}</StatNumber>
                                                                                                        <StatHelpText>
                                                                                                            { recommendation?.cutOff?.comparison === '+' 
                                                                                                                ? 
                                                                                                                <StatArrow type = 'increase'/> 
                                                                                                                : recommendation?.cutOff?.comparison === '-' 
                                                                                                                    ? <StatArrow type = 'decrease'/> 
                                                                                                                : '' }
                                                                                                            {recommendation?.cutOff?.rankDiff}
                                                                                                        </StatHelpText>
                                                                                                    </Stat>
                                                                                                </Box>
                                                                                            </Td>
                                                                                            
                                                                                        </Tr>
                                                                                    })
                                                                                    
                                                                            : <Tr><Td>No matching data</Td></Tr>
                                                                        }
                                                                        </Tbody>
                                                                    </Table>
                                                                }
                                                                </Td>
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionPanel>
                                        </AccordionItem>
                                })
                            }
                        </Accordion>
                    : ""
                }
            </>
            
        );
    }

    const tabPanels = (
        <TabPanels style={{padding: '2px'}}>
            {
                tabData && 
                tabData.map((item) => {
                    return <TabPanel key={item.entity?.value}>
                                {
                                    displayType === 'standard' && standardDisplay(item)
                                }
                                {
                                    displayType === 'standardExtended' && standardExtendedDisplay(item)
                                }
                                {
                                    displayType === 'competitionPlayer' && competitionPlayerAccordianDisplay(item)
                                }
                            </TabPanel>        
                })
            }
            {
                tabData && displayType === 'competition' && competitionAccordianDisplay(tabData)
            }
            {
                tabData && displayType === 'recommendation' && recommendationDisplay(tabData)
            }
        </TabPanels>
    );
    
    return (<Tabs padding={3} isFitted variant='enclosed'>
        {tabList}
        {tabPanels}
    </Tabs>);
}