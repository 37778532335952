import React, { useState, useEffect, useRef, useMemo } from "react";
import useSwr  from "swr";
import {
  Box,
  FormLabel,
  Grid,
  GridItem,
  Center,
  IconButton,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Text,
  Skeleton
} from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';
import { AddIcon } from "@chakra-ui/icons";
import { usePlayersState } from 'hooks/usePlayers';
import { useEventsState } from 'hooks/useEvents';
import { useGlobalPerformanceTypeState } from "hooks/useGlobalPerformanceType";
import { useAuthState } from 'hooks/useAuth';

import { Select } from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import { default as ReactSelect } from "react-select";

import { useClient } from "hooks/useClient";
import fetcher from "utils/fetcher";
import * as Messages from "utils/messages";
import benchmarkLevels from 'mocks/benchmarks.json';
import ageGroups from 'mocks/ageGroups.json';
// import states from 'mocks/states.json'
import {
  GET_ALL_PLAYERS as getAllPlayers,
  GET_ALL_EVENTS as getAllEvents,
  GET_ALL_GROUPS as getAllGroups,
  GET_PLAYER_COMPETITION as getPlayerCompetition,
  GET_ALL_STATES as getAllStatesDB,
  GET_ALL_TOURNAMENTS as getAllTournaments,
  GET_PARENT_TOURNAMENTS as getParentTournaments
} from "./queries";

import { StandardHeader } from "components/Header";
import Footer from "components/Footer";
import Loader from "components/Loader";

import SelectAdd from "components/UI/SelectAdd";
import AquaTag from "components/UI/AquaTag";
import AquaTab from "components/UI/AquaTab";
import { useToast } from '@chakra-ui/react';

import { isArrayEmpty, isAuthenticated, initCaps } from "utils/helpers";
import { buildEventsArray, buildStatesArray } from 'pages/Player/helpers';
import { buildPlayerCompetitionData, buildAgeGroupsArray } from 'pages/Player/helpers';

import {
  PLAYERS_LIMIT,
  EVENTS_LIMIT,
  AUTH_REDIRECT_ROUTE,
  DEFAULT_STATE,
  DEFAULT_AGE_GROUP,
  DEFAULT_GENDER,
  BenchmarkLevels,
  ADMIN_ROLE,
  COACH_ROLE,
  PLAYER_ROLE
} from "utils/constants";

export const PlayerCompetition = (props) => {


  const DEFAULT_PLAYER = [{
    label: "Ashwath Ayyalasomayajula",
    value: "9E98605B64D34FD2BF8FE02665577D54",
  }];

  const DEFAULT_BENCHMARK_LEVEL = 'NATIONAL';
  const DEFAULT_COMPETITION_TYPE = 'O';

  const navigate = useNavigate();

  const [shouldFetch, setShouldFetch] = useState(false);
  const isPlayerLoading = useRef(false);

  const [isLoading, setIsLoading] = useState(false);

  const [nodata, setNoData] = useState(false);

  const {players, setPlayers} = usePlayersState();
  const {events, setEvents} = useEventsState();
  const [tournaments, setTournaments] = useState([]);

  const [selectedPerfEvents, setSelectedPerfEvents] = useState(
                                events && !isArrayEmpty(events)
                                ? events
                                : []
                              );
  const [selectedPlayers, setSelectedPlayers] = useState(
                                players && !isArrayEmpty(players)
                                ? players
                                : []
                              );
  const [selectedBenchmarkLevel, setSelectedBenchmarkLevel] = useState(DEFAULT_BENCHMARK_LEVEL);
  const [selectedAgeGroup, setSelectedAgeGroup] = useState(DEFAULT_AGE_GROUP);
  const [selectedGender, setSelectedGender] = useState(DEFAULT_GENDER);
  const [selectedCompetitionType, setSelectedCompetitionType] = useState(DEFAULT_COMPETITION_TYPE);
  const [selectedTournamentId, setSelectedTournamentId] = useState('')

  const [selectedState, setSelectedState] = useState(DEFAULT_STATE);

  const [playerList, setPlayerList] = useState([]);
  const [ eventsList, setEventsList] = useState();
  const [ statesList, setStatesList] = useState();
  const [ ageGroupsList, setAgeGroupsList] = useState();

  const [shortlistedPlayers, setShortlistedPlayers] = useState([]);
  const [shortlistedEvents, setShortlistedEvents] = useState();
  const [showPlayerError, setShowPlayerError] = useState(false);


  const [playerCompetitionData, setPlayerCompetitionData] = useState();

  const [showSelectPlayer, setShowSelectPlayer] = useState(false);
  const [showSelectEvent, setShowSelectEvent] = useState(false);
  const {authenticatedUser, setAuthenticatedUser} = useAuthState();

  let playerProfile = {};

  const queryParamsRef = useRef({
    // playerIds: selectedPlayers.map(item => item.value),
    benchmarkLevel: selectedBenchmarkLevel,
    // state: selectedState,
    ageGroup: DEFAULT_AGE_GROUP,
    gender: DEFAULT_GENDER
  });

  const competitionTypeChangedHandler = (e) => {
    let paramObj = {};

    if (e === DEFAULT_COMPETITION_TYPE) {
      paramObj = {
        ...queryParamsRef.current,
      };
      delete paramObj.playerIds;
      updateQueryParam(paramObj);
      setSelectedCompetitionType(e);
      setSelectedPlayers([]);
    }
    else {
      // player type selected
      paramObj = {
        ...queryParamsRef.current,
      };
      updateQueryParam(paramObj);
      setSelectedCompetitionType(e);
    }
    setPlayerCompetitionData('');
  }

  const ageGroupChangedHandler = (e) => {
    e.preventDefault();
    
    let paramObj = {
      ...queryParamsRef.current,
      ageGroup: e.currentTarget.value
    };
    
    updateQueryParam(paramObj);
    setSelectedAgeGroup(e.currentTarget.value);
  }

  const genderChangedHandler = (e) => {
    
    console.log(e);

    let paramObj = {
      ...queryParamsRef.current,
      gender: e
    };
    
    updateQueryParam(paramObj);
    setSelectedGender(e);
  }

  const eventChangedHandler = (inputValue) => {
    if (!inputValue) return;

    const shortlistedEventsArr = [];

    inputValue.map((event) => {
      shortlistedEventsArr.push({'label' : event.label, 'value': event.value});
    });

    let currentShortListedEvents = shortlistedEvents;

    if (typeof currentShortListedEvents !== 'undefined' && !currentShortListedEvents) 
    currentShortListedEvents.push(...shortlistedEventsArr);
    else  
    currentShortListedEvents = shortlistedEventsArr;
    setShortlistedEvents(currentShortListedEvents);
  };

  const benchmarkLevelChangedHandler = async (e) => {

    e.preventDefault();
    
    let paramObj = {
      ...queryParamsRef.current,
      benchmarkLevel: e.currentTarget.value
    };

    if (e.currentTarget.value === BenchmarkLevels.NATIONAL) {
      delete paramObj.state;
      setSelectedState('');
    }
    else if (e.currentTarget.value === BenchmarkLevels.STATE) {
      setSelectedState(DEFAULT_STATE);
      paramObj['state'] = selectedState || DEFAULT_STATE;
    }
    
    updateQueryParam(paramObj);
    setSelectedBenchmarkLevel(e.currentTarget.value);

    // let queryParam = {
    //   benchmarkType: e.currentTarget.value
    // }

    // console.log('queryParam', queryParam)

    // const tournamentResData = await fetcher(getAllTournaments, client, queryParam);

    // const tournamentsArr = buildTournamentsArray(
    //   tournamentResData?.getAllTournaments?.tournaments
    // );

    // setTournaments(tournamentsArr);

  }

  const StateChangedHandler = (e) => {
    e.preventDefault();

    // set state value
    let paramObj = {};

    if (e.currentTarget.value) {
      paramObj = {
        ...queryParamsRef.current,
        state: e.currentTarget.value
      }
    }
    else {
      paramObj = {
        ...queryParamsRef.current
      };
      delete paramObj.state;
    }

    
    updateQueryParam(paramObj);

    setSelectedState(e.currentTarget.value);
  }

  const playerChangedHandler = (inputValue) => {
    if (!inputValue) return;

    const shortlistedPlayersArr = [];

    inputValue.map((player) => {
      shortlistedPlayersArr.push({'label' : player.label, 'value': player.value});
    });

    let currentShortListedPlayers = shortlistedPlayers;

    if (typeof currentShortListedPlayers !== 'undefined' && !currentShortListedPlayers) 
      currentShortListedPlayers.push(...shortlistedPlayersArr);
    else  
      currentShortListedPlayers = shortlistedPlayersArr;

    console.log('currentShortListedPlayers', currentShortListedPlayers);

    setShortlistedPlayers(currentShortListedPlayers);
  };

  const tournamentChangedHandler = (e) => {

    e.preventDefault();
    console.log('e.currentTarget.value', e.currentTarget.value)
    if (e.currentTarget.value != "") {
      let paramObj = {
        ...queryParamsRef.current,
        parentTournament: e.currentTarget.value
      };
  
      updateQueryParam(paramObj);
    }
    else if (e.currentTarget.value === "") {
      let paramObj = queryParamsRef.current;
      delete paramObj.parentTournament;
      updateQueryParam(paramObj);
    }
    console.log('tournament changed', queryParamsRef);
    setSelectedTournamentId(e.currentTarget.value);
    
  }

  const playerAddedHandler = () => {

     // Check if any value is selected
     if (!shortlistedPlayers || (Array.isArray(shortlistedPlayers) && shortlistedPlayers.length === 0 )) {
      toast({
        title: 'Choose a Player',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    let currentSelectedPlayers = [...selectedPlayers];

    // Check for duplicates
    const duplicatePlayers = selectedPlayers.filter(itr => shortlistedPlayers.some(sp => sp.value  === itr.value));

    if (duplicatePlayers && Array.isArray(duplicatePlayers) && duplicatePlayers.length > 0) {
      toast({
        title: 'Player Already added',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    // Maximum 5 players are allowed
    if (selectedPlayers.length + shortlistedPlayers.length > PLAYERS_LIMIT) {
      toast({
        title: `Max ${PLAYERS_LIMIT} players can be added`,
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    if (Array.isArray(currentSelectedPlayers) && currentSelectedPlayers.length > 0) 
      currentSelectedPlayers.push(...shortlistedPlayers);
    else  
      currentSelectedPlayers = shortlistedPlayers;

    let paramObj = {
      ...queryParamsRef.current,
      playerIds: currentSelectedPlayers.map((item) => item.value)
    };
    
    updateQueryParam(paramObj);

    setSelectedPlayers(currentSelectedPlayers);
    setShowSelectPlayer(false);
    setShowPlayerError(false);
  }

  const eventAddedHandler = () => {

    // Check if any value is selected
    if (!shortlistedEvents || (Array.isArray(shortlistedEvents) && shortlistedEvents.length === 0 )) {
      toast({
        title: 'Choose an Event',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    let currentSelectedEvents = [...selectedPerfEvents];

    // Check for duplicates
    const duplicateEvents = selectedPerfEvents.filter(itr => shortlistedEvents.some(se => se.value  === itr.value));

    if (duplicateEvents && Array.isArray(duplicateEvents) && duplicateEvents.length > 0) {
      toast({
        title: 'Event Already added',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    // Maximum 5 Events are allowed
    if (selectedPerfEvents.length + shortlistedEvents.length > EVENTS_LIMIT) {
      toast({
        title: `Max ${EVENTS_LIMIT} events can be added`,
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    if (Array.isArray(currentSelectedEvents) && currentSelectedEvents.length > 0) 
      currentSelectedEvents.push(...shortlistedEvents);
    else  
      currentSelectedEvents = shortlistedEvents;

   

    let paramObj = {
      ...queryParamsRef.current,
      eventIds: currentSelectedEvents.map((item) => item.value)
    };

    updateQueryParam(paramObj);

    setSelectedPerfEvents(currentSelectedEvents);
    setShowSelectEvent(false);
    

  }

  const client = useClient();
  const toast = useToast();

  const updateQueryParam = (paramObj) => {
    queryParamsRef.current = paramObj;
  };

  const buildPlayersArray = (playersObj) => {
    const playersArr = [];

    playersObj.map((playerObj) => {
      let playerItem = {};
      playerItem["label"] = playerObj.firstName + ' ' + playerObj.lastName;
      playerItem["value"] = playerObj.id;
      playersArr.push(playerItem);
    });

    return playersArr;
  };

  const getPlayers = async () => {
    try {
      const playerResData = await fetcher(getAllPlayers, client);
      const playersArr = buildPlayersArray(
        playerResData?.getAllPlayers?.players
      );
      setPlayerList(playersArr);
    } catch (error) {
      console.log('error', error);
      isPlayerLoading.current = false;
    }
  };

  const getEvents = async () => {
    try {
        const eventsResData = await fetcher(getAllEvents, client);
        const eventsArr = buildEventsArray(eventsResData?.getAllEvents?.events);
        setEventsList(eventsArr);
    }
    catch(error) {
      console.log('error', error);
      setEventsList([]);
    }
  }

  const getStates = async () => {
    try {
        const statesResData = await fetcher(getAllStatesDB, client);
        const statesArr = buildStatesArray(statesResData?.getAllStatesDB?.states);
        setStatesList(statesArr);
    }
    catch(error) {
      console.log('error', error);
      setStatesList([]);
    }
  }

  const getAgeGroups = async () => {
    // setAgeGroupsList(ageGroups);
    console.log('getAgeGroups');
    try {
      // const groupsResData = await fetcher(getAllGroups, client);
      setAgeGroupsList(ageGroups);
    }
    catch(error) {
      console.log('error', error);
      setAgeGroupsList([]);
    }
  }

  const buildTournamentsArray = (tournamentsObj) => {
    const tournamentsArr = [];

    tournamentsObj && tournamentsObj.map((tournamentObj) => {
      let tournamentItem = {};
      tournamentItem["label"] = tournamentObj.name;
      tournamentItem["value"] = tournamentObj.id;
      tournamentsArr.push(tournamentItem);
    });

    return tournamentsArr;
  };

  const getTournaments = async () => {
    try {
      const tournamentResData = await fetcher(getParentTournaments, client);

      const tournamentsArr = buildTournamentsArray(
        tournamentResData?.getParentTournaments?.tournaments
      );
      console.log('tournamentsArr', tournamentsArr)
      setTournaments(tournamentsArr);
    } catch (error) {
      console.log('error', error);
    }
  };

  const { data: playerCompetitionApiData, error} = useSwr(
    shouldFetch
      ? [getPlayerCompetition, client, queryParamsRef.current]
      : null,
    fetcher,
    {
      loadingTimeout: 800,
      onError: () => {
        console.log('onerror')
        setIsLoading(false);
      },
    }
  );

  useEffect(() => {
    getPlayers();
    getEvents();
    getAgeGroups();
    getStates();
    getTournaments();
    
    if (hasFullPermission()) {
      // setShouldFetch(true);
      // setIsLoading(true);
    }
    else if (isRolePlayer()) {
      let playerProfile = getPlayerProfile();
      let paramObj = {
        ...queryParamsRef.current,
        playerIds: [playerProfile.id]
      };

      delete paramObj.ageGroup;
  
      updateQueryParam(paramObj);
      // setShouldFetch(true);
      // setIsLoading(true);
      setSelectedPlayers([{
        label: playerProfile.profileName,
        value: playerProfile.id
      }])
    }
    console.log('useeffect - queryParamsRef', queryParamsRef)
    setShouldFetch(true);
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!playerCompetitionApiData) return;

    // set should fetch to false
    setShouldFetch(false);
    console.log('playerCompetitionApiData', playerCompetitionApiData);
    const playerCompetitionDataRes =
    playerCompetitionApiData?.getPlayerCompetition;
    console.log('playerCompetitionDataRes', playerCompetitionDataRes)
    // If player performance is null then show no data found
    if (!playerCompetitionDataRes) {
      console.log('empty playerCompetitionDataRes', playerCompetitionDataRes)
      setIsLoading(false);
      setNoData(true);
      return;
    }

    console.log('selected players', selectedPlayers);
    console.log('selected selectedPerfEvents', selectedPerfEvents);

    const performanceObj = buildPlayerCompetitionData(selectedPlayers, selectedBenchmarkLevel, selectedState, ageGroupsList, playerCompetitionDataRes);

    console.log('performanceObj', performanceObj);

    setPlayerCompetitionData(performanceObj);

    setIsLoading(false);
  }, [playerCompetitionApiData]);

  useEffect(() => {

  }, [shortlistedPlayers])

  useEffect(() => {

  }, [shortlistedEvents])

  const searchClickedHandler = () => {
    console.log('competitionType', selectedCompetitionType);
    // if ((selectedCompetitionType !== DEFAULT_COMPETITION_TYPE  || !hasFullPermission()) && selectedPlayers.length === 0) {
    //   setShowPlayerError(true);
    // }
    // else {
    //   console.log('queryParamsRef', queryParamsRef);
    //   setShowPlayerError(false);
    //   setIsLoading(true);
    //   setNoData(false);
    //   setShouldFetch(true);
    //   setPlayerCompetitionData('');
    // }

    if (selectedCompetitionType !== DEFAULT_COMPETITION_TYPE && selectedPlayers.length === 0) {
      setShowPlayerError(true);
      return;
    }
    
    if (selectedCompetitionType !== DEFAULT_COMPETITION_TYPE || isRolePlayer()) {
      let paramObj = {
        ...queryParamsRef.current,
      };

      delete paramObj.ageGroup;
      updateQueryParam(paramObj);
    }

    console.log('searchClickedHandler - queryParamsRef', queryParamsRef);

    setShowPlayerError(false);
    setIsLoading(true);
    setNoData(false);
    setShouldFetch(true);
    setPlayerCompetitionData('');
    

  }

  const playerTagClickedHandler = (e, value) => {
    const currentPlayers = [...selectedPlayers];
    const updatedPlayers = currentPlayers.filter( (player) => player && player.value !== value );

    let paramObj = {
      ...queryParamsRef.current,
    };

    if (updatedPlayers && Array.isArray(updatedPlayers) && updatedPlayers.length === 0) {
      delete paramObj.playerIds;
    }
    else {
      paramObj['playerIds'] = updatedPlayers.map((item) => item.value)
    }
    
    updateQueryParam(paramObj);

    setSelectedPlayers([...updatedPlayers]);
  }

  const eventTagClickedHandler = (e, value) => {
    const currentEvents = [...selectedPerfEvents];
    const updatedEvents = currentEvents.filter( (eventItem) => eventItem && eventItem.value !== value );

    let paramObj = {
      ...queryParamsRef.current,
    };

    if (updatedEvents && Array.isArray(updatedEvents) && updatedEvents.length === 0) {
      delete paramObj.eventIds;
    }
    else {
      paramObj['eventIds'] = updatedEvents.map((item) => item.value)
    }
    
    updateQueryParam(paramObj);

    setSelectedPerfEvents(updatedEvents);
  }

  const showSelectPlayerHandler = () => {
    setShortlistedPlayers();
    setShowSelectPlayer(!showSelectPlayer);
  }

  const showSelectEventHandler = () => {
    setShortlistedEvents();
    setShowSelectEvent(!showSelectEvent);
  }

  const hasFullPermission = () => {
    let permittedRoles = [ADMIN_ROLE, COACH_ROLE];
    console.log('roles', authenticatedUser?.userMe?.roles);
    return permittedRoles.some((item) => authenticatedUser?.userMe?.roles?.includes(item));
  }

  const isRolePlayer = () => {
    return authenticatedUser?.userMe?.roles?.includes(PLAYER_ROLE);
  }

  const getPlayerProfile = () => {
    if (isRolePlayer) {
      return authenticatedUser?.userMe?.profile;
    }
    return '';
  }

  if(isRolePlayer()) {
    playerProfile = getPlayerProfile();
  }

  if (error) {
    console.log('fetch error')
  }
  if (isLoading) return <Loader show={isLoading} message="Loading..." />;

  return (
    <div>
      <StandardHeader />
      <Heading as='h4' size='md' textAlign='center' marginTop={5}>Player Competition</Heading>
      

      <Grid
        spacing="10px"
        p={6}
        templateColumns={{base: '1fr', sm: 'repeat(4, 1fr)', md: 'repeat(6, 1fr)', lg: 'repeat(10, 1fr)' }}
      >
        {
          !hasFullPermission()
          ?
            isRolePlayer()
            ?
              <GridItem colSpan={4} marginBottom={{base: '25px'}}>
                <Box>
                  <FormLabel>Selected Player</FormLabel>
                  <AquaTag 
                    key={playerProfile.profileName}
                    label={initCaps(playerProfile.profileName)}
                    value={playerProfile.id}
                    clickedHandler={() => {}}
                  />
                </Box>
              </GridItem>
            :
              <GridItem colSpan={4} marginBottom={{base: '25px'}}>
                <Box>
                  <FormLabel>Selected Players</FormLabel>
                    {
                      selectedPlayers && selectedPlayers.length > 0 && selectedPlayers.map( (player) => {
                        return  <AquaTag 
                                  key={player.value}
                                  label={player.label}
                                  value={player.value}
                                  clickedHandler={playerTagClickedHandler}
                                />
                      })
                    }
                    <IconButton 
                      icon={<AddIcon />} 
                      size='sm' 
                      onClick={() => showSelectPlayerHandler()} 
                    />
                    {
                      showPlayerError &&
                      <div>
                        <Text fontSize='sm' color='red'>Player is required</Text>
                      </div>
                    }
                    {
                      showSelectPlayer &&
                      <SelectAdd 
                      itemsList={playerList} 
                        onChangedHandler={playerChangedHandler}
                        label="Player"
                        name="playerName"
                        placeholder="Select Player"
                        addClickedHandler={playerAddedHandler}
                        cancelClickedHandler={() => setShowSelectPlayer(!showSelectPlayer)} 
                      />
                    }
                </Box>
              </GridItem>
          : 
            <>
            <GridItem colSpan={2} marginBottom={{base: '25px'}}>
              <Box w='80%'>
                <FormLabel>Tournaments</FormLabel>
                <Select placeholder="Competition" 
                  value={selectedTournamentId} onChange={tournamentChangedHandler}>
                  {
                    (tournaments && tournaments.length > 0)
                    ?
                    tournaments && tournaments.map((tournament) => {
                        return <option
                                key={tournament.key}
                                value={tournament.value}
                              >
                                {tournament.label}
                              </option>
                      })
                    :
                      <option>
                        {Messages.NO_DATA_FOUND}
                      </option>
                  }
                </Select>
              </Box>
            </GridItem>
            <GridItem colSpan={2} marginBottom={{base: '25px'}}>
                <Box w='80%'>
                    <FormLabel>Competition By</FormLabel>
                    <RadioGroup onChange={competitionTypeChangedHandler} value={selectedCompetitionType}>
                        <Stack direction='row'>
                            <Radio value='O'>Age Group</Radio>
                            <Radio value='P'>Player</Radio>
                        </Stack>
                    </RadioGroup>
                </Box>
            </GridItem>
            {
              (selectedCompetitionType === DEFAULT_COMPETITION_TYPE)
              ?
                <>
                  <GridItem colSpan={2} marginBottom={{base: '25px'}}>
                      <Box w='80%'>
                          <FormLabel>Age Group</FormLabel>
                          <Select value={selectedAgeGroup} onChange={ageGroupChangedHandler}>
                              {
                                  (ageGroups && ageGroups.length > 0)
                                  ?
                                  ageGroups && ageGroups.map((ageGroup) => {
                                      return <option
                                              key={ageGroup.ageGroup}
                                              value={ageGroup.ageGroup}
                                          >
                                              {ageGroup.ageGroup}
                                          </option>
                                  })
                                  :
                                  <option>
                                      {Messages.NO_DATA_FOUND}
                                  </option>
                              }
                          </Select>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={2} marginBottom={{base: '25px'}}>
                      <Box w='80%'>
                          <FormLabel>Gender</FormLabel>
                          <RadioGroup onChange={genderChangedHandler} value={selectedGender}>
                              <Stack direction='row'>
                                  <Radio value='M'>Male</Radio>
                                  <Radio value='F'>Female</Radio>
                              </Stack>
                          </RadioGroup>
                      </Box>
                  </GridItem>
                </>
              :
                <GridItem colSpan={4} marginBottom={{base: '25px'}}>
                  <Box>
                    <FormLabel>Selected Players</FormLabel>
                      {
                        selectedPlayers && selectedPlayers.length > 0 && selectedPlayers.map( (player) => {
                          return  <AquaTag 
                                    key={player.value}
                                    label={player.label}
                                    value={player.value}
                                    clickedHandler={playerTagClickedHandler}
                                  />
                        })
                      }
                      <IconButton 
                        icon={<AddIcon />} 
                        size='sm' 
                        onClick={() => showSelectPlayerHandler()} 
                      />
                      {
                        showPlayerError &&
                        <div>
                          <Text fontSize='sm' color='red'>Player is required</Text>
                        </div>
                      }
                      {
                        showSelectPlayer &&
                        <SelectAdd 
                        itemsList={playerList} 
                          onChangedHandler={playerChangedHandler}
                          label="Player"
                          name="playerName"
                          placeholder="Select Player"
                          addClickedHandler={playerAddedHandler}
                          cancelClickedHandler={() => setShowSelectPlayer(!showSelectPlayer)} 
                        />
                      }
                  </Box>
                </GridItem>
            }
            </>
        }
        <GridItem colSpan={3} marginBottom={{base: '25px'}}>
        <Box>
            <FormLabel>Selected Events</FormLabel>
              {
                selectedPerfEvents && 
                selectedPerfEvents.length > 0 && 
                selectedPerfEvents.map( (eventItem) => {
                  return  <AquaTag 
                            key={eventItem.value}
                            label={eventItem.label}
                            value={eventItem.value}
                            clickedHandler={eventTagClickedHandler}
                          />
                })
              }
              <IconButton 
                icon={<AddIcon />} 
                size='sm' 
                onClick={() => showSelectEventHandler()} 
              />
              {
                showSelectEvent &&
                <SelectAdd 
                  itemsList={eventsList} 
                  onChangedHandler={eventChangedHandler}
                  label="Event"
                  name="eventName"
                  placeholder="Select Event"
                  addClickedHandler={eventAddedHandler}
                  cancelClickedHandler={() => setShowSelectEvent(!showSelectEvent)} 
                />
              }
          </Box>
        </GridItem>

        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box w='80%'>
            <FormLabel>Competition Level</FormLabel>
            <Select placeholder="Choose Level" 
              value={selectedBenchmarkLevel} onChange={benchmarkLevelChangedHandler}>
              {
                (benchmarkLevels && benchmarkLevels.length > 0)
                ?
                benchmarkLevels && benchmarkLevels.map((benchmarkLevel) => {
                    return <option
                            key={benchmarkLevel.key}
                            value={benchmarkLevel.key}
                          >
                            {benchmarkLevel.value}
                          </option>
                  })
                :
                  <option>
                    {Messages.NO_DATA_FOUND}
                  </option>
              }
            </Select>
          </Box>
        </GridItem>
        {
        (selectedBenchmarkLevel && selectedBenchmarkLevel === 'STATE') && (
        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box w='80%'>
            <FormLabel>Select State</FormLabel>
            <Select placeholder="All" 
              value={selectedState} onChange={StateChangedHandler}>
              {
                (statesList && statesList.length > 0)
                ?
                statesList && statesList.map((state) => {
                    return <option
                            key={state.label}
                            value={state.value}
                          >
                            {state.label}
                          </option>
                  })
                :
                  <option>
                    {Messages.NO_DATA_FOUND}
                  </option>
              }
            </Select>
          </Box>
        </GridItem>)
        }
        
        <GridItem colSpan={1} style={{alignSelf: 'center'}}>
          <Button onClick={searchClickedHandler}>Search</Button>
        </GridItem>
      </Grid>
      {playerCompetitionData && 
          <AquaTab tabData={playerCompetitionData} displayType={selectedPlayers?.length > 0? 'competitionPlayer' : 'competition'}></AquaTab>
      }

      {error && (
        <Box width="100%" p={5} h="70vh">
          <Center>
            <div>Unable to load Data. Try again</div>
          </Center>
        </Box>
      )}
      
      {
        !playerCompetitionData && (
          <Box width="100%" p={5} h="70vh">
            <Center>
              <div>Select Filters and Press Search</div>
            </Center>
          </Box>  
        )
      }

      <Footer />
    </div>
  );
};
