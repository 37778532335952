import { createContext, useContext, useReducer } from 'react';

const AppContext = createContext(null);
const AppDispatchContext = createContext(null);

export { AppContext, AppDispatchContext }

// export function useClient() {
//     return useContext(AppContext);
// }

// export function useClientDispatch() {
//     return useContext(AppDispatchContext);
// }

// const appReducer = (state, action) => {
//     switch (action.type) {
//         case 'UPDATE': {
//             return {
//                 ...state,
//                 client: action.payload
//             }
//         }
//         default: {
//             throw Error('Invalid action ' + action.type);
//         }
//     }
// }

// export function ClientProvider({ children, initialState }) {
//     const [state, dispatch] = useReducer(appReducer, initialState);

//     return (
//         <AppContext.Provider value={state}>
//             <AppDispatchContext.Provider value={dispatch}>
//                 {children}
//             </AppDispatchContext.Provider>
//         </AppContext.Provider>
//     )
// }
