import { Navigate } from 'react-router-dom';
import { AUTH_TOKEN_NAME, AUTH_USER_ME } from 'utils/constants';

function isAuthenticated() {
    console.log('localStorage.getItem(AUTH_TOKEN_NAME)', localStorage.getItem(AUTH_TOKEN_NAME));
    console.log('inside isAuthenticated');
    
    // if (!localStorage.getItem(AUTH_TOKEN_NAME)) return false;
    // return true;
    return false;
}

function hasPermissions(roles = []) {
    const userObject = localStorage.getItem(AUTH_USER_ME);
    const userMe = userObject ? window.atob(userObject) : '';
    
    return roles.some((item) => userMe?.roles.includes(item));

}

function navigateTo(route) {
    console.log('route', route);
    return <Navigate to={route} replace={true}/>;
}

function getValueFromObject(jsonObject, inputKey) {
    for(let key in jsonObject){
        if (inputKey === jsonObject[key].key) {
            return jsonObject[key].key;
        }
            
    }
}

function allAreNull(arr) {
    return arr && Array.isArray(arr) && arr.every(element => element === null);
}

function isArrayEmpty(inputArr) {
    return !Array.isArray(inputArr) || inputArr.length < 1 || allAreNull(inputArr);
}

function getDataLabelValue (context) {
    const datasetLength = context.dataset.data.length;
    if (context.dataIndex === datasetLength - 1){
      return context.dataset.data[context.dataIndex]
    }
    else
      return null;
}

function epoch_to_mm_ss(epoch) {
    return new Date(epoch*1000).toISOString().substr(14, 5)
}

function epoch_to_mm_ss_sss(epoch) {
  return new Date(epoch*1000).toISOString().substr(14, 9)
}

function getMillisecondsFromTime(timeValue) {
    if (!timeValue) return 0;

    let splitArr = '';


    // check if milliseconds exists
    if (timeValue.includes('.')) {
        splitArr = timeValue.split('.');
        if (splitArr.isArrayEmpty) return '';

        // get milliseconds
        return splitArr[splitArr.length - 1];
    }
    else if (!timeValue.includes(':')) {
        return timeValue;
    }
    else 
        return 0;
}

function getSecondsFromTime(timeValue) {
    if (!timeValue) return '';

    let splitArr = '';

    // check if milliseconds exists
    // if (timeValue.includes('.')) {
    //     splitArr = timeValue.split('.');
        
    //     // get seconds
    //     if (splitArr[0].includes(":")) {
    //         splitArr = splitArr[0].split(":");
    //         return splitArr[1];
    //     }
    //     else 
    //         return splitArr[0];
    // }
    // else if (timeValue.includes(":")){
    //     splitArr = timeValue.split(':');
    //     return splitArr.length > 0 && splitArr[1];
    // }
    // else {
    //     return 0;
    // }

    if (timeValue.includes(":")){
        splitArr = timeValue.split(':');
        return splitArr.length > 0 && splitArr[1];
    }
    else {
        return timeValue;
    }
}

function getMinutesFromTime(timeValue) {
    if (!timeValue) return '';

    if (!timeValue.toString().includes(":")) return 0;

    let splitArr = timeValue.split(":");

    return splitArr.length > 0 ? splitArr[0] : 0;
}

function convertToMilliseconds(timeValue) {
    // @input = timeValue could be in MM:SS.MS or SS.MS or SS
    // @output = SS (in milliseconds)

    if (!timeValue) return 0;

    let mm = getMinutesFromTime(timeValue);
    let ss = getSecondsFromTime(timeValue);
    let ms = getMillisecondsFromTime(timeValue);

    // console.log('timeValue, mm, ss, ms', timeValue, ",", mm, ",", ss, ",", ms);
    // return parseInt((mm * 60 * 1000)) + parseInt((ss * 1000)) + parseInt(ms);
    return parseInt((mm * 60 * 1000)) + parseInt((ss * 1000));
}

// function getRankDiff1(value1, value2) {

//     if (!value1 || !value2) {
//         return {}
//     }
    
//     console.log('value1', value1);
//     console.log('value2', value2);

//     console.log('convertToMilliseconds(value1)', convertToMilliseconds(value1));
//     console.log('convertToMilliseconds(value2)', convertToMilliseconds(value2));

    
//     const diffInMillis = (convertToMilliseconds(value1) - convertToMilliseconds(value2) );
//     const minutes = (diffInMillis/60000) >= 1 ? (diffInMillis/60000) : 0;
//     const seconds = (diffInMillis%60000) / 1000;

    
//     let comparison = '=';
//     let rankDiff = 0;
        

//     if (seconds < 0) {
//         comparison = '-';
//         rankDiff = Math.abs(seconds);
//     }
//     else if (seconds > 0) {
//         comparison = '+';
//         rankDiff = seconds;
//     } 
//     else {
//         comparison = '=';
//         rankDiff = 0;
//     }

//     return {
//         comparison,
//         rankDiff
//     }
// }

function getRankDiff(value1, value2) {

    if (!value1 || !value2) {
        return {}
    }

    const [min1, sec1, ms1] = value1.split(':').map(parseFloat);
    const [min2, sec2, ms2] = value2.split(':').map(parseFloat);

    const value1InMs = min1 * 60000 + sec1 * 1000 + (ms1 ? ms1 : 0);
    const value2InMs = min2 * 60000 + sec2 * 1000 + (ms2 ? ms2 : 0);

    const diffInMillis = value1InMs - value2InMs;

    let seconds = 0;
    let minutes = 0;
    let remainingSeconds = 0;
    let milliseconds = 0;

    let formattedMinutes = '';
    let formattedSeconds = '';
    let formattedTimeDifference = '';

    let comparison = '=';
    let rankDiff = 0;

    // Minutes exists
    if (min1 > 0 && min2 > 0) {
        seconds = Math.floor(Math.abs(diffInMillis) / 1000);

        minutes = Math.floor(Math.abs(diffInMillis) / (60 * 1000));

        remainingSeconds = seconds % 60;

        milliseconds = (Math.abs(diffInMillis) % 1000).toString().padStart(3, '0');

        formattedMinutes = minutes.toString();
        formattedSeconds = remainingSeconds.toString().padStart(2, '0');
        formattedTimeDifference = `${formattedMinutes}:${formattedSeconds}.${milliseconds}`;
    }
    else {
        seconds = Math.floor(Math.abs(diffInMillis) / 1000);

        remainingSeconds = seconds % 60;

        milliseconds = (Math.abs(diffInMillis) % 1000).toString().padStart(3, '0');

        formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        formattedTimeDifference = `0:${formattedSeconds}.${Math.round(milliseconds)}`;
    }

    if (value1InMs < value2InMs) {
        comparison = '-';
        rankDiff = formattedTimeDifference;
    }
    else if (value1InMs > value2InMs) {
        comparison = '+';
        rankDiff = formattedTimeDifference;
    }
    else {
        comparison = '=';
        rankDiff = 0;
    }
    
   
    return {
        comparison,
        rankDiff
    }
}

function isObjectEmpty(Obj) {
    return Obj && 
            Object.keys(Obj).length === 0 && 
            Object.getPrototypeOf(Obj) === Object.prototype;
}

function getAgeGroupFromAge(groupDetails, age) {
    let groups = [];
    
    if (!groupDetails) return;

    for (let itr = 0; itr < groupDetails.length; itr++){    
        let groupDataItem = {
            "group": groupDetails[itr].group,
            "ageGroup": groupDetails[itr].ageGroup,
            "minAge": groupDetails[itr].minAge,
            "maxAge": groupDetails[itr].maxAge,
        };
        groups.push(groupDataItem);
    };

    let ageGroup =  groups.filter( (grp) => age >= grp.minAge && age <= grp.maxAge  );

    let ageGroupObj = Array.isArray(ageGroup) && ageGroup.length > 0 && ageGroup[0];

    return ageGroupObj;
}

function initCaps (inputStr) {
    if (!inputStr) return '';
    let tmpStr = inputStr.includes(' ') ? inputStr.split(' ') : inputStr;

    if (Array.isArray(tmpStr)) {
        return tmpStr[0]?.charAt(0).toUpperCase() + tmpStr[0]?.slice(1).toLowerCase() + ' ' +
            tmpStr[1]?.charAt(0).toUpperCase() + tmpStr[1]?.slice(1).toLowerCase(); 
    }
    else {
        return inputStr.charAt(0).toUpperCase() + inputStr.slice(1).toLowerCase();
    }
    
}

export { 
    isAuthenticated, 
    hasPermissions, 
    navigateTo, 
    getValueFromObject, 
    allAreNull, 
    isArrayEmpty, 
    getDataLabelValue,
    epoch_to_mm_ss,
    epoch_to_mm_ss_sss,
    convertToMilliseconds,
    getRankDiff,
    isObjectEmpty,
    getAgeGroupFromAge,
    initCaps
}
