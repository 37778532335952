import React from 'react';
import useSwr from 'swr';
import {
    useColorMode,
    useColorModeValue,
    Container    
  } from '@chakra-ui/react';
  

import { useClient } from 'hooks/useClient';
import fetcher from 'utils/fetcher';
import { capsuleQuery as query } from './queries';
import { StandardHeader } from 'components/Header'
import Footer from 'components/Footer';

export const Home = (props) => {
    const { toggleColorMode } = useColorMode();
    const bgColor = useColorModeValue("gray.50", "whiteAlpha.50");

    // const client = useClient();

    // const { data, error} = useSwr([query, client], fetcher);

    // if (!data) return <p>Loading...</p>

    return (
        <div>
            <StandardHeader />
            <Container maxW="container.2xl" h="100vh" p={0}>
                <span>Home</span>
            </Container>
            <Footer />
        </div>
    )
}
