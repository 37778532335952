import {
    TagLabel,
    TagCloseButton,
    Tag,
  } from "@chakra-ui/react";
  

export default function AquaTag({label, value, clickedHandler}) {
    return (
        <Tag  size='md' style={{margin: 5}} key={value} onClick={(e) => clickedHandler(e, value)}>
            <TagLabel>{label}</TagLabel>
            <TagCloseButton key={value} />
        </Tag>
    );
}

