import { Grid, GridItem } from '@chakra-ui/react'

import SignInForm from 'components/SignIn'
import { BasicHeader } from 'components/Header'
import Footer from 'components/Footer'

// export default function SignIn() {
//     return (
//         <>
//             <BasicNavigationBar />
//             <Container maxW="container.2xl" p={0}>
//                 <Flex h={{ base: '100vh', md: '100vh' }} py={2}>
//                     <SignInForm />
//                 </Flex>
//             </Container>
//         </>
        
//     )
// }

export default function SignIn() {
    return (
        <>
            <BasicHeader />
            <SignInForm />
            <Footer />
        </>
    )
}
