import React from 'react';
import { Spinner } from '@chakra-ui/react';
import { Stack, Heading, Flex } from '@chakra-ui/react'
import { BasicHeader } from "components/Header";

const Loader = (props) => (
    props.show ?
    
    <Stack h="calc(100vh - 64px)" direction={{ base: 'column', md: 'column' }}>
        <BasicHeader />
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
            {/* <Spinner />
            <Heading p={10} fontSize={'2xl'}>{props.message}</Heading> */}
            <img src="https://www.animatedimages.org/data/media/623/animated-swimming-image-0010.gif"/>
            
        </Flex>
    </Stack>
    : null
)

export default Loader;