export const appReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE': {
            return {
                ...state,
                client: action.payload
            }
        }
        default: {
            throw Error('Invalid action ' + action.type);
        }
    }
}