import React, { useState, useEffect, useRef } from "react";
import useSwr from "swr";
import {
  Box,
  FormLabel,
  Grid,
  GridItem,
  Center,
  IconButton,
  Heading,
  StatArrow,
  Stat,
  StatNumber,
  StatHelpText,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Link
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { useEventsState } from 'hooks/useEvents';
import './BenchmarkStyles.css';

import { Select } from "@chakra-ui/react";

import { useClient } from "hooks/useClient";
import fetcher from "utils/fetcher";
import { useNavigate } from 'react-router-dom';

import {
  GET_ALL_EVENTS as getAllEvents,
  GET_BENCHMARKS as getPerformanceBenchmarks,
  GET_ALL_GROUPS as getAllGroups,
} from "./queries";

import { StandardHeader } from "components/Header";
import Footer from "components/Footer";
import Loader from "components/Loader";

import SelectAdd from "components/UI/SelectAdd";
import AquaTag from "components/UI/AquaTag";
import { useToast } from '@chakra-ui/react';

import { isAuthenticated, isArrayEmpty } from "utils/helpers";
import { buildEventsArray, buildBenchmarkData, buildAgeGroupsArray } from 'pages/Player/helpers';

import {
  EVENTS_LIMIT,
  DEFAULT_BENCHMARK_LEVEL,
  AUTH_REDIRECT_ROUTE
} from "utils/constants";

import * as Messages from "utils/messages";

// import ageGroups from 'mocks/ageGroups.json';
import genders from 'mocks/genders.json';
import benchmarks from 'mocks/benchmarks.json';

import { useMediaQuery } from '@chakra-ui/react';
import { capitalize } from "lodash";

export const Benchmark = (props) => {

  const DEFAULT_EVENT = [{
    label: "50m Butterfly",
    value: "39A4BF2F90A14F71BFE5ACD24D9985EA",
  }];

    
  const [shouldFetch, setShouldFetch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const {events, setEvents} = useEventsState();

  const [selectedPerfEvents, setSelectedPerfEvents] = useState(
                                events && !isArrayEmpty(events)
                                ? events
                                : []
                              );
  const [selectedGender, setSelectedGender] = useState();
  const [selectedAgeGroup, setSelectedAgeGroup] = useState();
  const [selectedBenchmarkLevel, setSelectedBenchmarkLevel] = useState(DEFAULT_BENCHMARK_LEVEL);

  const [ eventsList, setEventsList] = useState();

  const [ gendersList, setGendersList] = useState();
  const [ ageGroupsList, setAgeGroupsList] = useState();
  const [ benchmarkLevelList, setBenchmarkLevelList] = useState();

  const [shortlistedEvents, setShortlistedEvents] = useState();
  
  const [benchmarkData, setBenchmarkData] = useState();
  
  const [showSelectEvent, setShowSelectEvent] = useState(false);

  const navigate = useNavigate();
  
  const queryParamsRef = useRef({
    eventIds: selectedPerfEvents.map(item => item.value),
    benchmarkLevel: selectedBenchmarkLevel
  });

  const [isLargerThan1280, isDisplayingInBrowser] = useMediaQuery([
    '(min-width: 992px)',
    '(display-mode: browser)',
  ]);

  const eventChangedHandler = (inputValue) => {
    if (!inputValue) return;

    const shortlistedEventsArr = [];

    inputValue.map((event) => {
      shortlistedEventsArr.push({'label' : event.label, 'value': event.value});
    });

    let currentShortListedEvents = shortlistedEvents;

    if (typeof currentShortListedEvents !== 'undefined' && !currentShortListedEvents) 
      currentShortListedEvents.push(...shortlistedEventsArr);
    else  
      currentShortListedEvents = shortlistedEventsArr;
    
    setShortlistedEvents(currentShortListedEvents);
  };

  const eventAddedHandler = () => {

    // Check if any value is selected
    if (!shortlistedEvents || (Array.isArray(shortlistedEvents) && shortlistedEvents.length === 0 )) {
      toast({
        title: 'Choose an Event',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    let currentSelectedEvents = [...selectedPerfEvents];

    // Check for duplicates
    const duplicateEvents = selectedPerfEvents.filter(itr => shortlistedEvents.some(se => se.value  === itr.value));

    if (duplicateEvents && Array.isArray(duplicateEvents) && duplicateEvents.length > 0) {
      toast({
        title: 'Event Already added',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    // Maximum 5 Events are allowed
    if (selectedPerfEvents.length + shortlistedEvents.length > EVENTS_LIMIT) {
      toast({
        title: `Max ${EVENTS_LIMIT} events can be added`,
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    if (Array.isArray(currentSelectedEvents) && currentSelectedEvents.length > 0) 
      currentSelectedEvents.push(...shortlistedEvents);
    else  
      currentSelectedEvents = shortlistedEvents;

   
    let paramObj = {
      ...queryParamsRef.current,
      eventIds: currentSelectedEvents.map((item) => item.value)
    };

    updateQueryParam(paramObj);

    setSelectedPerfEvents(currentSelectedEvents);
    setShowSelectEvent(false);
    
  }

  const genderChangedHandler = (e) => {
    e.preventDefault();

    let paramObj = {
      ...queryParamsRef.current
    }

    if (e.currentTarget.value) {
      paramObj['gender'] = e.currentTarget.value
    }
    else {
      delete paramObj.gender;
    }

    updateQueryParam(paramObj);

    setSelectedGender(e.currentTarget.value);
  }

  const ageGroupChangedHandler = (e) => {
    e.preventDefault();

    let paramObj = {
      ...queryParamsRef.current
    }

    if (e.currentTarget.value) {
      paramObj['ageGroup'] = e.currentTarget.value
    }
    else {
      delete paramObj.ageGroup;
    }

    updateQueryParam(paramObj);

    setSelectedAgeGroup(e.currentTarget.value);
  }

  const benchmarkLevelChangedHandler = (e) => {
    e.preventDefault();

    let paramObj = {
      ...queryParamsRef.current
    }

    if (e.currentTarget.value) {
      paramObj['benchmarkLevel'] = e.currentTarget.value
    }
    else {
      delete paramObj.benchmarkLevel;
    }

    updateQueryParam(paramObj);

    setSelectedBenchmarkLevel(e.currentTarget.value);
  }

  const client = useClient();
  const toast = useToast();

  const updateQueryParam = (paramObj) => {
    queryParamsRef.current = paramObj;
  };

  const getEvents = async () => {
    try {
        const eventsResData = await fetcher(getAllEvents, client);
        const eventsArr = buildEventsArray(eventsResData?.getAllEvents?.events);
        setEventsList(eventsArr);
    }
    catch(error) {
      console.log('error', error);
      setEventsList([]);
    }
  }

  const getGenders = () => {
    setGendersList(genders);
  }

  const getAgeGroups = async () => {
    // setAgeGroupsList(ageGroups);
    try {
      const groupsResData = await fetcher(getAllGroups, client);
      const ageGroupsArr = buildAgeGroupsArray(groupsResData?.getAllGroups?.groups);
      setAgeGroupsList(ageGroupsArr);
    }
    catch(error) {
      console.log('error', error);
      setAgeGroupsList([]);
    }
  }

  const getBencharmarkLevels = () => {
    setBenchmarkLevelList(benchmarks);
  }

  const { data: benchmarkApiData, error } = useSwr(
    shouldFetch
      ? [getPerformanceBenchmarks, client, queryParamsRef.current]
      : null,
    fetcher,
    {
      loadingTimeout: 800,
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  useEffect(() => {
    
    getEvents();
    getGenders();
    getAgeGroups();
    getBencharmarkLevels();

    // setShouldFetch(true);
    setIsLoading(true);
    events && setSelectedPerfEvents(events);
  }, []);

  useEffect(() => {

    // if (
    //   isArrayEmpty(selectedPerfEvents) &&
    //   !selectedGender &&
    //   !selectedAgeGroup &&
    //   !selectedBenchmarkLevel
    // ) {
    //   setBenchmarkData("");
    //   return;
    // }

    // if (!isAuthenticated()) navigate(AUTH_REDIRECT_ROUTE)

    setIsLoading(true);
    setShouldFetch(true);
    setBenchmarkData("");
  }, [
    selectedPerfEvents,
    selectedGender,
    selectedAgeGroup,
    selectedBenchmarkLevel
  ]);

  useEffect(() => {

    // set should fetch to false
    setShouldFetch(false);
    
    const benchmarkDataRes =
      benchmarkApiData?.getPerformanceBenchmarks;

    // If benchmark is null then show no data found
    if (!benchmarkDataRes) {
      setIsLoading(false);
      return;
    }

    const benchmarkObj = buildBenchmarkData(benchmarkDataRes);
    setBenchmarkData(benchmarkObj);

    console.log('benchmarkObj', benchmarkObj);
    
    setIsLoading(false);
  }, [benchmarkApiData]);


  const eventTagClickedHandler = (e, value) => {
    const currentEvents = [...selectedPerfEvents];
    const updatedEvents = currentEvents.filter( (eventItem) => eventItem && eventItem.value !== value );

    let paramObj = {
      ...queryParamsRef.current,
      eventIds: updatedEvents.map((item) => item.value)
    };
    
    updateQueryParam(paramObj);

    setSelectedPerfEvents(updatedEvents);
  }

  const showSelectEventHandler = () => {
    setShortlistedEvents();
    setShowSelectEvent(!showSelectEvent);
  }

  const gridStyling = {
      border: '1px',
      borderColor: 'lightgray',
  };

  const gridHeaderStyling = {
    border: '1px',
    borderColor: 'lightgray',
    background: 'black',
    minHeight: '50px'
  };

  const columnStyling = {
    color: 'white',
    textTransform: 'capitalize'
  }

  const borderColumnRightStyling = {
      borderRight: {base: '0px', sm: '1px', md: '1px', lg: '1px'},
      borderColor: 'lightgray'
  };

  const gridBorderColor = {
      borderColor: 'lightgray'
  };

  const vhAlign = {
    // textAlign: {base: "left", md: "center"},
    // alignSelf: "center",
    color: 'white',
    textTransform: 'capitalize',
    fontSize: 'medium'
};

  const vAlign = {
      alignSelf: "center"
  };

  

  if (isLoading) return <Loader show={isLoading} message="Loading..." />;

  return (
    <div>
      <StandardHeader />
      <Heading as='h4' size='md' textAlign='center' marginTop={5}>Benchmarks</Heading>
      
      <Grid
        spacing="10px"
        p={4}
        templateColumns={{base: '1fr', sm: 'repeat(4, 1fr)', md: 'repeat(6, 1fr)', lg: 'repeat(8, 1fr)' }}
      >
        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box>
            <FormLabel>Selected Events</FormLabel>
              {
                selectedPerfEvents && 
                selectedPerfEvents.length > 0 && 
                selectedPerfEvents.map( (eventItem) => {
                  return  <AquaTag 
                            key={eventItem.value}
                            label={eventItem.label}
                            value={eventItem.value}
                            clickedHandler={eventTagClickedHandler}
                          />
                })
              }
              <IconButton 
                icon={<AddIcon />} 
                size='sm' 
                onClick={() => showSelectEventHandler()} 
              />
              {
                showSelectEvent &&
                <SelectAdd 
                  itemsList={eventsList} 
                  onChangedHandler={eventChangedHandler}
                  label="Event"
                  name="eventName"
                  placeholder="Select Event"
                  addClickedHandler={eventAddedHandler}
                  cancelClickedHandler={() => setShowSelectEvent(!showSelectEvent)} 
                />
              }
          </Box>
        </GridItem>
        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box w='80%'>
            <FormLabel>Gender</FormLabel>
            <Select placeholder="Choose Gender" value={selectedGender} onChange={genderChangedHandler}>
              {
                (gendersList && gendersList.length > 0)
                ?
                gendersList && gendersList.map((gender) => {
                    return <option
                            key={gender.key}
                            value={gender.key}
                          >
                            {gender.value}
                          </option>
                  })
                :
                  <option>
                    {Messages.NO_DATA_FOUND}
                  </option>
              }
            </Select>
          </Box>
        </GridItem>
        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box w='80%'>
            <FormLabel>Age Group</FormLabel>
            <Select placeholder="Choose Age Group" value={selectedAgeGroup} onChange={ageGroupChangedHandler}>
              {
                (ageGroupsList && ageGroupsList.length > 0)
                ?
                ageGroupsList && ageGroupsList.map((ageGroup) => {
                    return <option
                            key={ageGroup.key}
                            value={ageGroup.key}
                          >
                            {ageGroup.value}
                          </option>
                  })
                :
                  <option>
                    {Messages.NO_DATA_FOUND}
                  </option>
              }
            </Select>
          </Box>
        </GridItem>
        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box w='80%'>
            <FormLabel>Benchmark</FormLabel>
            <Select value={selectedBenchmarkLevel} onChange={benchmarkLevelChangedHandler}>
              {
                (benchmarkLevelList && benchmarkLevelList.length > 0)
                ?
                benchmarkLevelList && benchmarkLevelList.map((item) => {
                    return <option
                            key={item.key}
                            value={item.key}
                          >
                            {item.value}
                          </option>
                  })
                :
                  <option>
                    {Messages.NO_DATA_FOUND}
                  </option>
              }
            </Select>
          </Box>
        </GridItem>
        
      </Grid>
      
      {/* Display Benchmark Table */}

      {
        benchmarkData && 
        <Box p={3} minHeight="70vh">
          <TableContainer>
            {selectedBenchmarkLevel === DEFAULT_BENCHMARK_LEVEL && 
              <Box textAlign="right"> <Link href="#nmr-table"> View National Records</Link> </Box>
            }
            <Table size="sm" variant='simple' {...gridStyling} className="custom-table">
                <Thead {...gridHeaderStyling} >
                  <Tr>
                    <Th {...vhAlign}>Gender, Event</Th>
                    <Th {...vhAlign}>Age Group</Th>
                    <Th {...vhAlign}>NMR</Th>
                    <Th {...vhAlign}>Gold</Th>
                    <Th {...vhAlign}>Silver</Th>
                    <Th {...vhAlign}>Bronze</Th>
                    <Th {...vhAlign}>CutOff Start</Th>
                    <Th {...vhAlign}>CutOff End</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {
                  benchmarkData && 
                  benchmarkData.map((item, idx) => {
                      return <Tr id={item.event.name + idx}>
                                <Td>{item.gender + ', ' + item.event.name}</Td>
                                <Td>{item.ageGroup}</Td>
                                <Td>{item?.nmr?.nmr ? item.nmr.nmr : "-"}</Td>
                                {
                                    item.ranks &&
                                    item.ranks.length > 0
                                    ?
                                        item.ranks.map((rank, idx) => {
                                            return <Td>
                                                    {rank.time}
                                                    {/* <Stat>
                                                        <StatNumber fontSize='sm'>{rank.time}</StatNumber>
                                                        <StatHelpText>
                                                            {rank.name}
                                                        </StatHelpText>
                                                    </Stat> */}
                                                </Td>
                                        })
                                    : 
                                    <Td>
                                        <span>-</span>
                                    </Td>
                                }

                                {
                                    (item.cutoffStart || item.cutoffEnd)
                                    ?
                                    <>
                                        <Td>
                                            <Box>
                                              {item.cutoffStart && item.cutoffStart.time ? item.cutoffStart.time : '-'}
                                                {/* <Stat>
                                                    <StatNumber fontSize='md'>{item.cutoffStart && item.cutoffStart.time ? item.cutoffStart.time : '-'}</StatNumber>
                                                </Stat> */}
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Box>
                                              {item.cutoffEnd && item.cutoffEnd.time ? item.cutoffEnd.time : '-'}
                                                {/* <Stat>
                                                    <StatNumber fontSize='md'>{item.cutoffEnd && item.cutoffEnd.time ? item.cutoffEnd.time : '-'}</StatNumber>
                                                </Stat> */}
                                            </Box>
                                        </Td>
                                    </>
                                    :
                                    <>
                                        <Td>
                                            <span>-</span>
                                        </Td>
                                        <Td>
                                            <span>-</span>
                                        </Td>
                                    </>
                                }
                      </Tr>
                  })
                }
                </Tbody>
            </Table>
          </TableContainer>
        </Box>
      }

      {/* NMR Table only for National */}

      {
        selectedBenchmarkLevel === DEFAULT_BENCHMARK_LEVEL &&  benchmarkData && 
        <>
          <Box paddingTop={3} paddingLeft={3} >
            <Heading size='md' id="nmr-table">National Records</Heading>
          </Box>
          <Box paddingTop={1} paddingLeft={3} paddingRight={3} paddingBottom={3} minHeight="70vh">
            <TableContainer>
              <Table size="sm" variant='simple' {...gridStyling} className="custom-table">
                  <Thead {...gridHeaderStyling} >
                    <Tr>
                      <Th {...vhAlign}>Gender, Event</Th>
                      <Th {...vhAlign}>Age Group</Th>
                      <Th {...vhAlign}>NMR</Th>
                      <Th {...vhAlign}>Player</Th>
                      <Th {...vhAlign}>State</Th>
                      <Th {...vhAlign}>Recorded Year</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  {
                    benchmarkData && 
                    benchmarkData.map((item, idx) => {
                        return <Tr id={item.event.name + idx}>
                                  <Td>{item.gender + ', ' + item.event.name}</Td>
                                  <Td>{item.ageGroup}</Td>
                                  <Td>{item?.nmr?.nmr ? item.nmr.nmr : "-"}</Td>
                                  <Td>{item?.nmr?.name ? item?.nmr?.name : "-"}</Td>
                                  <Td>{item?.nmr?.state ? item?.nmr?.state : "-"}</Td>
                                  <Td>{item?.nmr?.recordedYear ? item?.nmr?.recordedYear : "-"}</Td>
                        </Tr>
                    })
                  }
                  </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </>
      }

      {
        error && <Box width="100%" p={5} h="70vh">
          <Center>
            <div>Unable to load Chart Data. Try again</div>
          </Center>
        </Box>
      }
      {
          (!benchmarkData || benchmarkData === "undefined" || isArrayEmpty(benchmarkData)) 
          ? <Box width="100%" p={5} h="70vh">
            <Center>
              <div>Data not found for this selection</div>
            </Center>
          </Box>
        : ""
      }
      <Footer />
    </div>
  );
};
