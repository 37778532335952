import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link, Outlet } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import  { SWRConfig } from 'swr';

import { useGraphQLClient }  from 'hooks/useGraphQLClient';
import { AppContextProvider } from 'providers/AppContextProvider';
import { GlobalStateProvider } from 'providers/GlobalStateProvider';
import AppRoute from 'routes';
import  ErrorBoundary from 'components/Error';
import theme from 'theme';
import 'theme/styles.css';

import './App.css';

function App() {
  const graphQLClient = useGraphQLClient();

  return (
    <AppContextProvider initialState={graphQLClient}>
      <GlobalStateProvider>
        <ChakraProvider theme={theme}>
          <ErrorBoundary>
            <SWRConfig value={{ shouldRetryOnError: false }}>
              <AppRoute />
            </SWRConfig>
          </ErrorBoundary>
        </ChakraProvider>
      </GlobalStateProvider>
    </AppContextProvider>
  );
}

export default App;