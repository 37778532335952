import React, { useState, useEffect, useRef } from "react";
import useSwr from "swr";
import {
  useColorMode,
  useColorModeValue,
  Box,
  FormLabel,
  SimpleGrid,
  Grid,
  GridItem,
  Center,
  Button,
  IconButton,
  HStack,
  Flex,
  Spacer,
  TagLabel,
  TagCloseButton,
  Tag,
  Heading,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import { usePlayersState } from 'hooks/usePlayers';
import { useEventsState } from 'hooks/useEvents';
import { useGlobalPerformanceTypeState } from "hooks/useGlobalPerformanceType";

import { Select } from "@chakra-ui/react";
import AsyncSelect from "react-select/async";
import { default as ReactSelect } from "react-select";

import { useClient } from "hooks/useClient";
import fetcher from "utils/fetcher";

import {
  GET_PLAYERS as getPlayerQuery,
  GET_ALL_PLAYERS as getAllPlayers,
  GET_ALL_EVENTS as getAllEvents,
  GET_PLAYER_PERFORMANCE as getPlayerPerformanceQuery,
  GET_PLAYER_PERFORMANCE_V1 as getPlayerPerformanceQueryV1,
  GET_PLAYER_PERFORMANCE_V2 as getPlayerPerformanceQueryV2
} from "./queries";

import { StandardHeader } from "components/Header";
import Footer from "components/Footer";
import Loader from "components/Loader";

import SelectAdd from "components/UI/SelectAdd";
import AquaTag from "components/UI/AquaTag";
import AquaTab from "components/UI/AquaTab";
import { useToast } from '@chakra-ui/react';

import champPerf from "mocks/championshipPerformance.json";

import {
  getPlayerPerformanceData,
  getChampPerformanceBenchmarkData,
  getNMR,
} from "utils/dataMapper";
import { isArrayEmpty, convertToMilliseconds } from "utils/helpers";
import { buildChartDataSets } from "pages/Player/helpers";
import { buildEventsArray } from 'pages/Player/helpers';
import { buildChampionshipPerformanceData } from 'pages/Player/helpers';
import { useNavigate } from 'react-router-dom';

import { getChartOptions } from "utils/charts";
import AquaChart from "components/AquaChart";

import {
  PLAYER_PERFORMANCE,
  DURATION_TITLE,
  LINE_CHART,
  PLAYER_PERFORMANCE_ROUTE,
  CHAMPIONSHIP_VALUE,
  PLAYERS_LIMIT,
  EVENTS_LIMIT
} from "utils/constants";

import * as Messages from "utils/messages";
import { isArray, repeat } from "lodash";


export const ChampionshipPerformance = (props) => {
  const { toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue("gray.50", "whiteAlpha.50");

  const DEFAULT_PERF_YEAR_VALUE = 2022;
  const DEFAULT_EVENT = [{
    label: "50m Butterfly",
    value: "39A4BF2F90A14F71BFE5ACD24D9985EA",
  }];
  const DEFAULT_BENCHMARK_TYPE = "DISTRICT";
  const DEFAULT_PLAYER = [{
    label: "Ashwath A",
    value: "8BA4EDF0AD484F99BF9C0EE098645686",
  }];

  const navigate = useNavigate();

  const { playerss, eventss, benchmark, perfYear, championship, championshipName } = props;

  let playersArr = [
    {
      label: "Ashwath A",
      value: "8BA4EDF0AD484F99BF9C0EE098645686"
    },
    {
      label: "Ashwath A-1",
      value: "9C593CDE05544FF6BF4B5C1700A0636B"
    },
  ];
    
  const [shouldFetch, setShouldFetch] = useState(false);
  const isPlayerLoading = useRef(false);

  const [isLoading, setIsLoading] = useState(false);

  const [nodata, setNoData] = useState(false);

  const {players, setPlayers} = usePlayersState();
  const {events, setEvents} = useEventsState();
  const {globalPerformanceType, setGlobalPerformanceType} = useGlobalPerformanceTypeState();

  const [selectedPerfYear, setSelectedPerfYear] = useState(
    DEFAULT_PERF_YEAR_VALUE
  );
  const [selectedPerfEvents, setSelectedPerfEvents] = useState(
                                events && !isArrayEmpty(events)
                                ? events
                                : DEFAULT_EVENT
                              );
  const [selectedPlayers, setSelectedPlayers] = useState(
                                players && !isArrayEmpty(players)
                                ? players
                                : DEFAULT_PLAYER
                              );
  const [playerList, setPlayerList] = useState([]);
  const [ eventsList, setEventsList] = useState();
  

  const [shortlistedPlayers, setShortlistedPlayers] = useState();
  const [shortlistedEvents, setShortlistedEvents] = useState();
  

  const [championshipPerformanceData, setChampionshipPerformanceData] = useState();

  const [showSelectPlayer, setShowSelectPlayer] = useState(false);
  const [showSelectEvent, setShowSelectEvent] = useState(false);
  

  const queryParamsRef = useRef({
    playerIds: selectedPlayers.map(item => item.value),
    year: DEFAULT_PERF_YEAR_VALUE,
    eventIds: selectedPerfEvents.map(item => item.value),
    benchmarkLevel: globalPerformanceType.benchmarkLevel,
    performanceLevel: globalPerformanceType.performanceLevel,
    performanceType: globalPerformanceType.performanceType
  });

  const eventChangedHandler = (inputValue) => {
    if (!inputValue) return;

    const shortlistedEventsArr = [];

    inputValue.map((event) => {
      shortlistedEventsArr.push({'label' : event.label, 'value': event.value});
    });

    let currentShortListedEvents = shortlistedEvents;

    if (typeof currentShortListedEvents !== 'undefined' && !currentShortListedEvents) 
    currentShortListedEvents.push(...shortlistedEventsArr);
    else  
    currentShortListedEvents = shortlistedEventsArr;
    setShortlistedEvents(currentShortListedEvents);
  };

  const playerChangedHandler = (inputValue) => {
    if (!inputValue) return;

    const shortlistedPlayersArr = [];

    inputValue.map((player) => {
      shortlistedPlayersArr.push({'label' : player.label, 'value': player.value});
    });

    let currentShortListedPlayers = shortlistedPlayers;

    if (typeof currentShortListedPlayers !== 'undefined' && !currentShortListedPlayers) 
      currentShortListedPlayers.push(...shortlistedPlayersArr);
    else  
      currentShortListedPlayers = shortlistedPlayersArr;
    setShortlistedPlayers(currentShortListedPlayers);
  };

  const playerAddedHandler = () => {

     // Check if any value is selected
     if (!shortlistedPlayers || (Array.isArray(shortlistedPlayers) && shortlistedPlayers.length === 0 )) {
      toast({
        title: 'Choose a Player',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    let currentSelectedPlayers = [...selectedPlayers];

    // Check for duplicates
    const duplicatePlayers = selectedPlayers.filter(itr => shortlistedPlayers.some(sp => sp.value  === itr.value));

    if (duplicatePlayers && Array.isArray(duplicatePlayers) && duplicatePlayers.length > 0) {
      toast({
        title: 'Player Already added',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    // Maximum 5 players are allowed
    if (selectedPlayers.length + shortlistedPlayers.length > PLAYERS_LIMIT) {
      toast({
        title: `Max ${PLAYERS_LIMIT} players can be added`,
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    if (Array.isArray(currentSelectedPlayers) && currentSelectedPlayers.length > 0) 
      currentSelectedPlayers.push(...shortlistedPlayers);
    else  
      currentSelectedPlayers = shortlistedPlayers;

    let paramObj = {
      ...queryParamsRef.current,
      playerIds: currentSelectedPlayers.map((item) => item.value)
    };
    
    updateQueryParam(paramObj);

    setSelectedPlayers(currentSelectedPlayers);
    setShowSelectPlayer(false);
    
  }

  const eventAddedHandler = () => {

    // Check if any value is selected
    if (!shortlistedEvents || (Array.isArray(shortlistedEvents) && shortlistedEvents.length === 0 )) {
      toast({
        title: 'Choose an Event',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    let currentSelectedEvents = [...selectedPerfEvents];

    // Check for duplicates
    const duplicateEvents = selectedPerfEvents.filter(itr => shortlistedEvents.some(se => se.value  === itr.value));

    if (duplicateEvents && Array.isArray(duplicateEvents) && duplicateEvents.length > 0) {
      toast({
        title: 'Event Already added',
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    // Maximum 5 Events are allowed
    if (selectedPerfEvents.length + shortlistedEvents.length > EVENTS_LIMIT) {
      toast({
        title: `Max ${EVENTS_LIMIT} events can be added`,
        duration: 3000,
        isClosable: true,
        status: 'error'
      })
      return;
    }

    if (Array.isArray(currentSelectedEvents) && currentSelectedEvents.length > 0) 
      currentSelectedEvents.push(...shortlistedEvents);
    else  
      currentSelectedEvents = shortlistedEvents;

   

    let paramObj = {
      ...queryParamsRef.current,
      eventIds: currentSelectedEvents.map((item) => item.value)
    };

    updateQueryParam(paramObj);

    setSelectedPerfEvents(currentSelectedEvents);
    setShowSelectEvent(false);
    

  }

  const client = useClient();
  const toast = useToast();

  const updateQueryParam = (paramObj) => {
    queryParamsRef.current = paramObj;
  };

  const buildPlayersArray = (playersObj) => {
    const playersArr = [];

    playersObj.map((playerObj) => {
      let playerItem = {};
      playerItem["label"] = playerObj.displayName;
      playerItem["value"] = playerObj.id;
      playersArr.push(playerItem);
    });

    return playersArr;
  };

  const getPlayers = async () => {
    try {
      const playerResData = await fetcher(getAllPlayers, client);
      const playersArr = buildPlayersArray(
        playerResData?.getAllPlayers?.players
      );
      setPlayerList(playersArr);
    } catch (error) {
      console.log('error', error);
      isPlayerLoading.current = false;
    }
  };

  const getEvents = async () => {
    try {
        const eventsResData = await fetcher(getAllEvents, client);
        const eventsArr = buildEventsArray(eventsResData?.getAllEvents?.events);
        setEventsList(eventsArr);
    }
    catch(error) {
      console.log('error', error);
      setEventsList([]);
    }
  }

  // const data = {};
  // const error = '';
  // const playerPerformanceData = {};

  const { data: playerPerformanceData, error } = useSwr(
    shouldFetch
      ? [getPlayerPerformanceQueryV2, client, queryParamsRef.current]
      : null,
    fetcher,
    {
      loadingTimeout: 800,
      onError: () => {
        setIsLoading(false);
      },
    }
  );

  useEffect(() => {
    
    getPlayers();
    getEvents();

    setShouldFetch(true);
    setIsLoading(true);
    setSelectedPlayers(players);
    events && setSelectedPerfEvents(events);
  }, []);

  useEffect(() => {
    
    // check if any of the input is empty. If true set noData to true
    if (
      isArrayEmpty(selectedPerfEvents) ||
      isArrayEmpty(selectedPlayers)
    ) {
      setNoData(true);
      setChampionshipPerformanceData("");
      return;
    }

    setNoData(false);
    setShouldFetch(true);
    setChampionshipPerformanceData('');

  }, [
    selectedPerfEvents,
    selectedPlayers,
  ]);

  useEffect(() => {
    if (!playerPerformanceData) return;

    // set should fetch to false
    setShouldFetch(false);

    const playerPerformanceDataRes =
      playerPerformanceData?.getPlayerPerformancesV2;

    // If player performance is null then show no data found
    if (!playerPerformanceDataRes) {
      setIsLoading(false);
      setNoData(true);
      return;
    }

    const performanceObj = buildChampionshipPerformanceData(selectedPlayers, selectedPerfEvents, playerPerformanceDataRes);

    setChampionshipPerformanceData(performanceObj);

    setIsLoading(false);
  }, [playerPerformanceData]);

  useEffect(() => {

  }, [shortlistedPlayers])

  useEffect(() => {

  }, [shortlistedEvents])

  const playerTagClickedHandler = (e, value) => {
    const currentPlayers = [...selectedPlayers];
    const updatedPlayers = currentPlayers.filter( (player) => player && player.value !== value );

    let paramObj = {
      ...queryParamsRef.current,
      playerId: updatedPlayers.map((item) => item.value)
    };
    
    updateQueryParam(paramObj);

    setSelectedPlayers([...updatedPlayers]);
  }

  const eventTagClickedHandler = (e, value) => {
    const currentEvents = [...selectedPerfEvents];
    const updatedEvents = currentEvents.filter( (eventItem) => eventItem && eventItem.value !== value );

    let paramObj = {
      ...queryParamsRef.current,
      eventId: updatedEvents.map((item) => item.value)
    };
    
    updateQueryParam(paramObj);

    setSelectedPerfEvents(updatedEvents);
  }

  const showSelectPlayerHandler = () => {
    setShortlistedPlayers();
    setShowSelectPlayer(!showSelectPlayer);
  }

  const showSelectEventHandler = () => {
    setShortlistedEvents();
    setShowSelectEvent(!showSelectEvent);
  }

  const modifySearchClickedHandler = () => {
    const updatedPlayers = [...selectedPlayers];
    setPlayers(updatedPlayers);

    const updatedEvents = [...selectedPerfEvents];
    setEvents(updatedEvents);
    navigate(PLAYER_PERFORMANCE_ROUTE + "?perfType=" + CHAMPIONSHIP_VALUE);
  }

  if (isLoading) return <Loader show={isLoading} message="Loading..." />;

  return (
    <div>
      <StandardHeader />
      <Heading as='h4' size='md' textAlign='center' marginTop={5}>Championship Performance</Heading>
      <Grid
        spacing="10px"
        p={10}
        templateColumns={{base: '1fr', sm: 'repeat(4, 1fr)', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)' }}
      >
        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
          <Box>
            <FormLabel>Selected Players</FormLabel>
              {
                selectedPlayers && selectedPlayers.length > 0 && selectedPlayers.map( (player) => {
                  return  <AquaTag 
                            key={player.value}
                            label={player.label}
                            value={player.value}
                            clickedHandler={playerTagClickedHandler}
                          />
                })
              }
              <IconButton 
                icon={<AddIcon />} 
                size='sm' 
                onClick={() => showSelectPlayerHandler()} 
              />
              {
                showSelectPlayer &&
                <SelectAdd 
                itemsList={playerList} 
                  onChangedHandler={playerChangedHandler}
                  label="Player"
                  name="playerName"
                  placeholder="Select Player"
                  addClickedHandler={playerAddedHandler}
                  cancelClickedHandler={() => setShowSelectPlayer(!showSelectPlayer)} 
                />
              }
          </Box>
        </GridItem>
        <GridItem colSpan={2} marginBottom={{base: '25px'}}>
        <Box>
            <FormLabel>Selected Events</FormLabel>
              {
                selectedPerfEvents && 
                selectedPerfEvents.length > 0 && 
                selectedPerfEvents.map( (eventItem) => {
                  return  <AquaTag 
                            key={eventItem.value}
                            label={eventItem.label}
                            value={eventItem.value}
                            clickedHandler={eventTagClickedHandler}
                          />
                })
              }
              <IconButton 
                icon={<AddIcon />} 
                size='sm' 
                onClick={() => showSelectEventHandler()} 
              />
              {
                showSelectEvent &&
                <SelectAdd 
                  itemsList={eventsList} 
                  onChangedHandler={eventChangedHandler}
                  label="Event"
                  name="eventName"
                  placeholder="Select Event"
                  addClickedHandler={eventAddedHandler}
                  cancelClickedHandler={() => setShowSelectEvent(!showSelectEvent)} 
                />
              }
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Button onClick={modifySearchClickedHandler}>Modify Search</Button>
        </GridItem>
      </Grid>
      
      {championshipPerformanceData && <Box p={5}>
        <AquaTab tabData={championshipPerformanceData} displayType='standard'></AquaTab>
      </Box>
      }

      {error && (
        <Box width="100%" p={5} h="70vh">
          <Center>
            <div>Unable to load Data. Try again</div>
          </Center>
        </Box>
      )}
      {nodata && (
        <Box width="100%" p={5} h="70vh">
          <Center>
            <div>Data not found for this selection</div>
          </Center>
        </Box>
      )}
      <Footer />
    </div>
  );
};
