import { gql } from 'graphql-request';

export const capsuleQuery = gql`
{
    capsules {
        id
        landings
        missions
        {
        flight
        name
        }
        original_launch
        reuse_count
        status
        type
    }
}`;

// export const capsuleQuery = gql`
// {
//     getTournamentById {
//         name
//     }
// }`;