import { ReactNode, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuGroup,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  IconButton,
  Icon
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, HamburgerIcon, SettingsIcon } from '@chakra-ui/icons';
import { FaChartLine, FaRegCalendarCheck, FaRegCalendarAlt, FaSignOutAlt } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuthState } from 'hooks/useAuth';
import { isObjectEmpty } from 'utils/helpers';
import { AUTH_USER_ME } from 'utils/constants';

import { PLAYER_PERFORMANCE_ROUTE, 
          AUTH_REDIRECT_ROUTE,
          UPLOAD_TOURNAMENT_ROUTE, 
          UPLOAD_TIMETRIAL_ROUTE,
          BENCHMARK_ROUTE, 
          PLAYER_PERSONAL_BEST_ROUTE,
          PLAYER_COMPETITION_ROUTE,
          PLAYER_RECOMMENDATION_ROUTE,
          ADMIN_ROLE
        } from 'utils/constants'
import { useGlobalPerformanceTypeState } from 'hooks/useGlobalPerformanceType';

// const NavLink = ({ children }) => (
//   <Link
//     px={2}
//     py={1}
//     rounded={'md'}
//     _hover={{
//       textDecoration: 'none',
//       bg: useColorModeValue('gray.200', 'gray.700'),
//     }}
//     href={'#'}>
//     {children}
//   </Link>
// );

export default function StandardHeader() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {authenticatedUser, setAuthenticatedUser} = useAuthState();

  console.log('authenticatedUser', authenticatedUser);

  return (
    <>
      <Box bg={useColorModeValue('gray.900', 'gray.100')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <Menu>
                <MenuButton
                    color={useColorModeValue('gray.100', 'gray.900')}
                    as={IconButton}
                    aria-label='Options'
                    icon={<HamburgerIcon />}
                />
                <MenuList alignItems={'center'}>
                    <NavLink to={BENCHMARK_ROUTE}>
                      <MenuItem icon={<Icon as={FaChartLine} />}>Benchmarks</MenuItem>
                    </NavLink>

                    <NavLink to={PLAYER_COMPETITION_ROUTE}>
                      <MenuItem icon={<Icon as={FaChartLine} />}>Competition</MenuItem>
                    </NavLink>

                    {
                      !isObjectEmpty(authenticatedUser) && authenticatedUser?.roles?.includes(ADMIN_ROLE)
                      ?
                        <>
                        <MenuDivider />
                          <MenuOptionGroup title='Upload' type='radio'>
                            <NavLink to={UPLOAD_TOURNAMENT_ROUTE}>
                              <MenuItemOption value='tournament'>Tournament Data</MenuItemOption>
                            </NavLink>
                            <NavLink to={UPLOAD_TIMETRIAL_ROUTE}>
                              <MenuItemOption value='tt'>Time Trial</MenuItemOption>
                            </NavLink>
                          </MenuOptionGroup>
                        </>
                      :""
                    }

                </MenuList>
            </Menu>
            <Box color={useColorModeValue('gray.100', 'gray.900')}>Aqualytics</Box>

            <Flex alignItems={'center'}>
                <Stack direction={'row'} spacing={7}>
                    <Button onClick={toggleColorMode}>
                        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    </Button>
                    <Menu>
                        <MenuButton
                            as={Button}
                            rounded={'full'}
                            variant={'link'}
                            cursor={'pointer'}
                            minW={0}>
                            <Avatar src='https://bit.ly/broken-link' />
                        </MenuButton>
                        <MenuList alignItems={'center'}>
                            <br />
                            <Center>
                                <Avatar
                                size={'2xl'}
                                src={'https://bit.ly/broken-link'}
                                />
                            </Center>
                            <br />
                            <Center>
                                <p>{!isObjectEmpty(authenticatedUser) ? authenticatedUser?.userName : "Username"}</p>
                            </Center>
                            <Center>
                                <p>{!isObjectEmpty(authenticatedUser) ? authenticatedUser?.roles : "Username"}</p>
                            </Center>
                            <MenuDivider />
                            {/* <MenuItem icon={<SettingsIcon />}>Account Settings</MenuItem> */}
                            <NavLink to={AUTH_REDIRECT_ROUTE}>
                              <MenuItem icon={<Icon as ={FaSignOutAlt} />}>Sign Out</MenuItem>
                            </NavLink>
                        </MenuList>
                    </Menu>
                </Stack>
            </Flex>
            </Flex>
      </Box>
    </>
  );
}