import { gql } from 'graphql-request';

export const capsuleQuery = gql`
{
    capsules {
        id
        landings
        missions
        {
        flight
        name
        }
        original_launch
        reuse_count
        status
        type
    }
}`;

export const GET_PLAYER_PERFORMANCE = gql `
    query GetPlayerPerformances($playerId: [String!]!, $year: Int!, $benchmarkType: [BENCHMARKTYPE!], $eventId: [String!]!) {
        getPlayerPerformances(playerId: $playerId, 
            year: $year, 
            benchmarkType: $benchmarkType, 
            eventId: $eventId
        ) 
        {
            gender
            playerPerformanceDetail {
                player {
                    id
                    displayName
                }
                event {
                    name
                    id
                }
                month
                time
                benchmarkType
            }
            playerPerformanceBenchmarkDetail {
                cutoffEnd
                cutoffStart
                benchmark {
                    name
                    rank
                    time
                    benchmarkdetails
                }
                event {
                    id
                    name
                }
                gender
                month
            }
            year
            ageGroup
            nmr {
                id
                name
                gender
                event {
                    id
                    name
                }
                nmr
                state
            }
        }
    }
`;


export const GET_PLAYER_PERFORMANCE_V1 = gql `
    query GetPlayerPerformancesV1($playerId: [String!]!, 
                    $year: Int!, 
                    $benchmarkType: [BENCHMARKTYPE!], 
                    $tournament: String, 
                    $eventId: [String!]!,
                    $performaceType: String!
                ) {
        getPlayerPerformancesV1(playerId: $playerId, 
            year: $year, 
            benchmarkType: $benchmarkType, 
            performaceType: $performaceType,
            eventId: $eventId,
            tournament: $tournament
        ) 
        {
            gender
            playerPerformanceDetail {
                player {
                    id
                    displayName
                }
                event {
                    name
                    id
                }
                month
                time
                benchmarkType
            }
            playerPerformanceBenchmarkDetail {
                cutoffEnd
                cutoffStart
                benchmark {
                    name
                    rank
                    time
                    benchmarkdetails
                }
                benchmarkDetails
                event {
                    id
                    name
                }
                gender
                month
            }
            year
            ageGroup
            nmr {
                id
                name
                gender
                event {
                    id
                    name
                }
                nmr
                state
            }
        }
    }
`;


export const GET_PLAYER_PERFORMANCE_V2 = gql `
    query GetPlayerPerformancesV2(
                $playerIds: [String!]!, 
                $eventIds: [String!]!, 
                $year: Int!, 
                $benchmarkLevel: BENCHMARKLEVEL, 
                $performanceLevel: PERFORMANCELEVEL,
                $performanceType: PERFORMANCETYPE!
                ) {
        getPlayerPerformancesV2(
            playerIds: $playerIds, 
            eventIds: $eventIds, 
            year: $year, 
            benchmarkLevel: $benchmarkLevel, 
            performanceLevel: $performanceLevel,
            performanceType: $performanceType
        ) 
        {
            playerPerformanceDetail {
                year
                month
                player {
                    id
                    lastName
                    firstName
                    displayName
                }
                ageGroup
                performanceLevel
                gender
                event {
                    name
                    id
                }
                time
            }
            benchmarkDetail {
                ageGroup
                benchmark {
                    name
                    rank
                    time
                }
                benchmarkLevel
                cutoffEnd
                cutoffStart
                event {
                    id
                    name
                }
                gender
                month
                year
                nmr {
                    name
                    nmr
                    gender
                }
                district
                state
            }
        }
    }
`;

export const GET_BENCHMARKS = gql `
    query GetPerformanceBenchmarks(
                $eventIds: [String!]!,
                $benchmarkLevel: BENCHMARKLEVEL,  
                $gender: GENDER, 
                $ageGroup: String
            ) {
        getPerformanceBenchmarks(
            eventIds: $eventIds, 
            benchmarkLevel: $benchmarkLevel, 
            gender: $gender, 
            ageGroup: $ageGroup
        ) 
        {
            ageGroup
            benchmark {
                playerName
                rank
                time
            }
            benchmarkLevel
            cutoffEnd {
                playerName
                rank
                time
                YB
            }
            cutoffStart {
                playerName
                rank
                time
                YB
            }
            event {
                id
                name
                order
            }
            gender
            month
            year
            nmr {
                name
                nmr
                gender
                state
                recordedYear
            }
        }
    }
`;

export const GET_PLAYER_PERSONAL_BEST = gql `
    query GetPlayerPersonalBest($playerIds: [String!]!, 
            $eventIds: [String], 
            $benchmarkLevel: BENCHMARKLEVEL
            ) {
        getPlayerPersonalBest(playerIds: $playerIds, eventIds: $eventIds, benchmarkLevel: $benchmarkLevel) 
        {
            playerPerformanceDetail {
                year
                month
                player {
                    id
                    lastName
                    firstName
                    displayName
                    YB
                }
                ageGroup
                performanceLevel
                gender
                event {
                    name
                    id
                    order
                }
                time
            }
            benchmarkDetail {
                ageGroup
                benchmark {
                    playerName
                    rank
                    time
                    YB
                }
                benchmarkLevel
                cutoffEnd {
                    playerName
                    rank
                    time
                    YB
                }
                cutoffStart {
                    playerName
                    rank
                    time
                    YB
                }
                event {
                    id
                    name
                }
                gender
                month
                year
                nmr {
                    name
                    nmr
                    gender
                }
            }
        }
    }
`;

export const GET_ALL_PLAYERS = gql `
    query GetAllPlayers($offset: Int, $limit: Int) {
        getAllPlayers(offset: $offset, limit: $limit) {
            players {
              id
              firstName
              lastName
              displayName
              playerType
            }
        }
    }
`;

export const GET_ALL_STATES = gql `
    query GetAllStates {
        getAllStatesDB {
            states {
              NAME
            }
        }
    }
`;

export const GET_PLAYER_COMPETITION = gql `
    query GetPlayerCompetition($playerIds: [String!], 
            $eventIds: [String], 
            $benchmarkLevel: BENCHMARKLEVEL,
            $state: String,
            $ageGroup: String,
            $gender: String,
            $parentTournament: String
            ) {
        getPlayerCompetition(
            playerIds: $playerIds, 
            eventIds: $eventIds, 
            benchmarkLevel: $benchmarkLevel,
            state: $state,
            ageGroup: $ageGroup,
            gender: $gender,
            parentTournament: $parentTournament
        ) 
        {
            competitionBenchmarkDetail {
                ageGroup
                competitions {
                    benchmark {
                        playerName
                        rank
                        time
                        YB
                        state
                    }
                    cutoffEnd {
                        playerName
                        rank
                        time
                        YB
                    }
                    cutoffStart {
                        playerName
                        rank
                        time
                        YB
                    }
                }
                benchmarkLevel
                performaceLevel
                event {
                    id
                    name
                    order
                }
                gender
                month
                year
                target {
                    playerName
                    rank
                    time
                    YB
                }
                cutoffStart {
                    playerName
                    rank
                    time
                    YB
                }
                nmr {
                    name
                    nmr
                    recordedYear
                    state
                }
            }
            playerPerformanceDetail {
                day
                event {
                    id
                    name
                    order
                }
                gender
                month
                performanceLevel
                time
                ageGroup
                player {
                    id
                    displayName
                    firstName
                    lastName
                    playerType
                    YB
                }
            }
            playerParticipation {
                player {
                  id
                  displayName
                }
                events {
                  id
                  name
                }
            }
        }
    }
`;


export const GET_PLAYER_RECOMMENDATION = gql `
    query GetPlayerRecommendation($playerIds: [String], 
            $eventIds: [String], 
            $ageGroup: String!,
            $gender: GENDER!,
            $benchmarkLevel: BENCHMARKLEVEL,
            $state: String
            ) {
        getPlayerRecommendation(
            playerIds: $playerIds, 
            eventIds: $eventIds,
            ageGroup: $ageGroup,
            gender: $gender, 
            benchmarkLevel: $benchmarkLevel,
            state: $state
        ) 
        {
            recommendationBenchmarkDetail {
                ageGroup
                gender
                target {
                    playerName
                    rank
                    time
                    YB
                    state
                }
                cutOff {
                    playerName
                    rank
                    time
                    YB
                    state
                }
                benchmarkLevel
                event {
                    id
                    name
                    order
                }
            }
            playerRecommendationDetail {
                event {
                    id
                    name
                    order
                }
                gender
                ageGroup
                performanceLevel
                time
                academy
                recommended
                player {
                    id
                    displayName
                    firstName
                    lastName
                    playerType
                    YB
                    state
                    district
                }
            }
        }
    }
`;

export const GET_PLAYERS = gql `
    query GetPlayers($keyword: String, $filter: PlayerFilterInput, $offset: Int, $limit: Int, $sortBy: SORTBY) {
        getPlayers(
                    keyword: $keyword,
                    filter: $filter,
                    offset: $offset, 
                    limit: $limit, 
                    sortBy: $sortBy 
                    ) 
        {
            players {
                id
                firstName
                lastName
                displayName
            }
        }
    }
`;

export const GET_ALL_EVENTS = gql`
    query GetAllEvents {
        getAllEvents {
            events {
                id
                name
            }
        }
    }
`;

export const GET_ALL_GROUPS = gql`
    query GetAllGroups {
        getAllGroups {
            groups {
                id
                group
                ageGroup
                minAge
                maxAge
              }
        }
    }
`;

export const GET_ALL_TOURNAMENTS = gql `
    query GetAllTournaments($benchmarkType: BENCHMARKTYPE) {
        getAllTournaments(benchmarkType: $benchmarkType) {
            tournaments {
              id
              name
            }
        }
    }
`;

export const GET_PARENT_TOURNAMENTS = gql `
    query GetParentTournaments {
        getParentTournaments {
            tournaments {
              id
              name
            }
        }
    }
`;

export const ADD_TOURNAMENT = gql `
    mutation AddTournament(
        $input: AddTournamentInput
    ) {
        addTournament(input: $input) {
            message
            success
        }
    }
`;