
// Routes
export const API_ENDPOINT = 'https://api.ngaze.in/graphql';
export const AUTH_REDIRECT_ROUTE = '/signIn';
export const PLAYER_PERFORMANCE_ROUTE = '/player-performance';
export const PLAYER_CHAMP_PERFORMANCE_ROUTE = '/championship';
export const PLAYER_TIMETRAIL_PERFORMANCE_ROUTE = '/timetrial';
export const BENCHMARK_ROUTE = '/benchmark';
export const PLAYER_PERSONAL_BEST_ROUTE = '/player-personal-best';
export const PLAYER_COMPETITION_ROUTE = '/player-competition';
export const PLAYER_RECOMMENDATION_ROUTE = '/player-recommendation';
export const UPLOAD_TOURNAMENT_ROUTE = '/upload-tournament-data';
export const UPLOAD_TIMETRIAL_ROUTE = '/upload-timetrial-data';

// Roles
export const ADMIN_ROLE = 'Admin';
export const PLAYER_ROLE = 'Player';
export const COACH_ROLE = 'Coach';

// Chart Types
export const LINE_CHART = 'Line Chart';

export const PLAYER_PERFORMANCE = 'Player Performance';
export const DURATION_TITLE = 'Duration mm:ss';

export const DEFAULT_BENCHMARK_LEVEL = 'NATIONAL';
export const DEFAULT_PERFORMANCE_TYPE = 'CHAMPIONSHIP';
export const DEFAULT_PERFORMANCE_LEVEL = 'NATIONAL';
export const DEFAULT_STATE = 'TELANGANA'
export const DEFAULT_PLAYER = {
    'label': 'Ashwath A',
    'value': '9E98605B64D34FD2BF8FE02665577D54'
  };
export const DEFAULT_AGE_GROUP = '10 - 11 years';
export const DEFAULT_GENDER = 'M';

export const TIME_TRIAL_VALUE = '1';
export const CHAMPIONSHIP_VALUE = '2';

export const PLAYERS_LIMIT = 5;
export const EVENTS_LIMIT = 5;

export const PEFORMANCE_TYPE_CHAMPIONSHIP = 'CHAMPIONSHIP';
export const PEFORMANCE_TYPE_TIMETRIAL = 'TIMETRIAL';

export const AUTH_TOKEN_NAME = 'aqToken';
export const AUTH_USER_ME = 'aqUser';

export const PerformanceFilters = {
  Yearly: 'Y',
  Monthly: 'M',
  Daily: 'D'
};

export const BenchmarkLevels = {
  NATIONAL: 'NATIONAL',
  ZONAL: 'ZONAL',
  STATE: 'STATE',
  DISTRICT: 'DISTRICT'
};

export const playerLegendColors = [
  {
    borderColor: 'rgb(0, 0, 255)', //Blue
    backgroundColor: 'rgba(0, 0, 255, 0.5)'
  },
  {
    borderColor: 'rgb(128, 0, 0)', //Maroon
    backgroundColor: 'rgba(128, 0, 0, 0.5)'
  },
  {
    borderColor: 'rgb(0, 128, 128)', //Teal
    backgroundColor: 'rgba(0, 128, 128, 0.5)'
  },
  {
    borderColor: 'rgb(138, 43, 226)', //Blue Violet
    backgroundColor: 'rgba(138, 43, 226, 0.5)'
  },
  {
    borderColor: 'rgb(255, 0, 255)', //Magenta
    backgroundColor: 'rgba(255, 0, 255, 0.5)'
  }
]

