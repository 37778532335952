import { useReducer } from 'react';
import { AppContext, AppDispatchContext } from 'context/appContext'
import { appReducer } from 'reducers/appReducer';

export function AppContextProvider({ children, initialState }) {
    const [state, dispatch] = useReducer(appReducer, initialState);

    return (
        <AppContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppContext.Provider>
    )
}