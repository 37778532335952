import React, { useState, useRef, useEffect } from 'react';
import {
    Heading,
    Stack,
    Flex,
    Button,
    Spacer,
    FormLabel,
    FormControl, 
    FormErrorMessage,
    SimpleGrid,
    GridItem,
    Input, 
    Box,
    Select,
    Text,
    Link
  } from "@chakra-ui/react";
import { useForm } from 'react-hook-form';
import { StandardHeader } from "components/Header";
import Footer from 'components/Footer';
import Loader from "components/Loader";
import { default as ReactSelect } from "react-select";
import { useToast } from '@chakra-ui/react';
import * as Messages from "utils/messages";

import {
    GET_ALL_TOURNAMENTS as getAllTournaments,
    ADD_TOURNAMENT as addTournament
  } from "pages/Player/queries";
import { useClient } from "hooks/useClient";
import fetcher from "utils/fetcher";
import benchmarkLevels from 'mocks/benchmarks.json';

export const TournamentUpload = (props) => {
    const UPLOAD_BASE_URL = 'https://api.ngaze.in/upload/championship?uploadDataType=timetrial';
    const DEFAULT_BENCHMARK_LEVEL = 'NATIONAL';
    
    const [selectedTournament, setSelectedTournament] = useState();
    const [tournamentList, setTournamentList] = useState([]);

    const [selectedFileObject, setSelectedFileObject] = useState();
    const [showTournamentError, setShowTournamentError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [showAddTournament, setShowAddTournament] = useState(false);
    const [selectedBenchmarkLevel, setSelectedBenchmarkLevel] = useState(DEFAULT_BENCHMARK_LEVEL);
   
    const isTournamentLoading = useRef(false);
    
    const client = useClient();
    const toast = useToast();
    

    const onChangedHandler = (event) => {
        setErrorText('');
        setSelectedFileObject(event.target.files[0]);
    }

    const { register, 
        handleSubmit,  
        formState: { errors, isSubmitting }, 
    } = useForm();

    const reactSelectCustomStyles = {
        control: base => ({
          ...base,
          minWidth: 250
        })
    };
    // On submit handler to add a tournament
    async function onSubmit(values) {
        const inputParams = {
            name: values.name,
            performanceLevel: values.benchmarkLevel,
            venue: values.venue
        };

        setIsLoading(true);

        console.log('inputParams', inputParams);

        const buildRequest = {
            document: addTournament,
            variables: inputParams
        };

        try {
            let tournamentResponse = await client.request(buildRequest);
            setIsLoading(false);

            if (tournamentResponse.success) {
                setShowAddTournament(false);
                toast({
                    title: 'Tournament Addition Success!',
                    duration: 3000,
                    isClosable: true,
                    status: 'success'
                })
            }
            else {
                toast({
                    title: 'Tournament Addition Failed!',
                    duration: 3000,
                    isClosable: true,
                    status: 'error'
                })
            }
        }
        catch(error) {
            setIsLoading(false);
            toast({
                title: 'Tournament Addition Failed!' + error,
                duration: 3000,
                isClosable: true,
                status: 'error'
            })
        }
    }

    const onSubmitHandler = (event) => {
        console.log(selectedFileObject);
        
        if (!selectedTournament) {
            setShowTournamentError(true);
            return;
        }
        
        const formData = new FormData();
        formData.append("file", selectedFileObject)

        console.log('selected file object', selectedFileObject);

        if (typeof selectedFileObject === "undefined"  || !selectedFileObject.size > 0) {
            setErrorText('File size cannot be empty');
            return;
        }

        setIsLoading(true);

        let uploadURL = UPLOAD_BASE_URL + '&tournamentId=' + selectedTournament;

        fetch(
            uploadURL,
            {
                method: 'POST',
                body: formData,
                mode: 'no-cors',
                redirect: 'follow'
            }
        )
        .then((response) => response.json)
        .then((result) => {
            console.log(result);
            setIsLoading(false);
        })
        .catch((error) => {
            console.log('upload failure', error)
            setIsLoading(false)
        })
    }

    const buildTournamentsArray = (tournamentsObj) => {
        const tournamentsArr = [];
    
        tournamentsObj.map((tournamentObj) => {
          let tournamentItem = {};
          tournamentItem["label"] = tournamentObj.name;
          tournamentItem["value"] = tournamentObj.id;
          tournamentsArr.push(tournamentItem);
        });
    
        return tournamentsArr;
      };

    const getTournaments = async () => {
        try {
          const tournamentResData = await fetcher(getAllTournaments, client);
          const tournamentsArr = buildTournamentsArray(
            tournamentResData?.getAllTournaments?.tournaments
          );

          setTournamentList(tournamentsArr);
        } catch (error) {
          console.log('error', error);
          isTournamentLoading.current = false;
        }
      };

    const benchmarkLevelChangedHandler = (e) => {
        e.preventDefault();
        setSelectedBenchmarkLevel(e.currentTarget.value);
    }

    const tournamentChangedHandler = (inputValue) => {
        if (!inputValue) return;
        setShowTournamentError(false);
        setSelectedTournament(inputValue.value);
        console.log(inputValue);
    };

    useEffect( () => {
        getTournaments();

    })

    if (isLoading) return <Loader show={isLoading} message="Loading..." />;

    return (
        <div>
            <StandardHeader />
            {
                showAddTournament
                ?
                    <>
                        <Heading as='h4' size='md' textAlign='center' marginTop={5}>Add Tournament</Heading>
                        <Stack minHeight="90vh" direction={{ base: 'column', md: 'row' }}>
                            <Flex p={8} flex={1} align={'center'} justify={'center'}>
                                <Stack spacing={4} w={'full'} maxW={'md'}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <FormControl isInvalid={errors.tournamentName || errors.benchmarkType || errors.venue}>
                                            <SimpleGrid rowGap={6} w={{ base: 'auto', md: '30vw' }}>
                                                <GridItem colSpan={1}>
                                                    <FormLabel>Tournament Name</FormLabel>
                                                    <Input 
                                                        id='tournamentName'
                                                        type='text'
                                                        maxLength={30}
                                                        {...register('tournamentName', {
                                                            required: 'Tournament Name is required',
                                                         })} 
                                                    />
                                                    <FormErrorMessage>
                                                        {errors.tournamentName && errors.tournamentName.message}
                                                    </FormErrorMessage>
                                                </GridItem>
                                                <GridItem colSpan={1}>
                                                    <FormLabel>Benchmark Type</FormLabel>
                                                    <Select 
                                                        name="benchmarkType"
                                                        placeholder="Choose Type" 
                                                        {...register("benchmarkType", {
                                                            required: 'Benchmark type is required'
                                                         })}
                                                        value={selectedBenchmarkLevel} onChange={benchmarkLevelChangedHandler}>
                                                        {
                                                            (benchmarkLevels && benchmarkLevels.length > 0)
                                                            ?
                                                            benchmarkLevels && benchmarkLevels.map((benchmarkLevel) => {
                                                                return <option
                                                                        key={benchmarkLevel.key}
                                                                        value={benchmarkLevel.key}
                                                                    >
                                                                        {benchmarkLevel.value}
                                                                    </option>
                                                            })
                                                            :
                                                            <option>
                                                                {Messages.NO_DATA_FOUND}
                                                            </option>
                                                        }

                                                        </Select>
                                                        <FormErrorMessage>
                                                        {errors.benchmarkType && errors.benchmarkType.message}
                                                    </FormErrorMessage>
                                                </GridItem>
                                                <GridItem colSpan={1}>
                                                    <FormLabel>Venue</FormLabel>
                                                    <Input 
                                                        id='venue'
                                                        type='text'
                                                        maxLength={30}
                                                        {...register('venue', {
                                                            required: 'Venue is required',
                                                         })} 
                                                    />
                                                    <FormErrorMessage>
                                                        {errors.venue && errors.venue.message}
                                                    </FormErrorMessage>
                                                </GridItem>
                                                <Flex py={2}>
                                                    <Button onClick={() => setShowAddTournament(false)} variant='outline'>Cancel</Button>
                                                    <Spacer />
                                                    <Button variant='solid' type='submit'>Submit</Button>
                                                </Flex>
                                            </SimpleGrid>
                                        </FormControl>
                                    </form>
                                </Stack>
                            </Flex>
                        </Stack>
                    </>
                :
                    <>
                    <Heading as='h4' size='md' textAlign='center' marginTop={5}>Tournament Data Import</Heading>
                    <Stack minHeight="90vh" direction={{ base: 'column', md: 'row' }}>
                        <Flex p={8} flex={1} align={'center'} justify={'center'}>
                            <Stack spacing={4} w={'full'} maxW={'md'}>
                                {/* Select Tournament */}
                                <Box>
                                    <FormLabel>Tournament</FormLabel>
                                    <ReactSelect placeholder="Select Tournament"
                                        styles={reactSelectCustomStyles}
                                        options={tournamentList} 
                                        onChange={(inputValue) => tournamentChangedHandler(inputValue)}
                                        name="tournamentId"
                                    />
                                    {
                                        showTournamentError &&
                                        <div>
                                            <Text fontSize='sm' color='red'>Tournament is required</Text>
                                        </div>
                                    }
                                </Box>
                                <Link 
                                    textDecoration={"underline"} 
                                    textAlign={'right'}
                                    onClick={() => setShowAddTournament(true)}
                                >
                                    Add Tournament
                                </Link>
                                {/* Upload File */}
                                <div id="upload-file">
                                    <FormLabel>Select File to Upload</FormLabel>
                                    <input type = "file" name="uploadFile" accept=".csv, .xls, .xlsx" onChange={onChangedHandler} />
                                    {
                                        errorText && 
                                        <div>
                                            <Text fontSize='sm' color='red'>{errorText}</Text>
                                        </div>
                                    }
                                    <Spacer />
                                </div>
                                <Button onClick={onSubmitHandler}>Submit</Button>
                            </Stack>
                        </Flex>
                    </Stack>
                </>
            }
            
            <Footer />
        </div>

    )
}